<template>
  <div>
    <VehicleReadOnlyInfo v-if="vehicle" :vehicle="vehicle" />

    <v-card class="border-1 outlined m-1 mt-0 p-1 rental-contract-from">
      <v-container fluid class="mb-0 removed-container" style="">
        <!-- New Design Ends Here -->

        <v-row class="border-b align-center">
          <v-col cols="12" sm="12" md="4">
            <h4 class="text-blue-color d-flex align-center">
              <v-icon class="text-blue-color mr-2" dark right>
                mdi-card-account-details </v-icon
              >{{
                formMode === "add"
                  ? $t("rentals.create_new")
                  : $t("rentals.edit")
              }}
              {{ $t("rentals.rental_contract") }}
            </h4>
          </v-col>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              id="top-print"
              class="ma-2 text-white-color"
              color="primary"
              disabled
              v-if="formMode === 'edit'"
            >
              {{ $t("rentals.print_contract") }}
              <v-icon dark right> mdi-printer </v-icon>
            </v-btn>
            <v-btn
              v-if="formMode === 'edit'"
              @click="attachmentDialog = true"
              id="top-attachments"
              class="ma-2 text-white-color"
              color="success"
            >
              {{ $t("attachments") }} ({{ $store.state.attachmentCounts['rentals'] ? $store.state.attachmentCounts['rentals'] : 0 }})
              <v-icon dark right> mdi-paperclip </v-icon>
            </v-btn>

            <v-btn
              id="top-cancel"
              class="ma-2 text-black-color"
              color="grey lighten-2"
              @click="cancelRental"
            >
              {{ $t("nav_buttons.cancel") }}
              <v-icon dark right> mdi-keyboard-return </v-icon>
            </v-btn>
            <v-btn
              id="top-save"
              class="ma-2 text-white-color"
              color="success"
              @click="saveData"
            >
              {{ $t("nav_buttons.save") }}
              <v-icon dark right> mdi-content-save </v-icon>
            </v-btn>
          </div>
        </v-row>

        <!-- Heading Ends Here -->
        <v-divider class="mt-5"></v-divider>
      </v-container>
      <div>
        <div class="mt-0 removed-container" style="margin:10px!important">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <h4 class="text-blue-color">
                {{ $t("rentals.contract_details") }}
              </h4>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-combobox
                autocomplete="off"
                ref="clientName"
                v-model="form.client"
                :items="company_client_names"
                item-value="id"
                item-text="name"
                :label="$t('vehicles.client')"
                class="to-upper"
                required
                append-icon="mdi-close"
                v-on:keydown="handleInput($event, 'alphanumeric', 50)"
                :search-input.sync="search_client"
                no-filter
                v-on:keydown.enter.prevent=""
                @click:append="
                  form.client = null;
                  company_client_names = [];
                "
                :error-messages="
                  $store.state.validation && !$v.form.client.required
                    ? [this.$t('vehicles.text_messages.client_required')]
                    : []
                "
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.name ? item.name : "" }}
                  {{ item.first_name ? item.first_name : "" }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <p class="client_name">
                      {{ item.name ? item.name : "" }}
                      {{ item.first_name ? item.first_name : "" }}
                    </p>
                    <p
                      class="client_detail"
                      v-if="item.representative_company_id"
                    >
                      {{ $t("entity_type.company") }}
                    </p>
                    <p class="client_detail" v-else>
                      {{ $t("entity_type.private") }}
                    </p>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-menu
                v-model="menuDtpStart"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_date"
                    :label="$t('pricing_rule.start_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="form.start_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  v-model="form.start_date"
                  :min="today"
                  @input="menuDtpStart = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-menu
                v-model="menuDtpEnd"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end_date"
                    :label="$t('pricing_rule.end_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="form.end_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  :min="form.start_date"
                  :max="form.extended_date"
                  v-model="form.end_date"
                  @input="menuDtpEnd = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <v-menu
                v-model="menuDtpExtended"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.extended_date"
                    :label="$t('rentals.extended_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="form.extended_date = null"
                    append-icon="mdi-close"
                    class="mr-2 to-upper"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  :weekday-format="getDay"
                  :min="form.end_date"
                  v-model="form.extended_date"
                  @input="menuDtpExtended = false"
                  no-title
                  scrollable
                  :locale="userLanguageId"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-text-field
                autocomplete="off"
                v-model="form.contract_reference"
                class="to-upper"
                name="client_contract_ref"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.rental.client_contract_reference')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                autocomplete="off"
                v-model="form.client_reference"
                class="to-upper"
                name="internal_contract_ref"
                v-on:keydown="handleInput($event, 'alphanumeric', 100)"
                :label="$t('vehicles.rental.internal_reference')"
                :outlined="is_boxes"
                :dense="is_boxes"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <AddressInput
                v-model="form.pickup_location"
                :label="$t('rentals.pickup_location')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <v-checkbox
                class="mt-6"
                v-model="delivery_add_same_as_pickup"
                :label="$t('vehicles.rental.same_as_pickup')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <AddressInput
                v-model="form.delivery_location"
                :label="$t('rentals.delivery_location')"
                :disabled="delivery_add_same_as_pickup"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-tabs
              height="50px"
              background-color="primary"
              dark
              class="ml-2 mr-2"
              v-model="tabs"
            >
              <v-tab href="#driver1">{{ $t("rentals.driver") }} 1</v-tab>
              <v-tab href="#driver2">{{ $t("rentals.driver") }} 2</v-tab>
              <v-tabs-items v-model="tabs">
                <v-tab-item value="driver1" class="mt-3">
                  <DriverForm v-model="form.driver" />
                </v-tab-item>

                <v-tab-item value="driver2">
                  <DriverForm v-model="form.second_driver" />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12" sm="12" md="12">
              <h4 class="mb-3" style="color:#9B9B9B; font-weight:normal">
                Note
              </h4>
              <wysiwyg v-model="form.note" />
            </v-col>
          </v-row>
          <br />
        </div>
      </div>

      <attachment-dialog
        :showDialog.sync="attachmentDialog"
        :data="rentalId"
        :countKey="'rentals'"
        :resource="'rentals'"
      ></attachment-dialog>
    </v-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import AttachmentDialog from "@/components/AttachmentDialog";
import RentalAttachmentsList from "../../components/Rentals/RentalAttachmentsList.vue";
import VehicleReadOnlyInfo from "../../components/Vehicles/VehicleReadOnlyInfo.vue";
import moment from "moment";
import AddressInput from "../../components/AddressInput.vue";
import DriverForm from "../../components/Rentals/DriverForm.vue";
export default {
  name: "AddRentalsHistory",
  mixins: [validationMixin],
  components: {
    "attachment-dialog": AttachmentDialog,
    // "rental-attachments-list": RentalAttachmentsList,
    VehicleReadOnlyInfo,
    AddressInput,
    DriverForm,
  },
  props: {
    showBookingDialog: [Boolean],
  },
  data() {
    return {
      tabs: null,
      isTrue: true,
      vehicle: null,
      rentalHistory: null,
      rentalId: this.$store.state.rentalId,
      is_edit: this.formMode === "edit",
      company_client_names: [],
      autocompleteValueLocal: false,
      search_client: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      menuDtp: false,
      menuDtpStart: false,
      menuDtpEnd: false,
      menuDtpExtended: false,
      delivery_add_same_as_pickup: false,
      delivery_address_readonly: false,
      attachmentDialog: false,
      vehiclesAttachmentDialog: false,
      form: {
        client: {},
        client_reference: null,
        contract_reference: null,
        driver: {},
        second_driver: {},
        pickup_location: null,
        delivery_location: null,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        note: null,
        vehicle: this.vehicle,
        vehicle_id: this?.vehicle?.id || null,
      },
    };
  },
  created() {
    if (!this.$store.state.vehicleId) {
      this.$router.push("/rentals");
    }

    this.$store.commit("setLoading", true);
    axios
      .get(API_BASE_URL + "/client_vehicles/" + this.$store.state.vehicleId, {
        headers: this.header,
      })
      .finally(() => this.$store.commit("setLoading", false))
      .then((response) => {
        this.vehicle = response.data || null;
      })
      .catch((error) => {
        this.$toast.error(error.response.data.message);
      });

    if (this.formMode === "edit") {
      this.setRentalHistory();
    }
  },
  methods: {
    renderDriverLastName(item) {
      if (typeof item == "string") {
        return item.toUpperCase();
      }
      return item.name;
    },
    renderCityName(city) {
      if (typeof city != "object") {
        return "";
      }

      return city.name + ", " + city.country_id;
    },
    falseButtonClicked() {
      this.$store.commit("setRentalVehicleForm", false);
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    showVehiclesAttachmentDialog() {
      this.vehiclesAttachmentDialog = true;
    },

    async map_search_address(field) {
      if (!this[field] || typeof this[field] == "object") {
        return;
      }
      

      let address_name = "pickup_addresses";

      if (field == "search_delivery_location") {
        address_name = "delivery_addresses";
      }

      if (field == "search_driver_location") {
        address_name = "driver_addresses";
      }

      await axios
        .get(
          "https://api.geoapify.com/v1/geocode/search?apiKey=" +
            this.mapApiKey +
            "&text=" +
            this[field].toString() +
            "&format=json"
        )
        .then((data) => {
          this[address_name] =
            data && data.data && data.data.results ? data.data.results : [];
        });

      
    },

    formPayload() {
      this.form.driver.full_name =
        this.form.driver.first_name + " " + this.form.driver.name;
      return this.form;
    },

    setRentalHistory() {
      
      axios
        .get(API_BASE_URL + "/vehicle_rentals/" + this.rentalId, {
          headers: this.header,
        })
        .finally(() => this.$store.commit("setLoading", false))
        .then((response) => {
          let history = response.data;
          this.rentalHistory = history;
          // Fill Form Data
          this.form = history;
          this.driver = history.driver;
          if (!this.form.second_driver) {
            this.form.second_driver = {};
          }
          this.form.start_date = moment(history.start_date).format(
            "YYYY-MM-DD"
          );
          this.form.end_date = moment(history.end_date).format("YYYY-MM-DD");
          if (this.form.extended_date) {
            this.form.extended_date = moment(history.extended_date).format(
              "YYYY-MM-DD"
            );
          }

          // Check if pickup_location is same as delivery location
          if (this.form.pickup_location_id == this.form.delivery_location_id) {
            this.delivery_add_same_as_pickup = true;
          }
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message || this.$t("unknown_error")
          );
        });
    },

    updateData() {
      
      let payload = this.formPayload();
      axios
        .put(
          API_BASE_URL + "/vehicle_rentals/" + this.$store.state.rentalId,
          payload,
          { headers: this.header }
        )
        .then((data) => {
          this.$toast.success(
            this.$t("vehicles.text_messages.contract_updated_successfully")
          );
          this.$store.commit("setRentalVehicleForm", false);
          this.$router.push("/rentals");
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.errors ||
              error?.response?.data?.errors[0] ||
              error?.response?.message ||
              "Unknown error ocurred"
          );
        })
        .finally(() => {
          
        });
    },
    addNew() {
      

      axios
        .post(API_BASE_URL + "/vehicle_rentals", this.formPayload(), {
          headers: this.header,
        })
        .then((data) => {
          this.$toast.success(
            this.$t("vehicles.text_messages.contract_created_successfully")
          );
          this.$router.push("/rentals");
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.errors ||
              error?.response?.data?.errors[0] ||
              error?.response?.message ||
              "Unknown error ocurred"
          );
        })
        .finally(() => {
          
        });
    },
    saveData() {
      if (!this.form.client || JSON.stringify(this.form.client) === "{}") {
        return this.$toast.error(this.$t("rentals.client_error"));
      }

      if (
        !this.form.pickup_location ||
        JSON.stringify(this.form.pickup_location) === "{}"
      ) {
        return this.$toast.error(this.$t("rentals.pickup_location_error"));
      }

      if (
        !this.form.delivery_location ||
        JSON.stringify(this.form.delivery_location) === "{}"
      ) {
        return this.$toast.error(this.$t("rentals.delivery_location_error"));
      }

      if (!this.form.start_date) {
        return this.$toast.error(this.$t("rentals.start_date_error"));
      }

      if (!this.form.end_date) {
        return this.$toast.error(this.$t("rentals.end_date_error"));
      }

      if (
        this.form.driver?.name === "" ||
        this.form.driver?.name === null ||
        this.form.driver?.name === undefined
      ) {
        return this.$toast.error(this.$t("rentals.last_name_error"));
      }

      if (typeof this.driver !== "object") {
        const requiredFields = ["name"];
        const missingFields = requiredFields.filter(
          (field) => !this.form.driver[field]
        );

        if (missingFields.length) {
          return this.$toast.error(this.$t("rentals.first_name_error"));
        }
      }

      if (
        this.form.driver === this.form.second_driver ||
        (this.form?.driver?.id || 1) === (this.form?.second_driver?.id || 2)
      ) {
        return this.$toast.error(this.$t("rentals.same_driver_error"));
      }

      if (this.formMode == "edit") {
        this.updateData();
      } else {
        this.addNew();
      }
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      
      axios
        .get(
          API_BASE_URL +
            "/clients/search?" +
            (this.search_client ? "&search=" + encodeURIComponent(this.search_client) : ""),
          { headers: this.header }
        )
        .then(({ data }) => {
          this.company_client_names = data.data;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    cancelRental() {
      this.$store.commit("setRentalVehicleForm", false);
      this.removeRentalInfo();
    },
    removeRentalInfo() {
      this.$store.commit("setRentalId", null);
      this.company_client_names = [];
      this.autocompleteValueLocal = false;
      this.search_client = null;
      this.is_boxes = this.getCompanyPropertyFromLocalStorage(
        "boxes_on_fields",
        1
      );
      this.form.start_date = new Date().toISOString().substr(0, 10);
      this.attachmentDialog = false;
      this.vehiclesAttachmentDialog = false;
      this.form = {};
      this.$router.push("/rentals");
    },
  },
  watch: {
    rentStartDate(val) {
      this.form.end_date = val;
    },
    delivery_add_same_as_pickup(val) {
      if (val) {
        this.form.delivery_location = this.form.pickup_location;
        this.delivery_address_readonly = true;
      } else {
        this.delivery_address_readonly = false;
      }
    },
    rentPickupLocation() {
      if (this.delivery_add_same_as_pickup) {
        this.form.delivery_location = this.form.pickup_location;
      }
    },

    vehicle(val) {
      this.form.vehicle = val;
      this.form.vehicle_id = val.id;
    },

    search_client(val) {
      if (!val || this.client_id == val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 500);
    },
  },
  computed: {
    addRentalVehicleFormActive() {
      return this.$store.state.addRentalVehicleFormActive;
    },
    formMode() {
      return this.$route.path.includes("edit") ? "edit" : "add";
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    rentStartDate() {
      return this.form.start_date;
    },
    rentPickupLocation() {
      return this.form.pickup_location;
    },
  },
};
</script>

<style scoped>
.rental-contract-from {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-top: 30px;
}

.driver-from {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.driver-from .driver-from-item {
  width: 31%;
}

.attachment-title {
  position: relative;
  background: #fff;
  z-index: 0;
}

.attachment-title:after {
  content: "";
  width: Calc(100% + 10px);
  height: 1px;
  display: block;
  background: rgba(0, 0, 0, 0.42);
  position: absolute;
  top: 50%;
  left: -5px;
  z-index: -1;
}

.attachment-title span {
  background: #fff;
  display: inline-block;
  width: initial;
  position: relative;
  z-index: 0;
  padding: 0 5px;
}

.print-btn {
  background-color: #fff !important;
  border: 1px solid;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.v-select__slot {
  border: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-bottom: 0;
}

.v-select__selections {
  border-right: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.contract-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.contract-btn button {
  font-size: 10px !important;
}
</style>
