<template>
    <div v-if="readyToView">
        <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>
        <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props: {
        bardata: [String],
        barfields: [Array],
        card: [Object]
    },
    data() {
        return {
            readyToView: false,
            bardataProcessed: [],

            series: [
                // {
                //     name: "Desktops",
                //     data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                // }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    // text: 'Product Trends by Month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                    categories: []
                },
            },
        }
    },
    mounted() {

        // Process Bar Data
        if (this.bardata) {
            let dataRows = this.bardata.replace(/"/g, '').split("\n");
            let finalData = [];
            let count = -1;
            let rownumber = 1;
            dataRows.forEach((record) => {
                count++;
                if (count == 0) {
                    // return because first row will contain headers
                    return
                }
                let dataInColumns = record.split(";");
                let rowObject = {};
                for (let index = 0; index < this.barfields.length; index++) {
                    rowObject[index] = dataInColumns[index]
                }
                finalData.push(rowObject);
                rownumber++;
            })
            console.log("data rows", dataRows, finalData);
            this.bardataProcessed = finalData;
        }

        // Process All the Data related to Graph
        this.barfields.forEach((element, index) => {
            console.log("bar field", element.key, element.translation, this.chartOptions.xaxis.categories);
            if (element.key.includes("x_")) {
                // this.chartOptions.xaxis.categories.push(element.translation);
                let dataToBeFilled = [];
                this.bardataProcessed.forEach((row) => {
                    if (!row[index]) {
                        return;
                    }
                    console.log("X-Axis Row", row, index, row[index]);
                    dataToBeFilled.push(row[index]); // index is same as data to be filled
                })
                this.chartOptions.xaxis.categories = dataToBeFilled;
            }
            if (element.key.includes("y_")) {
                let dataToBeFilled = [];
                this.bardataProcessed.forEach((row) => {
                    if (!row[index]) {
                        return;
                    }
                    console.log("Y-Axis Row", row, index, row[index]);
                    dataToBeFilled.push(row[index]); // index is same as data to be filled
                })
                this.series.push(
                    {
                        name: element.translation,
                        data: dataToBeFilled
                        // data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                    }
                );
            }
        })


        // Show Graph
        this.$nextTick(() => {
            this.readyToView = true;
        })
    }
}
</script>