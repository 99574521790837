<template>
<div>
  <v-row v-if="depotBulkOperation && depotBulkOperation.direction === 'in'">
  <v-col cols="12">
    <v-list v-if="client" style="width: 50%; display: flex; flex-direction: row">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h4>{{ translate('client') }}: {{ client.name }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h4>{{ translate('depot_manager') }}: {{ depotBulkOperation.depot_manager }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h4>{{ translate('date') }}: {{ depotBulkOperation.depot_preparation }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h4>{{ translate('entries') }}: {{ depots.length }}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-data-table
    :headers="tableHeaders"
    :items="items"
    :items-per-page="200"
    >

    </v-data-table>
  </v-col>
  </v-row>
  <v-row v-else>
    <v-col :sm="5">
      <v-col cols="12">
        <v-text-field
            v-model="depotManager"
            :label="translate('depot_manager')"
            disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
            v-model="entries"
            label="Requested"
            rows="10"
            auto-grow
        ></v-textarea>
      </v-col>
    </v-col>
    <v-col :sm="7">
      <v-col cols="12" v-if="depotBulkOperation && depotBulkOperation.errors.length > 0">
        <v-card-title style="text-decoration: underline">
          <!--                    <span class="mr-2"> <v-icon @click="toggleErrorTableView">mdi-eye</v-icon></span>-->
          Errors
        </v-card-title>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(error, index) in depotBulkOperation.errors" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ error }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-col>
  </v-row>
  <span class="d-none" id="bulk-save" @click="processBulkData"></span>
  <span class="d-none" id="bulk-print"  @click="printBulk"></span>
  <span class="d-none" id="bulk-email"></span>
  <span class="d-none" id="bulk-print-labels" @click="printLabels"></span>
  <batch-printing v-model="batchPrintingDialog" :depot-bulk-operation="depotBulkOperation" :type="batchPrintingType" :chunk-size="chunkSize"/>
</div>
</template>
<script>
import {API_BASE_URL} from "@/config";
import axios from 'axios'
import BatchPrinting from "@/components/TyreHotelBulk/BatchPrinting";

export default {
  name: 'BulkOperation',
  components: {
    BatchPrinting,
  },
  data() {
    return {
      depotBulkOperation: null,
      depotManager: null,
      depots: [],
      entries: null,
      client: null,
      batchPrintingDialog: false,
      batchPrintingType: 'bulk_operation_labels',
      chunkSize:100,
      axiosOptions: {Authorization: 'Bearer ' + this.$store.state.AccessToken},
    }
  },
  computed: {
    tableHeaders(){
      return [
        {text: this.$t('srno'), value: 'serial', width: 50},
        {text: this.translate('registration_number'), value: 'registration_number'},
        {text: this.translate('vin'), value: 'vin'},
        {text: this.translate('localisation'), value: 'localisation'},
        {text: this.translate('status'), value: 'status'},
      ].map(header => {
        header.align = 'start'
        header.sortable = false
        return header
      });
    },
    items(){
      return this.depots.map((depot, index) => {
        return {
          serial: index + 1,
          registration_number: depot.vehicle.registration_number,
          vin: depot.vehicle.vin,
          localisation: depot.localisation,
          status: this.$t('depot_hints.' + depot.status.key)
        }
      })
    }
  },
  async mounted() {
    let targetId = localStorage.getItem('bulk_operation_id') ?? null;
    // go back to list of bulk operations if no targetId
    if (!targetId) {
      return await this.$router.push({name: 'TyreHotelBulkOperation'})
    }

    this.depotBulkOperation = await this.getBulkOperation(targetId)
    if(this.depotBulkOperation.direction === 'in'){
      document.getElementById('bulk-save-button').style.display = 'none'
      this.depots = await this.getSpecificDepots(this.depotBulkOperation.completed)
      this.client = await this.getClient(this.depots[0].client_id)
    }else{
      document.getElementById('bulk-save-button').style.display = 'block'
    }

    // convert requested multiple array to multiple line string
    let requested = this.depotBulkOperation.requested;
    let firstRow = requested[0];
    this.entries = (Array.isArray(firstRow) || typeof firstRow === 'object') ?  requested.map(entry => {
      if(Array.isArray(entry)){
        return entry.join(' ')
      }
      return Object.values(entry).join(' ')
    }).join('\n') : requested.join('\n') ;
    this.depotManager = this.depotBulkOperation.depot_manager;
  },
  watch:{
    depotBulkOperation(val){
      if(!val) return;

      // convert requested multiple array to multiple line string
      let requested = this.depotBulkOperation.requested;
      let firstRow = requested[0];
      this.entries = Array.isArray(firstRow) ?  requested.map(entry => entry.join(', ')).join('\n') : requested.join('\n') ;
      this.depotManager = this.depotBulkOperation.depot_manager;
    },
  },
  methods: {
    openBatchPrintingModal(){
      this.batchPrintingDialog = false
      setTimeout(() => {
        this.batchPrintingDialog = true
      }, 10)
    },
    getBulkOperation(id) {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/depots/bulk_operations/' + id, {headers: this.axiosOptions})
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    translate(key) {
      return this.$t('tyre_hotel.bulk.' + key)
    },
    processBulkData() {
      
      axios.put(API_BASE_URL + '/depots/bulk_operations/' + this.depotBulkOperation.id, {
        data: this.entries,
        date: this.depotBulkOperation.requested_for,
        depot_manager: this.depotManager
      }, {headers: this.axiosOptions})
          .finally(() => {
            
          })
          .then((data) => {
            this.depotBulkOperation = data.data.data
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {

          })
    },
    getSpecificDepots(depotIds){
      return new Promise((resolve, reject) => {
        axios.put(API_BASE_URL + '/depots/bulk_operations',{depots: depotIds}, {headers: this.axiosOptions})
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    getClient(clientId){
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + '/clients/' + clientId, {headers: this.axiosOptions})
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    printLabels() {
      if(this.depotBulkOperation.completed.length > this.chunkSize){
        this.batchPrintingType = 'bulk_operation_labels'
        return this.openBatchPrintingModal();
      }

      let url = API_BASE_URL + "/depot/" + this.depotBulkOperation.id + "/print?document=bulk_operation_labels&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.depotBulkOperation.depot_manager
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    printBulk(){
      if(this.depotBulkOperation.completed.length > this.chunkSize){
        this.batchPrintingType = 'bulk_report'
        return this.openBatchPrintingModal();
      }

      let document = this.depotBulkOperation.direction === 'in' ? 'bulk_report' : 'bulk_depot_out'
      let url = API_BASE_URL + "/depot/" + this.depotBulkOperation.id + "/print?document="+ document +"&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.depotBulkOperation.depot_manager
      this.setPreviewData(url, fileName, true, 'application/pdf');
    }
  }
}
</script>
<style scoped>

</style>