<template>
    <v-dialog @click:outside="closeDialog" @keydown.esc="closeDialog" v-model="datetimeDialog" :persistent="persistent"
        width="290px" :fullscreen="fullscreen">

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="Picker in menu" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                    Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                </v-btn>
            </v-date-picker>
        </v-menu>
    </v-dialog>
</template>
<script>
import { DateTime } from 'luxon';
export default {
    inheritAttrs: false,
    props: {
        value: {
            type: String
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: false
        },
        dateFormat: {
            type: String,
            default: "yyyy-MM-dd"
        },
        timeFormat: {
            type: String,
            default: "HH:mm"
        }
    },
    data() {
        return {
            nowDate: new Date().toISOString().slice(0, 10),
            date: null,
            tab: false,
            datetimeDialog: false,
            menu: false,
            modal: false,
            menu2: false,
        }
    },
    computed: {
        datetimeFormatted() {
            console.log("datetime", this.value);
            return this.value
                ? DateTime.fromISO(this.value)
                    .setLocale("en")
                    .toFormat(`${this.dateFormat}`)
                : "";
        }
    },
    watch: {
        datetimeDialog: function (val) {
            // load into separated models as dialog opens
            if (val && this.value) {
                this.date = DateTime.fromISO(this.value)
                    .setLocale("en")
                    .toFormat("yyyy-MM-dd");
            }
        }
    },
    methods: {
        closeDialog() {
            this.datetimeDialog = false;

            this.$emit(
                "input",
                DateTime.fromFormat(
                    `${this.date}`,
                    "yyyy-MM-dd",
                    {
                        locale: "en"
                    }
                ).toISO()
            );

            // prevents animate to date tab when closing
            setTimeout(() => (this.tab = "date"), 300);
        }
    }
}
</script>
<style scoped>
.t-datetime-picker .v-tabs-slider-wrapper {
    top: 0;
}

.t-datetime-picker .v-picker__title {
    height: 90px;
}

.t-datetime-picker .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
}

.t-datetime-picker .v-picker__body {
    height: 290px;
}

.t-datetime-picker .v-tabs-items {
    height: 380px;
}
</style>