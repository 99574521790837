<template>
    <div class="pt-2 pb-0">
        <h3>{{ heading }}</h3>
        <span>
            {{ text }}
        </span>
    </div>
</template>

<script>
export default {
    name: "NotificationPopUpContent",
    props: {
        heading: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
};
</script>
