var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","min-width":"500","max-width":"1204"},on:{"click:outside":function($event){return _vm.falseButtonClicked()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.falseButtonClicked()}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-title",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(_vm.$t("appointment_details.heading"))+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"dialog-text text-content pa-3",class:_vm.isLoaded ? '' : 'loading_content',attrs:{"color":"black"}},[(_vm.isLoaded)?[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"footer-props":{
                        'items-per-page-options': _vm.dataTableOptions,
                    },"headers":_vm.dataHeader,"hide-default-footer":true,"hide-default-header":true,"items":_vm.selectedArticles,"item-class":_vm.row_classes,"items-per-page":10000,"group-by":"group","show-group-by":"","disable":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                    var group = ref.group;
return [(group && group.trim() !== '')?[_c('td',{staticClass:"group-header",attrs:{"colspan":_vm.dataHeader.length}},[_c('strong',[_vm._v(_vm._s(group))])])]:_vm._e()]}},{key:"header",fn:function(ref){
                    var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,staticClass:"text-right",style:(_vm.getHeaderWidth(header, header.width)),attrs:{"scope":"col"}},[(
                                            header.value ==
                                                'article_original_name'
                                        )?[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(header.text)+" ")])],1)]:(
                                            header.value == 'actions'
                                        )?[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex dontbreakline",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(header.text)+" ")])],1)]:[_vm._v(" "+_vm._s(header.text)+" ")]],2)}),0)])]}},{key:"no-data",fn:function(){return [_c('span')]},proxy:true},{key:"item.information",fn:function(ref){
                                        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row information-class"},[(
                                    item.supplier &&
                                        item.supplier.length > 0 &&
                                        item.supplier[0] &&
                                        item.supplier[0].id >= 0 &&
                                        item.article_original_name &&
                                        item.article_original_name.family &&
                                        item.article_original_name.family
                                            .is_stock_managed
                                )?_c('div',{class:typeof item.article != 'object'
                                        ? item.article
                                              .internal_company_article &&
                                          item.article
                                              .internal_company_article
                                              .length > 0 &&
                                          item.article
                                              .internal_company_article[0]
                                              .stock < item.quantity
                                            ? 'articleNotInStock'
                                            : item.supplier &&
                                              item.supplier.length > 0 &&
                                              item.supplier[0].id != 0 &&
                                              item.saved_supplier_order_detail &&
                                              item
                                                  .saved_supplier_order_detail
                                                  .supplier_order &&
                                              [
                                                  10091000,
                                                  10091001,
                                                  10091004 ].includes(
                                                  item
                                                      .saved_supplier_order_detail
                                                      .supplier_order
                                                      .status_id
                                              )
                                            ? (item.saved_supplier_order
                                                ? new Date(
                                                      item.saved_supplier_order.delivery_date
                                                  ) >=
                                                  new Date(
                                                      _vm.formatDate(
                                                          _vm.getCurrentDate(),
                                                          'yyyy-mm-dd'
                                                      )
                                                  )
                                                : false)
                                                ? 'articleWaitingForSupplierOrderOrange'
                                                : 'articleWaitingForSupplierOrder'
                                            : 'articleInStock'
                                        : '',staticStyle:{"max-width":"100px","border":"0px","width":"60px"},attrs:{"dense":"","return-object":"","readonly":"","flat":"","solo":"","hide-details":"auto"}},[(
                                        item.supplier &&
                                            item.supplier.length > 0 &&
                                            item.supplier[0].id != 0 &&
                                            item.saved_supplier_order_detail &&
                                            item.saved_supplier_order_detail
                                                .supplier_order &&
                                            [
                                                10091000,
                                                10091001,
                                                10091004 ].includes(
                                                item
                                                    .saved_supplier_order_detail
                                                    .supplier_order
                                                    .status_id
                                            )
                                    )?_c('v-icon',{attrs:{"slot":"prepend","color":item.saved_supplier_order_detail &&
                                        item.saved_supplier_order_detail
                                            .delivery_date &&
                                        new Date(
                                            item.saved_supplier_order_detail
                                        ) >=
                                            new Date(
                                                _vm.formatDate(
                                                    _vm.getCurrentDate(),
                                                    'yyyy-mm-dd'
                                                )
                                            )
                                            ? 'orange'
                                            : 'red'},slot:"prepend"},[_vm._v(" mdi-timer-sand ")]):_vm._e(),(
                                        item.saved_supplier_order_detail &&
                                            item.saved_supplier_order_detail
                                                .supplier_order &&
                                            [10091005].includes(
                                                item
                                                    .saved_supplier_order_detail
                                                    .supplier_order
                                                    .status_id
                                            )
                                    )?_c('v-icon',{attrs:{"slot":"prepend","color":'green'},slot:"prepend"},[_vm._v(" mdi-check ")]):_vm._e(),(item.supplier.length > 0)?[_vm._v(" "+_vm._s(item.supplier[0].identifier)+" ")]:_vm._e()],2):(
                                    item.article_original_name &&
                                        item.article_original_name.family &&
                                        item.article_original_name.family
                                            .is_stock_managed
                                )?_c('div',{staticStyle:{"max-width":"100px","border":"0px","width":"60px","float":"right"}},[_vm._v(" INT ")]):_vm._e(),(
                                    item.article.family &&
                                        item.article.family
                                            .is_stock_managed == 1 &&
                                        !(
                                            item.supplier &&
                                            item.supplier.length > 0 &&
                                            item.supplier[0] &&
                                            item.supplier[0].id
                                        )
                                )?_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [(
                                            item.article
                                                .internal_company_article &&
                                                item.article
                                                    .internal_company_article
                                                    .length > 0 &&
                                                item.article
                                                    .internal_company_article[0]
                                                    .stock < item.quantity
                                        )?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( "billings.text_messages.article_does_not_have_stock" )))])]):_vm._e()],1)]}},{key:"item.article_original_name",fn:function(ref){
                                        var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{staticStyle:{"display":"flex"},attrs:{"cols":"12","sm":"12"}},[_c('div',{staticStyle:{"width":"100%"}},[(
                                            item.article_original_name &&
                                                item.article_original_name
                                                    .title_description
                                                    .length > 0
                                        )?[_c('div',{staticClass:"cursor-pointer-trans"},[_c('span',[_vm._v(" "+_vm._s(item .article_original_name .title_description[0] .description)),_c('br'),_c('span',{staticClass:"article_identifier"},[_vm._v(" "+_vm._s(item .article_original_name .identifier)+" ")])])])]:[_c('div',{staticClass:"article_identifier",domProps:{"innerHTML":_vm._s(item.text)}})]],2)])],1)]}},{key:"item.quantity",fn:function(ref){
                                        var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(item.quantity)?[_c('span',[_vm._v(" "+_vm._s(_vm.fixDecimal( item.quantity, item ? item.article_original_name ? item .article_original_name .family .quantity_decimals : 0 : 0 ))+" "+_vm._s(item.selectedmu.value)+" ")])]:_vm._e()],2)],1)]}},{key:"item.rounded_unit_price",fn:function(ref){
                                        var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(parseFloat( item.rounded_unit_price ).toFixed(_vm.getDecimalNumber()))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(item.rounded_unit_price ? parseFloat( item.rounded_unit_price ).toFixed(_vm.getDecimalNumber()) : (0).toFixed( _vm.getDecimalNumber() ))+" ")])])],1)]}},{key:"item.red_percentage",fn:function(ref){
                                    var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_vm._v(" "+_vm._s((item.red_percentage ? item.red_percentage : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])])],1)]}},{key:"item.tva",fn:function(ref){
                                    var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_vm._v(" "+_vm._s((item.tva ? item.tva : 0).toFixed( _vm.getDecimalNumber() ))+" ")])])],1)]}},{key:"item.thtva",fn:function(ref){
                                    var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',[_vm._v(" "+_vm._s(item.thtva.toFixed( _vm.getDecimalNumber() ))+" ")]):_c('span',[_vm._v(" "+_vm._s((item.thtva ? item.thtva : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])])],1)]}},{key:"item.total",fn:function(ref){
                                    var item = ref.item;
return [_c('v-row',{on:{"mouseover":function($event){return _vm.toggleElementById(item.srno, 'visible')},"mouseleave":function($event){return _vm.toggleElementById(item.srno, 'hidden')}}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(item.total.toFixed( _vm.getDecimalNumber() ))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s((item.total ? item.total : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])])],1)]}},{key:"body.append",fn:function(){return [(_vm.selectedArticles.length > 0)?_c('tr',{staticClass:"last_row",staticStyle:{"background-color":"rgba(142, 142, 142, 0.07)"}},[_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right ma-0 pa-0 mt-1 mb-1"},[_c('custom-money-input',{ref:"reduction_on_order",staticClass:"pt-0 to-upper right-aligned-input shrink float-right pr-2",staticStyle:{"width":"80px"},attrs:{"disabled":true,"hide-details":"auto","dense":"","tabindex":"3","prefix":"%","properties":{
                                        prefix: '%',
                                        readonly: true,
                                        disabled: true,
                                        outlined: false,
                                        placeholder:
                                            _vm.getDecimalNumber() == 2
                                                ? '0.00'
                                                : '0.000',
                                        dense: true,
                                        hideDetails: 'auto',
                                    },"options":{
                                        locale: 'en',
                                        length: 12,
                                        precision: _vm.getDecimalNumber(),
                                        empty:
                                            _vm.getDecimalNumber() == 2
                                                ? 0.0
                                                : 0.0,
                                    }},on:{"keydown":function($event){return _vm.handleInput($event, 'float', 5)}},model:{value:(_vm.reduction_on_order),callback:function ($$v) {_vm.reduction_on_order=$$v},expression:"reduction_on_order"}})],1),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right"},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.articleTHTVATotalBeforeReduction.toFixed( _vm.getDecimalNumber() ))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s((_vm.articleTHTVATotalBeforeReduction ? _vm.articleTHTVATotalBeforeReduction : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])]),_c('th',{staticClass:"text-sm-right"},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.articleTotalBeforeReduction.toFixed( _vm.getDecimalNumber() ))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s((_vm.articleTotalBeforeReduction ? _vm.articleTotalBeforeReduction : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])])]):_vm._e(),(
                                _vm.selectedArticles.length > 0 &&
                                    _vm.total_reduction > 0
                            )?_c('tr',{staticClass:"last_row",staticStyle:{"background-color":"rgba(142, 142, 142, 0.07)"}},[_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" - "+_vm._s(_vm.total_reduction)+" ")]),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right"}),_c('th',{staticClass:"text-sm-right"},[(
                                        _vm.billType &&
                                            _vm.billType.key == 'credit_note'
                                    )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.articleTotal.toFixed( _vm.getDecimalNumber() ))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s((_vm.articleTotal ? _vm.articleTotal : 0 ).toFixed(_vm.getDecimalNumber()))+" ")])])]):_vm._e(),(
                                _vm.selectedArticles &&
                                    _vm.selectedArticles.length > 0 &&
                                    _vm.selectedBill &&
                                    _vm.selectedBill.type &&
                                    ['bill', 'credit_note'].includes(
                                        _vm.selectedBill.type.key
                                    )
                            )?_c('tr',{staticClass:"last_row",staticStyle:{"background-color":"rgba(142, 142, 142, 0.07)"}},[_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right"},[_c('span',{staticClass:"mr-0"},[_vm._v(" "+_vm._s(_vm.$t("billings.paid"))+" ")])]),_c('th',{staticClass:"text-sm-right text-no-wrap",attrs:{"align":"right"}},[_c('span',[_vm._v(" "+_vm._s(_vm.currencySymbol)+" ("+_vm._s(_vm.totalPaid)+") ")])])]):_vm._e(),(
                                _vm.selectedArticles &&
                                    _vm.selectedArticles.length > 0 &&
                                    _vm.selectedBill &&
                                    _vm.selectedBill.type &&
                                    ['bill', 'credit_note'].includes(
                                        _vm.selectedBill.type.key
                                    )
                            )?_c('tr',{staticClass:"last_row",staticStyle:{"background-color":"rgba(142, 142, 142, 0.07)"}},[_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-left",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right",domProps:{"textContent":_vm._s('')}}),_c('th',{staticClass:"text-sm-right"},[_vm._v(" "+_vm._s(_vm.$t("billings.balance"))+" ")]),_c('th',{staticClass:"text-sm-right"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.paymentBalance)+" ")])])]):_vm._e()]},proxy:true}],null,false,3730870468)})]:[_c('v-container',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2","color":"primary"}})],1)]],2),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.falseButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.$t("attachment.close"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }