var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"1200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","dark":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('billings.history_short'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('billings.history'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{staticStyle:{"height":"800px"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.progressBarLoading,"server-items-length":_vm.totalItems,"items-per-page":_vm.itemsPerPage,"loading-text":this.$t('loading'),"footer-props":{
                                'items-per-page-options': _vm.dataTableOptions,
                            },"height":'calc(-310px + 100vh)'},on:{"update:page":_vm.updatePagination,"click:row":function($event){return _vm.repeatArticles($event.details)}},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
                            var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}}),(item.details.length > _vm.minimalLength)?_c('v-tooltip',{attrs:{"right":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('a',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},_vm._l((item.details),function(articles,index){return _c('v-list-item',{key:index,staticClass:"pa-0 ma-0"},[(
                                        articles.article &&
                                          articles.article.descriptions.length > 0
                                         )?[_vm._v(" "+_vm._s(articles.quantity)+" X "+_vm._s(articles.article.descriptions[0].description)+" ")]:[(articles && articles.text)?[_vm._v(" "+_vm._s(articles.quantity)+" X "+_vm._s(articles.text)+" ")]:_vm._e()]],2)}),1):_vm._e()]}},{key:"item.action",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-1","icon":""}},[_c('v-icon',[_vm._v("mdi-redo")])],1)]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }