var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-data-table',{key:_vm.forceReloadComponentKey,staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
      'items-per-page-options': _vm.dataTableOptions,
    },"headers":_vm.customHeader,"items":_vm.articles,"items-per-page":_vm.itemsPerPage,"options":_vm.options,"sort-by":_vm.sortBy,"server-items-length":_vm.totalItems,"loading-text":this.$t('loading'),"item-key":"identifier","height":'calc(-211px + 100vh)'},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.updatePagination,"update:items-per-page":_vm.updateItemsPerPage,"update:sort-by":_vm.updateSort,"update:sort-desc":_vm.updateSortOrder},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-4 pl-0",attrs:{"flat":""}},[_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"12","sm":"2"}},[_c('v-select',{staticClass:"mr-0 pr-2",attrs:{"items":_vm.familyArticles,"label":_vm.familyArticle
                ? _vm.$t('articles.family')
                : _vm.$t('articles.everything'),"item-value":"id","item-text":"name","prepend-icon":_vm.normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline',"return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"click:prepend":function($event){_vm.normalSearch = !_vm.normalSearch}},model:{value:(_vm.familyArticle),callback:function ($$v) {_vm.familyArticle=$$v},expression:"familyArticle"}})],1),_c('v-col',{staticClass:"d-flex pa-0 pr-2",attrs:{"cols":"12","sm":_vm.customFilters.length > 0 ? 6 : 7}},[(_vm.normalSearch || _vm.customFilters.length == 0)?_c('v-text-field',{staticClass:"to-upper",attrs:{"label":_vm.$t('search'),"prepend-inner-icon":"mdi-magnify","clearable":true,"clear-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();_vm.isDebounceCancelled = true;
              _vm.fetchData();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(!_vm.normalSearch)?[(
                _vm.familyArticle &&
                  (_vm.familyArticle.parent_family_id == 1 ||
                    _vm.familyArticle.id == 1)
              )?_c('v-btn-toggle',{staticClass:"mr-2",attrs:{"id":"season-button","dense":"","mandatory":""},model:{value:(_vm.season),callback:function ($$v) {_vm.season=$$v},expression:"season"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"value":"0"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"grey darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-all ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.shorts.no_season"))+" ")])],1)]}}],null,false,4045992146)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.no_season"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"value":"10111002"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"amber darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-weather-sunny ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.shorts.summer"))+" ")])],1)]}}],null,false,2725465375)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.summer"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"value":"10111001"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"blue darken-2"}},'v-icon',attrs,false),on),[_vm._v(" mdi-weather-snowy-heavy ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.shorts.winter"))+" ")])],1)]}}],null,false,651882354)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.winter"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"value":"10111003"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"green darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-weather-partly-snowy-rainy ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.shorts.all_season"))+" ")])],1)]}}],null,false,2961970239)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("codes.all_season"))+" ")])])],1):_vm._e(),_vm._l((_vm.filterPropertiesExcept(_vm.customFilters)),function(filter,index){return _c('div',{key:index},[(filter.property_name == 'property_00')?[(filter.property_name == 'property_00')?_c('v-autocomplete',{ref:filter.property_name,refInFor:true,staticClass:"mr-2 to-upper",attrs:{"flat":"","items":_vm.brands,"search-input":_vm.searchBrand,"label":_vm.$t('articles.properties.brand'),"item-value":"id","item-text":"name","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"update:searchInput":function($event){_vm.searchBrand=$event},"update:search-input":function($event){_vm.searchBrand=$event},"focus":function($event){_vm.brand = null}},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}):_vm._e()]:[(filter.mapping_key != null)?_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.multiselectsValues[index],"label":filter.text,"item-value":"id","hide-details":"auto","append-icon":"mdi-close","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"click:append":function($event){filter.value = null;
                    _vm.fetchData();},"change":function($event){return _vm.fetchData()}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e(),(filter.mapping_key == null)?_c('v-text-field',{ref:filter.property_name,refInFor:true,staticClass:"mr-2 to-upper",attrs:{"flat":"","label":filter.text,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"focus":function($event){filter.value = null},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();_vm.isDebounceCancelled = true;
                    _vm.fetchData();},"keypress":function($event){return _vm.handleInput($event, filter.keytype, filter.search_length)},"keyup":function($event){_vm.handleAutoFocus(
                      $event,
                      filter.search_length,
                      filter.property_name,
                      filter.keytype,
                      _vm.filterPropertiesExcept(_vm.customFilters)[index + 1]
                        ? _vm.filterPropertiesExcept(_vm.customFilters)[index + 1]
                            .property_name
                        : null
                    )}},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}}):_vm._e()]],2)}),(_vm.articleBooleanProperties.length >= 1)?_c('v-select',{attrs:{"label":_vm.$t('articles.properties.more'),"items":_vm.articleBooleanProperties,"item-value":"id","item-text":"name","multiple":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_c('span',[(index === 0 && _vm.booleanSelectedValues.length == 1)?[_vm._v(" "+_vm._s(item.name.substr( 0, _vm.booleanSelectedValues.length > 1 ? 3 : 5 ))+" ")]:_vm._e(),(index === 1)?[_vm._v(" (+"+_vm._s(_vm.booleanSelectedValues.length - 1)+") ")]:_vm._e()],2)]}}],null,false,3507342178),model:{value:(_vm.booleanSelectedValues),callback:function ($$v) {_vm.booleanSelectedValues=$$v},expression:"booleanSelectedValues"}}):_vm._e()]:_vm._e()],2),_c('v-col',{staticClass:"d-flex pa-0",attrs:{"cols":"12","sm":"4"}},[(
              _vm.familyArticle &&
                (_vm.familyArticle.parent_family_id == 1 ||
                  _vm.familyArticle.parent_family_id == 2)
            )?[_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.statuses,"label":_vm.$t('articles.status'),"item-value":"id","item-text":"text","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.approvals,"label":_vm.$t('articles.approval'),"item-value":"id","item-text":"text","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.approval),callback:function ($$v) {_vm.approval=$$v},expression:"approval"}}),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-database-search")])],1),_c('v-btn',{staticClass:"ml-2 text-white-color",attrs:{"disabled":!_vm.checkIfAnyFilterAdded(),"color":"red","red":""},on:{"click":function($event){_vm.show_bulk_updater = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-layers-edit")]),_vm._v(" "+_vm._s(_vm.$t("pricing_rule.selection_edit")))],1)]:[_c('v-btn',{staticClass:"mt-n4",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-database-search")])],1),_c('v-btn',{staticClass:"ml-2 mt-n4 text-white-color",attrs:{"disabled":!_vm.checkIfAnyFilterAdded(),"color":"red","red":""},on:{"click":function($event){_vm.show_bulk_updater = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-layers-edit")]),_vm._v(" "+_vm._s(_vm.$t("pricing_rule.selection_edit")))],1)],(_vm.show_bulk_updater == true)?_c('bulk-updater',{attrs:{"familyArticle":_vm.familyArticle,"search":_vm.search,"customFilters":_vm.customFilters,"brand":_vm.brand,"status":_vm.status,"approval":_vm.approval,"season":_vm.season},on:{"modifyClose":_vm.bulk_updater_dialog_close}}):_vm._e()],2)],1)]},proxy:true},{key:"item.image",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showImage(item)}}},[_vm._v("mdi-image")])],1)]}},{key:"item.property_00",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
            var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id && item.family_id > 2)?_c('v-text-field',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"font-size":"14px"},attrs:{"id":"description","hide-details":"auto","dense":"","tabindex":"3"},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s((item.description ? item.description : "").toUpperCase()))])]}},{key:"item.for_1_wotva",fn:function(ref){
            var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id)?_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"width":"80px"},attrs:{"hide-details":"auto","outlined":"","dense":"","tabindex":"3","customStyle":"font-size:14px;","properties":{
          prefix: _vm.currencySymbol,
          readonly: false,
          disabled: false,
          outlined: false,
          placeholder: '0.0000',
          dense: true,
          hideDetails: 'auto',
        },"options":{
          locale: 'en',
          length: 8,
          precision: 4,
          empty: 0.0,
        }},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(_vm.wotvafor1),callback:function ($$v) {_vm.wotvafor1=$$v},expression:"wotvafor1"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.wotvafor1))])]}},{key:"item.for_1_wtva",fn:function(ref){
        var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id)?_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"width":"80px"},attrs:{"hide-details":"auto","outlined":"","dense":"","tabindex":"3","customStyle":"font-size:14px;","properties":{
          prefix: _vm.currencySymbol,
          readonly: false,
          disabled: false,
          outlined: false,
          placeholder: '0.0000',
          dense: true,
          hideDetails: 'auto',
        },"options":{
          locale: 'en',
          length: 8,
          precision: 4,
          empty: 0.0,
        }},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(_vm.wtvafor1),callback:function ($$v) {_vm.wtvafor1=$$v},expression:"wtvafor1"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.wtvafor1))])]}},{key:"item.for_2_wtva",fn:function(ref){
        var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id)?_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"width":"80px","font-size":"14px"},attrs:{"hide-details":"auto","outlined":"","dense":"","tabindex":"3","customStyle":"font-size:14px;","properties":{
          prefix: _vm.currencySymbol,
          readonly: false,
          disabled: false,
          outlined: false,
          placeholder: '0.0000',
          dense: true,
          hideDetails: 'auto',
        },"options":{
          locale: 'en',
          length: 8,
          precision: 4,
          empty: 0.0,
        }},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(_vm.wtvafor2),callback:function ($$v) {_vm.wtvafor2=$$v},expression:"wtvafor2"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.wtvafor2))])]}},{key:"item.for_3_wtva",fn:function(ref){
        var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id)?_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"width":"80px"},attrs:{"hide-details":"auto","outlined":"","dense":"","tabindex":"3","customStyle":"font-size:14px;","properties":{
          prefix: _vm.currencySymbol,
          readonly: false,
          disabled: false,
          outlined: false,
          placeholder: '0.0000',
          dense: true,
          hideDetails: 'auto',
        },"options":{
          locale: 'en',
          length: 8,
          precision: 4,
          empty: 0.0,
        }},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(_vm.wtvafor3),callback:function ($$v) {_vm.wtvafor3=$$v},expression:"wtvafor3"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.wtvafor3))])]}},{key:"item.for_4_wtva",fn:function(ref){
        var item = ref.item;
return [(_vm.editedItem && _vm.editedItem.id == item.id)?_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",staticStyle:{"width":"80px"},attrs:{"hide-details":"auto","outlined":"","dense":"","tabindex":"3","customStyle":"font-size:14px;","properties":{
          prefix: _vm.currencySymbol,
          readonly: false,
          disabled: false,
          outlined: false,
          placeholder: '0.0000',
          dense: true,
          hideDetails: 'auto',
        },"options":{
          locale: 'en',
          length: 8,
          precision: 4,
          empty: 0.0,
        }},on:{"keydown":function($event){_vm.pauseWatch = false}},model:{value:(_vm.wtvafor4),callback:function ($$v) {_vm.wtvafor4=$$v},expression:"wtvafor4"}}):_c('span',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.wtvafor4))])]}},{key:"item.updated_at",fn:function(ref){
        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.updated_at))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.editedItem && item.id === _vm.editedItem.id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":function($event){_vm.editedItem = null}}},[_vm._v(" mdi-window-close ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.saveItem(item)}}},[_vm._v(" mdi-content-save ")])],1):_c('div',[(_vm.currentSessionEditedArticles.includes(item.id))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_vm._e()],1)]}}])}),(_vm.showImagePopUp)?_c('image-pop-up',{attrs:{"show":_vm.showImagePopUp,"imageUrl":_vm.articleImageBaseUrl + _vm.currentItem.identifier,"data":_vm.currentItem},on:{"closePopUp":function($event){_vm.showImagePopUp = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }