<template>
    <div>
        <!-- Custom Filters -->
        <div
            v-for="(filter, index) in searchProperties"
            :key="index"
        >
            <template v-if="filter.property_name == 'property_00'">
                <v-autocomplete
                    v-if="filter.property_name == 'property_00'"
                    flat
                    :ref="filter.property_name"
                    :items="brands"
                    :search-input.sync="searchBrand"
                    v-model="brand"
                    :label="$t('articles.properties.brand')"
                    class="mr-2 to-upper"
                    item-value="id"
                    item-text="name"
                    append-icon="mdi-close"
                    @click:append="brand = null"
                    return-object
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    :style="{ width: '120px' }"
                ></v-autocomplete>
            </template>
            <template v-else>
                <v-autocomplete
                    v-if="filter.mapping_key != null"
                    v-model="filter.value"
                    :items="multiselectsValues[index]"
                    :label="filter.text"
                    item-value="id"
                    hide-details="auto"
                    class="mr-2"
                    append-icon="mdi-close"
                    @click:append="
                        filter.value = null;
                        fetchData();
                    "
                    v-on:keydown.enter.prevent="
                        isDebounceCancelled = true;
                        fetchData();
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                ></v-autocomplete>
                <v-text-field
                    flat
                    v-if="filter.mapping_key == null"
                    v-model="filter.value"
                    :label="filter.text"
                    class="mr-2 to-upper"
                    :class="{
                        'fixed-width': filter.property_name == 'property_60',
                    }"
                    :ref="filter.property_name"
                    @focus="
                        filter.value = null;
                        resetFurtherBoxesValues(filter.property_name);
                        updateLocalStorageValues();
                    "
                    @keypress="
                        ($event) => {
                            // If key pressed is enter then fetch Data
                            if ($event.keyCode == 13) {
                                fetchData();
                            }
                            handleInput(
                                $event,
                                filter.keytype,
                                filter.search_length
                            );
                        }
                    "
                    @keyup="
                        handleAutoFocus(
                            $event,
                            filter.search_length,
                            filter.property_name,
                            filter.keytype,
                            filterPropertiesExcept(customFilters)[index + 1]
                                ? filterPropertiesExcept(customFilters)[
                                        index + 1
                                ].property_name
                                : null
                        )
                    "
                    v-on:keydown.enter.prevent="
                        isDebounceCancelled = true;
                        fetchData();
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                ></v-text-field>
            </template>
        </div>
        <v-select
            v-if="articleBooleanProperties.length >= 1"
            v-model="booleanSelectedValues"
            :label="$t('articles.properties.more')"
            :items="articleBooleanProperties"
            item-value="id"
            item-text="name"
            multiple
            :outlined="is_boxes"
            :dense="is_boxes"
            return-object
            @change="updateLocalStorageValues()"
        >
            <template v-slot:selection="{ item, index }">
                <span>
                    <template
                        v-if="index === 0 && booleanSelectedValues.length == 1"
                    >
                        {{
                            item.name.substr(
                                0,
                                booleanSelectedValues.length > 1 ? 3 : 5
                            )
                        }}
                    </template>
                    <template v-if="index === 1" class="grey--text caption">
                        (+{{ booleanSelectedValues.length - 1 }})
                    </template>
                </span>
            </template>
        </v-select>
    </div>
</template>
<script>
    export default{
        props:{
            searchProperties: {
                type: Array,
                default: Array
            },
            moreProperties: {
                type: Array,
                default: Array
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>
