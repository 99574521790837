<template>
  <v-dialog :value="value" max-width="600px">
    <v-card v-if="employee">
      <v-card-title>
        <span class="headline"> {{ $t('clocking_section.add_clocking_for', {name: employeeDisplayName}) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col :md="addTypeIsSingle ? 4 : 6">
              <v-select
                  :items="addTypeOptions"
                  :label="translate('select_duration')"
                  v-model="addType"
                  item-text="text"
                  item-value="value"
              />
            </v-col>
            <v-col :md="addTypeIsSingle ? 5 : 6">
              <v-select
                  :items="processedTypes"
                  :label="translate('clocking_type')"
                  v-model="selectedType"
                  item-text="text"
                  item-value="id"
              />
            </v-col>
            <v-col md="3" v-if="addTypeIsSingle">
              <v-checkbox
                  v-model="isFullDay"
                  :label="translate('is_full_day')"
                  color="success"
                  hide-details
              ></v-checkbox>

            </v-col>
            <v-col :md="isFullDay ? 12 : 4" v-if="addTypeIsSingle">
              <v-menu
                  v-model="dayPickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="selectedDay"
                      :label="translate('filter_options.select_day')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDay"
                    @input="dayPickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="4" v-if="!isFullDay && addTypeIsSingle">
              <v-text-field v-model="fromTime" :label="translate('from_time')" type="time"></v-text-field>
            </v-col>
            <v-col md="4" v-if="!isFullDay && addTypeIsSingle">
              <v-text-field v-model="toTime" :label="translate('to_time')" type="time"></v-text-field>
            </v-col>

            <v-col md="6" v-if="addTypeIsMultiple">
              <v-menu
                  v-model="fromDatePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="selectedFromDate"
                      :label="translate('filter_options.select_from_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedFromDate"
                    @input="fromDatePickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6" v-if="addTypeIsMultiple">
              <v-menu
                  v-model="toDatePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="selectedToDate"
                      :label="translate('filter_options.select_to_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedToDate"
                    @input="toDatePickerMenu = false"
                    :min="selectedFromDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="12">
              <NoteEditor v-model="note"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close" class="mr-2"><CloseButton></CloseButton></span>
        <span @click="save"><SaveButton/></span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import moment from "moment";
import NoteEditor from "@/components/NoteEditor.vue";
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";

export default {
  name: "AddClocking",
  components: {NoteEditor, SaveButton, CloseButton},
  emits: ['input'],
  props: {
    value: Boolean,
    employeeContract: Object,
    day: String,
    fromDate: String,
    toDate: String,
  },
  data() {
    return {
      dayPickerMenu: false,
      selectedDay: null,
      addType: null,
      fromTime: null,
      toTime: null,
      isFullDay: false,
      fromDatePickerMenu: false,
      toDatePickerMenu: false,
      selectedFromDate: null,
      selectedToDate: null,
      selectedType: null,
      note: null,
      addTypeOptions: [
        {
          text: this.translate('single_day'),
          value: "single"
        },
        {
          text: this.translate('multiple_days'),
          value: "multiple"
        }
      ],
      clockingTypes: [],
      headers: {
        'Authorization': 'Bearer ' + this.$store.state.AccessToken
      },
    }
  },
  async mounted() {
    this.addType = Object.values(this.addTypeOptions)[0].value
    this.clockingTypes = await this.getCodes('clocking_types')
  },
  watch: {
    value() {
      if (!this.value) {
        return false
      }
      this.resetForm();

      if (this.day) {
        this.selectedDay = this.day
      }

      this.addType = Object.values(this.addTypeOptions)[0].value
    },
    selectedFromDate(val) {
      if (!this.selectedToDate || !val) return false

      let fromDate = moment(val)
      let toDate = moment(this.selectedToDate)

      if (fromDate.isAfter(toDate)) {
        this.selectedToDate = null
      }
    },
  },
  computed: {
    employee() {
      return this.employeeContract?.employee ?? null;
    },
    addTypeIsSingle() {
      return this.addType === 'single'
    },
    addTypeIsMultiple() {
      return this.addType === 'multiple'
    },
    employeeDisplayName() {
      if (!this.employee) {
        return ""
      }

      return this.employee.first_name + ' ' + this.employee.last_name + ' (' + this.employee.badge + ')'
    },
    processedTypes() {
      return this.clockingTypes
          //     .filter(item => {
          //   return !(['work', 'break', 'mission', 'unspecified'].includes(item.key))
          // })
          .map(item => {
            item.text = this.translate('types.' + item.key)
            return item
          })
      // sort by key
      .sort((a, b) => a.key.localeCompare(b.key))
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
      // restore form State
      this.resetForm();
    },
    resetForm() {
      this.addType = Object.values(this.addTypeOptions)[0].value
      this.selectedDay = null
      this.fromTime = null
      this.toTime = null
      this.isFullDay = false
      this.fromDatePickerMenu = false
      this.toDatePickerMenu = false
      this.selectedFromDate = null
      this.selectedToDate = null
      this.selectedType = null
    },
    save() {
      if (!this.selectedType) {
        return this.$toast.error(this.translate('select_clocking_type'))
      }

      if (this.addTypeIsSingle) {
        if (!this.selectedDay) {
          return this.$toast.error(this.translate('select_day'))
        }

        if (!this.isFullDay) {
          if (!this.fromTime) {
            return this.$toast.error(this.translate('select_time'))
          }

          if (!this.toTime) {
            return this.$toast.error(this.translate('select_to_time'))
          }

          // using moment check if fromTime is greater than toTime
          let fromTime = moment(this.fromTime, 'HH:mm')
          let toTime = moment(this.toTime, 'HH:mm')

          if (fromTime.isAfter(toTime)) {
            return this.$toast.error(this.translate('from_time_greater'))
          }
        }

      }

      if (this.addTypeIsMultiple) {
        if (!this.selectedFromDate) {
          return this.$toast.error(this.translate('select_from_date'))
        }

        if (!this.selectedToDate) {
          return this.$toast.error(this.translate('select_to_date'))
        }
        // use moment to do the Comparison
        let fromDate = moment(this.selectedFromDate)
        let toDate = moment(this.selectedToDate)

        if (fromDate.isAfter(toDate)) {
          return this.$toast.error(this.translate('from_date_greater'))
        }
      }

      let payload = {
        employee_contract_id: this.employeeContract.id,
        clocking_type_id: this.selectedType,
        main_day: this.selectedDay,
        duration: this.addType,
        is_full_day: this.isFullDay,
        main_from_time: this.fromTime,
        main_to_time: this.toTime,
        note: this.note,
        main_from_date: this.selectedFromDate,
        main_to_date: this.selectedToDate,
        from_date: this.fromDate,
        to_date: this.toDate,
      }

      axios.post(API_BASE_URL + '/employees/clocking/admin', payload, {headers: this.headers})
          .finally(() => {
          })
          .then(response => {
            this.$toast.success('Clocking added successfully')
            this.close();
            this.$emit('clockingAdded', response.data)
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
    },
    translate(key) {
      if (!this.isset(key)) return "";
      return this.$t('clocking_section.' + key)
    },
  }
}
</script>

<style scoped>

</style>