<template>
    <div :class="notFullScreen ? 'pdf-viewer-not-full-screen' : 'pdf-viewer'" v-if="$store.state.previewUrl">
        {{ isImage ? 'SSDF' : '' }}
        <div id="header" :class="isImage ? 'full-width' : ''">
            <div class="iconAndFileNameBlock">
                <v-btn icon color="white" @click="resetToNull">
                    <v-icon dark>mdi-arrow-left</v-icon>
                </v-btn>
                <v-icon dark :color="isPdf() ? 'red' : 'blue'" class="ma-2 mb-3">
                    <template v-if="isPdf()">
                        mdi-file-pdf-box
                    </template>
                    <template v-else-if="isImage">
                        mdi-image
                    </template>
                </v-icon>
                <span class="filename">{{ $store.state.previewFileName }}</span>
            </div>
            <div class="imageNavBar" v-if="isImage">
                <v-row style="justify-content: center;">
                    <v-col cols="12" sm="1" style="text-align: end;">
                        <v-btn icon color="white" @click="decreaseSize">
                            <v-icon color="white">mdi-magnify-minus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-slider hide-details v-model="imageWidth" max="3000" min="400"></v-slider>
                    </v-col>
                    <v-col cols="12" sm="1" style="align-content: start;">
                        <v-btn icon color="white" @click="increaseSize">
                            <v-icon color="white">mdi-magnify-plus</v-icon>
                        </v-btn>
                        <v-btn icon color="white" @click="downloadImage(frameSrc)">
                            <v-icon color="white">mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div :class="isPdf() ? 'pdf-outer-box' : 'outer-box'" @click="resetToNull">
            <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                class="loader"
                indeterminate
            ></v-progress-circular>

            <iframe v-if="frameSrc && isPdf()" @click="resetToNull" class="box" style="display:none;"
                onload="this.style.display = 'block';" :src="frameSrc" height="100%" width="80%" frameBorder="0"> </iframe>
            <template  v-if="isImage">
                <div class="imageViewer">
                    <div class="imageData">
                        <v-img @click.stop="updateImageSizeStatus()" :src="frameSrc" contain :width="imageWidth" class="center" ></v-img>
                    </div>
                </div>
            </template>
        </div>
        <loading-model :showLoadingModel.sync="showLoadingModel"></loading-model>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import LoadingModel from "@/components/LoadingModel";

export default {
    props: {
        filename: { type: String, default: 'Article Identifier' },
        notFullScreen: { type: Boolean, default: false}
    },
    components: {
        "loading-model": LoadingModel,
    },
    computed:{
        isImage(){
            return ['image/jpeg','image/jpg', 'image/gif', 'image/png', 'image/svg+xml', 'image/webp'].includes(this.$store.state.mimeType);
        },
    },
    data() {
        return {
            frameSrc: null,
            loading: true,
            imageWidth: 500,
            imageSizeStatus: 0, // 0 Normal Size, 1 Increased Size
            showLoadingModel: false,
        }
    },
    methods: {
        generateFileName(){
            let currentdate = new Date(); 
            let datetime = currentdate.getDate() + "_"
                            + (currentdate.getMonth()+1)  + "_" 
                            // + currentdate.getFullYear() + " @ "  
                            + currentdate.getHours() + ":"  
                            + currentdate.getMinutes() + ":" 
                            + currentdate.getSeconds();
            return datetime;

        },
        downloadImage(imageUrl){
            // Fetch the image
            this.showLoadingModel = true;
            axios.get(imageUrl, { responseType: 'blob' }) // Set the responseType to 'blob'
            .then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                // Create a temporary anchor element
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = this.$store.state.previewFileName ? this.$store.state.previewFileName :this.generateFileName(); // Set the filename for download
                // Append the anchor to the body
                document.body.appendChild(a);
                // Programmatically trigger a click event
                a.click();
                // Clean up
                window.URL.revokeObjectURL(url);
                a.remove();
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                this.showLoadingModel = false;
            });
        },
        increaseSize(){
            this.imageWidth = this.imageWidth + 100;
        },
        decreaseSize(){
            this.imageWidth = this.imageWidth - 100;
        },
        updateImageSizeStatus(){
            this.imageSizeStatus = !! this.imageSizeStatus;
            if(this.imageSizeStatus == 1){
                this.imageSizeStatus = 0;
                this.imageWidth = this.imageWidth / 2;
            }
            else {
                this.imageSizeStatus = 1;
                this.imageWidth = this.imageWidth * 2;
            }
        },
        isPdf(){
            return ['application/pdf'].includes(this.$store.state.mimeType);
        },
        resetToNull() {
            this.$store.commit("setPreviewUrl", null);
            this.$store.commit("setPreviewMimeType", null)
        },
        hideLoader() {
            alert("hello");
        },
        b64toBlob(b64Data, contentType='', sliceSize=512){
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        setAuthBasedSrc() {
            console.log("set auth entered");
            this.loading = true;
            if(this.$store.state.mimeType == "application/pdf"){
                axios.get(this.$store.state.previewUrl, {
                    headers: { Authorization: "Bearer " + this.$store.state.AccessToken }
                })
                .then(({ data }) => {
                    if(typeof data == 'string' && data.length < 500){
                        this.frameSrc = data;
                    }
                    else{
                        console.log("worksheet data for printing -------hello", data);
                        let base64Data = data; // Assuming the server returns the base64-encoded data directly

                        // Set the iframe source directly with base64 data
                        // this.frameSrc = "data:" + (this.$store.state.mimeType ?? "application/pdf") + ";base64," + base64Data;
                        
                        let blob = this.b64toBlob(base64Data, this.$store.state.mimeType);
                        const blobUrl = URL.createObjectURL(blob);
                        
                        if(this.$store.state.openInNewWindow){
                            window.open(blobUrl);
                            this.resetToNull();
                            this.resetPDFViewerProperties();
                        }
                        else{
                            this.frameSrc = blobUrl;
                        }
                    }
                })
                .catch( (error) => {
                    console.log("error raised", error);
                    this.$toast.error(error?.response?.data?.message ?? this.$t('unknown_error'))
                    setTimeout(() => {
                        this.$store.state.previewUrl = null;
                    }, 2000);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            else{
                console.log("opening file in new window");
                if(this.$store.state.openInNewWindow){
                    window.open(this.$store.state.previewUrl); 
                    this.resetToNull();
                    this.resetPDFViewerProperties();
                }
                else{
                    this.frameSrc = this.$store.state.previewUrl;
                }
                
                this.loading = false;
            }
        }
    },
    mounted() {
        console.log("mounted pdf viewer", this.$store.state.previewIsAuthDependent);
        if(this.$store.state.previewIsAuthDependent){
            this.setAuthBasedSrc();
        }
        else{
            this.frameSrc = this.$store.state.previewUrl;
            this.loading = false;
        }
    }
}
</script>
<style>
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 50%; */
}
.pdf-viewer {
    background-color: rgba(0, 0, 0, 0.767);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
}

.pdf-viewer-not-full-screen {
    background-color: rgba(0, 0, 0, 0.767);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.box {
    display: block;
    margin: auto;
    position: initial;
}



#header {
    position: fixed;
    height: 6vh;
    padding: 10px;
    /* background: #020024;
    background: linear-gradient(180deg, rgba(6, 5, 34, .616), rgba(22, 22, 37, 0) 99%, #000); */
    display: flex;
}

.outer-box {
    height: 94vh;
    position: relative;
    top: 6vh;
    display: flex;
}
.pdf-outer-box{
    height: 100%;
    display: flex;
}
.filename {
    top: 29px;
    font-size: 17px;
    color: #fff;
    position: relative;
    left: -66px;
}

.loader{
    position: relative;
    top: 40%;
    left: 50%;
}
.imageViewer{
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 5px;
    width: 100%;
}
.imageNavBar{
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 83%;
    padding: 0px;
}
.full-width{
    width: 100%;
}
</style>