<template>
    <v-dialog v-model="popUpModelVisible" max-width="800" @click:outside="closePopUpModel()" @keydown.esc="closePopUpModel()">
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{ $t('receptions.modify_receptions') }}  - {{ data.supplier_order_details.article.original_name }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <!-- <span>
                        {{ data }}
                    </span> -->
                    <v-col>
                        <v-autocomplete
                            disabled
                            flat
                            :items="[data.supplier]"
                            v-model="data.supplier"
                            :label="$t('receptions.supplier')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="data.supplier_order_details.confirmed_quantity"
                            :label="$t('receptions.confirmed_quant')"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="data.supplier_order_details.received_quantity"
                            :label="$t('receptions.received_quant')"
                            readonly
                            disabled
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="rec_status"
                            :items="rec_statuses"
                            :label="$t('receptions.status')"
                            item-value="id"
                            item-text="text"
                            class="mr-2"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            @click:append="status = null"
                            hide-details
                            append-icon="mdi-close"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey-lighten-2" @click="closePopUpModel()">
                    {{ $t('receptions.modify_article.cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn color="green" dark @click="SavePopUpModel()">
                    {{ $t('receptions.modify_article.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { validationMixin } from 'vuelidate';
    import axios from "@/plugins/axios";
    import { API_BASE_URL } from "@/config";

    export default {
        mixins: [validationMixin],
        validations: {
        },
        props:{
            rec_statuses: [Object],
            data: [Object]
        },
        data() {
            return {
                is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
                popUpModelVisible: true,
                isLoading: false,
                autocompleteValueLocal : 'off',
                rec_status: null,
                savedReception: false,
            };
        },
        mounted(){
            this.autocompleteValueLocal = this.autocompleteValue();
        },
        methods:{
            closePopUpModel(){
                this.popUpModelVisible = false;
            },
            makeSubmitData(){
                return {
                    is_partial_update: 1,
                    status_id: this.rec_status.id,
                    confirmed_quantity: this.data.supplier_order_details.confirmed_quantity,
                    article_id: this.data.supplier_order_details.article.id
                };
            },
            async SavePopUpModel(){
                await this.saveReception(this.makeSubmitData()).then(() => {
                    this.savedReception = true;
                    this.popUpModelVisible = false;
                });
            },
            saveReception(data) {
                return new Promise((resolve) => {
                    axios.put(API_BASE_URL + '/suppliers/order/' + this.data.id, data, {headers: this.header})
                    .then(data => {
                        this.$toast.success(this.$t('receptions.text_messages.reception_updated_successfully'));
                    })
                    .finally(() => {
                        resolve();
                    });
                });
            },
        },
        computed:{
        },
        watch:   {
            popUpModelVisible(val){
                this.$emit('change-popup-model-visibility', this.savedReception);
            },
            data:{
                handler: function (val) {
                    this.rec_status = this.data.status;
                },
                immediate: true
            }
        }
    }
</script>