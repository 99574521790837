<template>
    <v-sheet color="white" elevation="3" height="60" width="200" class="main-sheet">
            <v-row class="ml-0 mt-0">
                <v-col class="d-flex ma-0 pa-0" cols="12" sm="2">
                    <v-sheet class="side-sheet" elevation="3" height="56" width="38">
                        <div>
                            <v-icon dark class="ml-1 mt-1"> mdi-dots-circle </v-icon>
                            <span class="side-sheet-footer">
                                <strong style="color:white"> B </strong>
                            </span>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col class="d-flex ma-0 ml-n0 pa-0" cols="12" sm="10">
                    <v-sheet color="white" class="text" elevation="3" height="56" width="162">
                        <span class="span-number">  
                            <strong class="number"> {{ vehicleDetail.registration_number }} </strong>
                        </span>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-sheet>
</template>
<script>
export default {
    props:{
        vehicleDetail: [String, Number]
    },
    data() {
        return {
            selected: false,
            number_plate: {}
        }
    },
}
</script>
<style scoped>
    .main-sheet{
        border:2px solid red !important;
    }
    .span-number:hover > .number{
        text-decoration: underline;
        text-decoration-thickness: 0.1em;
        text-decoration-color: red;
    }
    .side-sheet{
        background-color: #0A47A7 !important;
    }
    .side-sheet-footer{
        margin:10px;
    }
    .number{
        font-size:26px;
    }
    .span-number{
        display: inline-block;
        margin-top:7px;
        margin-left: 5px;
    }
    .text{
        overflow-y: auto;
    }
</style>