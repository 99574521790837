var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-10 loginbox",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"6","lg":"4"}},[_c('v-card',{ref:"form",staticClass:"box-shad-none card-authenticated-wrapper"},[_c('v-card-text',{staticClass:"pl-0 pr-0 pt-0"},[_c('v-card-title',{staticClass:"pl-3 pr-3 mb-0 card-title-heading"},[_c('h3',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t("login.title")))])]),_c('v-progress-linear',{attrs:{"active":_vm.isLoading,"indeterminate":"","color":"primary"}}),_c('form',{staticClass:"pl-3 pr-3 pt-3"},[_vm._l((_vm.alerts),function(alert){return _c('div',{key:alert,attrs:{"hidden":!alert.show}},[_c('v-alert',{attrs:{"type":alert.type,"dismissible":""}},[_vm._v(" "+_vm._s(alert.msg)+" ")])],1)}),_c('v-text-field',{attrs:{"label":_vm.$t('login.email'),"error-messages":_vm.$store.state.validation
                ? _vm.returnErrorMessage(
                    [!_vm.$v.email.required, this.$t('login.email_required')],
                    [!_vm.$v.email.email, this.$t('login.email_required')]
                  )
                : [],"required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submitLogin()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('login.password'),"error-messages":_vm.$store.state.validation
                ? _vm.returnErrorMessage(
                    [
                      !_vm.$v.password.required,
                      this.$t('login.password_required') ],
                    [
                      !_vm.$v.password.minLength,
                      this.$t('login.password_minimum_length') ]
                  )
                : [],"required":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.submitLogin()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-right d-flex align-center justify-content-between"},[_c('v-checkbox',{attrs:{"label":_vm.$t('login.rememberMe')},on:{"change":function($event){return _vm.onRememberme()}},model:{value:(_vm.chkRemember),callback:function ($$v) {_vm.chkRemember=$$v},expression:"chkRemember"}}),_c('span',{staticClass:"forgot-password-txt"},[_c('a',{attrs:{"href":"/forgot-password"}},[_vm._v(_vm._s(_vm.$t("login.forgotPassword")))])])],1)],2)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitLogin}},[_vm._v(" "+_vm._s(_vm.$t("login.title"))+" "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-login-variant")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }