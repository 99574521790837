var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","min-width":"300","max-width":"800"},on:{"click:outside":function($event){return _vm.falseButtonClicked()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.falseButtonClicked()}},model:{value:(_vm.internalShowDialog),callback:function ($$v) {_vm.internalShowDialog=$$v},expression:"internalShowDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-title"},[_vm._v(" "+_vm._s(_vm.$t("billings.payment_methods.title"))+" ")]),(_vm.isContentLoaded)?_c('v-card-text',{staticClass:"dialog-text text-content",attrs:{"color":"black"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.payment_methods,"item-value":"id","item-text":"text","return-object":"","menu-props":"auto","label":_vm.$t('billings.payed_by'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.payed_by_id),callback:function ($$v) {_vm.payed_by_id=$$v},expression:"payed_by_id"}})],1),_c('v-col',{style:({
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '220px',
                        }),attrs:{"cols":"12","sm":"4"}},[_c('custom-money-input',{ref:"tableUnitPrice",staticClass:"pt-4 to-upper right-aligned-input shrink",staticStyle:{"width":"250px"},attrs:{"label":_vm.$t('bulk_updater.amount'),"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","outlined":"","dense":"","tabindex":"3","properties":{
                                    prefix: _vm.currencySymbol,
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    placeholder: _vm.getDecimalNumber() == 2 ? '0.00' : '0.000',
                                    dense: true,
                                    hideDetails: 'auto',
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 12,
                                    precision: _vm.getDecimalNumber(),
                                    empty: _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
                                }},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),(_vm.refreshContentOnChange)?_c('v-col',{style:({
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '220px',
                        }),attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{staticClass:"to-upper right-aligned-input shrink",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('billings.payed_at'),"type":"datetime-local"},model:{value:(_vm.payed_at),callback:function ($$v) {_vm.payed_at=$$v},expression:"payed_at"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"success","disabled":_vm.checkPaymentAllowed},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1)],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
                            'items-per-page-options': _vm.dataTableOptions,
                        },"hide-default-footer":true,"headers":_vm.headers,"items":_vm.articlePayments,"options":_vm.options,"server-items-length":100,"loading":_vm.isLoading,"loading-text":this.$t('loading'),"items-per-page":100,"item-key":"identifier","height":"400px","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"mustSort":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [_c('tr',[_c('td',[(!_vm.editModeItem || (_vm.editModeItem && _vm.editModeItem.id != item.id))?[(item.payed_by_id && typeof item.payed_by_id == 'object')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('billings.payment_methods.' + item.payed_by_id.key))+" ")]):(item.payed_by && typeof item.payed_by == 'object')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('billings.payment_methods.' + item.payed_by.key))+" ")]):(item.payed_by && typeof item.payed_by == 'number')?_c('span',[_vm._v(" "+_vm._s(item.payed_by_id)+" ")]):_vm._e()]:_vm._e(),(_vm.editModeItem && _vm.editModeItem.id == item.id)?[_c('v-autocomplete',{attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.payment_methods,"item-value":"id","item-text":"text","return-object":"","menu-props":"auto","label":_vm.$t('billings.payed_by'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(item.payed_by_id),callback:function ($$v) {_vm.$set(item, "payed_by_id", $$v)},expression:"item.payed_by_id"}})]:_vm._e()],2),_c('td',[(!_vm.editModeItem || (_vm.editModeItem && _vm.editModeItem.id != item.id))?[(item.amount)?[_vm._v(" "+_vm._s((parseFloat(item.amount)).toFixed(_vm.getDecimalNumber()))+" ")]:[_vm._v(" "+_vm._s((0).toFixed(_vm.getDecimalNumber()))+" ")]]:_vm._e(),(_vm.editModeItem && _vm.editModeItem.id == item.id)?[_c('v-col',{style:({
                                                display: 'flex',
                                                flexDirection: 'row',
                                                maxWidth: '150px',
                                            }),attrs:{"cols":"12","sm":"4"}},[_c('custom-money-input',{ref:"tableUnitPrice",staticClass:"to-upper right-aligned-input shrink",staticStyle:{"width":"250px"},attrs:{"label":_vm.$t('bulk_updater.amount'),"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","outlined":"","dense":"","tabindex":"3","properties":{
                                                        prefix: _vm.currencySymbol,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        placeholder: _vm.getDecimalNumber() == 2 ? '0.00' : '0.000',
                                                        dense: true,
                                                        hideDetails: 'auto',
                                                    },"options":{
                                                        locale: _vm.userLanguageId,
                                                        length: 12,
                                                        precision: _vm.getDecimalNumber(),
                                                        empty: _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
                                                    }},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1)]:_vm._e()],2),_c('td',[(!_vm.editModeItem || (_vm.editModeItem && _vm.editModeItem.id != item.id))?[_vm._v(" "+_vm._s(item.executed_at)+" ")]:_vm._e(),(_vm.editModeItem && _vm.editModeItem.id == item.id)?[_c('v-text-field',{staticClass:"to-upper right-aligned-input shrink",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('billings.payed_at'),"type":"datetime-local"},on:{"change":function($event){return _vm.fixFormat(item,item.executed_at, index)}},model:{value:(item.executed_at),callback:function ($$v) {_vm.$set(item, "executed_at", $$v)},expression:"item.executed_at"}})]:_vm._e()],2),_c('td',[(_vm.editModeItem && _vm.editModeItem.id == item.id)?[(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"blue"},on:{"click":function($event){return _vm.cancelEdit()}}},[_vm._v(" mdi-close ")]):_vm._e(),(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"},on:{"click":function($event){return _vm.saveItem(item, index)}}},[_vm._v(" mdi-content-save ")]):_vm._e()]:_vm._e(),(item)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.deletePaymentMethodAfterConfirmation(index)}}},[_vm._v(" mdi-delete ")]):_vm._e()],2)])]}}],null,false,8234300)})],1),_c('v-row',[_c('v-col',{staticClass:"mr-2 mb-3",style:({
							display: 'flex',
							flexDirection: 'row',
							maxWidth: '200px',
							color: 'black'
						}),attrs:{"cols":"12","sm":"5"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("billings.balance")))])]),_c('v-col',{staticClass:"mr-2 mb-3 d-flex",style:({
							display: 'flex',
							flexDirection: 'row',
							maxWidth: '120px',
							color: 'black'
						})},[_c('v-spacer'),_c('strong',{attrs:{"align":"right"}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm.paymentBalance)+" ")])])],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.falseButtonClicked()}}},[_vm._v(" "+_vm._s(_vm.$t("attachment.close"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }