<template>
    <v-row>
        <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
        <v-overlay :value="isLoadingInProgress">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-data-table
            fixed-header
            :footer-props="{
                'items-per-page-options': dataTableOptions
            }"
            :headers="headers"
            :hide-default-header="true"
            :items="rec_items"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :sort-by="sortBy"
            :loading-text="this.$t('loading')"
            @update:page="updatePagination"
            @update:items-per-page="updateItemsPerPage"
            class="elevation-0"
            item-key="id"
            height="76vh"
            style="width: 100%"
        >
            <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                    <tr>
                    <template v-for="header in headers" >
                        <th :key="header.value" scope="col" class="text-right">
                            <template>
                                {{ header.text }}
                            </template>
                        </th>
                    </template>
                    </tr>
                </thead>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="pt-4">
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                class="mr-3 to-upper"
                                :label="$t('receptions.delivery_note_reference')"
                                v-bind:clearable="true"
                                clear-icon="mdi-close"  
                                v-model="delivery_note_ref"
                                v-on:keydown.enter.prevent="addArticleToDataTableFromSelectedArticle()"
                                v-on:keydown="handleInput($event,'alphanumeric',12);"
                                @focus="reception_no=null"
                                @input="debounceInput"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-row v-if="article">
                                <v-col cols="12" sm="11">
                                    <v-text-field
                                        :autocomplete="autocompleteValueLocal"
                                        class="ma-0 mt-n1 pa-0 selectedArticleTextBox to-upper"
                                        append-icon="mdi-close"
                                        @click:append="resetSelectedArticle()"
                                        v-model="article.title_description[0].description"
                                        hide-details="auto"
                                        v-on:keydown.enter.prevent="addArticleToDataTableFromSelectedArticle()"
                                        v-on:keydown.prevent="jumpToField($event, 'tableQuantity')"
                                        v-on:keydown.tab.prevent="jumpToField($event, 'tableQuantity')"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-autocomplete
                                v-if="delivery_note_ref"
                                flat
                                :items="suppliers"
                                v-model="supplier"
                                v-bind:clearable="true"
                                v-on:keydown.enter.prevent="addArticleToDataTableFromSelectedArticle()"
                                :label="$t('receptions.supplier')"
                                @keypress="debounceInput()"
                                class="mr-2 to-upper"
                                item-value="id"
                                item-text="name"
                                return-object
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                :search-input.sync="searchSupplier"
                                hide-no-data
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-if="delivery_note_ref"
                                class="mr-3 to-upper"
                                :label="$t('receptions.localisation')"
                                v-bind:clearable="true"
                                clear-icon="mdi-close"  
                                v-model="localisation"
                                @focus="localisation=null"
                                @input="debounceInput"
                                v-on:keydown.enter.prevent="addArticleToDataTableFromSelectedArticle()"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <reception-search-article
                                v-if="delivery_note_ref"
                                :class="article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="parentSearchSelectedArticleId"
                                :compSearchTextBox.sync="parentSearchTextBox"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="searchArticle"
                            ></reception-search-article>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item="{ item }" >
                <tr>
                    <td>{{ item.id }}</td>
                    <td>
                        <p class="article_name ma-0">{{ item.article.title_description[0].description }}
                            <br/>
                            <span class="identifier">{{item.article.identifier}}</span>
                        </p>
                    </td>
                    <td>
                        {{ item.localisation }}
                    </td>
                    <!-- <td>
                        <template v-if="item.article">
                            {{ item.price }}
                        </template>
                    </td> -->
                    <td align="right">
                        {{ item.totalOrdered }}
                    </td>
                    <td align="right">
                        {{ item.confirmed_quantity }}
                    </td>
                    <td align="right">
                        {{ item.received_quantity }}
                    </td>
                    <td align="right" style="width:200px">
                        <v-row justify="end">
                            <v-col sm="11" class="pa-0 ma-0">
                                <v-text-field
                                    class="mr-3 reception_received to-upper"
                                    v-bind:clearable="true"
                                    v-model="item.received"
                                    @focus="reception_no=null"
                                    @input="debounceInput"
                                    v-on:keydown="handleInput($event,'int',12);"
                                    :background-color="getBackgroundColorForReceivedBox(item.confirmed_quantity,item.received_quantity,item.received)"
                                    outlined
                                    dense
                                    hide-details="auto"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col sm="1" class="pa-0 ma-0 pt-2 to-upper" v-if="item.received < (item.confirmed_quantity - item.received_quantity)">
                                <!-- <v-tooltip  v-model="show" bottom> -->
                                    <!-- <template v-slot:activator="{ on, attrs }"> -->
                                        <v-icon @click="markAsReceived(item)" v-bind="attrs" v-on="on" color="green">mdi-check-circle</v-icon>
                                    <!-- </template> -->
                                    <!-- <span>{{ $t("receptions.mark_as_received")}}</span> -->
                                <!-- </v-tooltip> -->
                            </v-col>
                        </v-row>
                    </td>
                    <td align="right">
                        <v-icon color="primary" @click="showModifyDotDialog(item.id)">mdi-circle-opacity</v-icon>
                        <v-icon color="warning">mdi-information</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <modify-article-dot
            v-if="modifyDots"
            :rec_item.sync="rec_items[clickedItemNumber]"
            @change-modify-article-dialog="changeModifyArticleDialog()"
        ></modify-article-dot>

        <v-btn @click="createReception()" class="d-none" id="create-reception"></v-btn>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import {RECEPTION_CREATE_MODE} from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
import ReceptionSearchArticle from "@/components/Reception/SearchArticle"
import ModifyArticleDot from "@/components/Reception/ModifyArticleDot"

export default {
    name:       "ReceptionListing",
    components: {
        "reception-search-article" : ReceptionSearchArticle,
        "modify-article-dot" : ModifyArticleDot
    },
    mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: 'Bearer ' + this.token };
        this.$store.commit('setFormMode', RECEPTION_CREATE_MODE);
        this.getSuppliers();
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            page:             1,
            lastPage:         null,
            totalItems:       null,
            itemsPerPage:     DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy:          'description',
            sortDesc:         false,
            options:          {},
            pagination:       {},
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            suppliers: [],
            supplier:'',
            menu_rec_date: false,
            rec_date:   null,
            localisation:     null,
            rec_items:      [],
            rec_number: null,
            delivery_note_ref: null,
            isLoading: false,
            articles: [],
            article: null,
            parentSearchSelectedArticleId: null,
            parentSearchTextBox: null,
            parentInternalStockOnly:    false,
            isLoadingInProgress: false,
            show: false,
            modifyDots: false,
            clickedItemNumber: null,
            isDebounceCancelled: false,
            searchSupplier: null,
        }
    },
    methods: {
        getSuppliers(){
            this.suppliers = [];
            
            axios.get(API_BASE_URL + '/suppliers?internal_supplier=true&items_per_page=-1&parent_also=true&search='+ encodeURIComponent(this.searchSupplier),{headers: this.header})
            .then(({ data }) => {
                this.suppliers = data.data;
            })
            .catch(function (error) {
                
            })
            .finally(() => {
                
            });
        },
        debounceInput: _.debounce(function (e) {
            if(this.isDebounceCancelled){
                this.isDebounceCancelled = false;
                return false;
            }
            this.focusOnSearchEAN();
        }, 5000),
        showModifyDotDialog(id){
            this.clickedItemNumber = id - 1;
            this.modifyDots = true;
        },
        changeModifyArticleDialog(){
            this.clickedItemNumber = null;
            this.modifyDots = ! this.modifyDots;
        },
        focusOnSearchEAN(){
            this.$refs['searchArticle'].$refs['searchEAN'].focus();
        },
        markAsReceived(item){
            item.received = item.confirmed_quantity - item.received_quantity;
            this.show = false;
        },
        createReception(){
            
            axios.post(API_BASE_URL + '/receptions/create', this.makeRequest(), {headers: this.header})
            .then(response => {
                if (response.status === 200) {
                    this.$router.push("/receiptions").catch(() => {});
                    this.$toast.success( this.$t('receptions.text_messages.reception_created_successfully'));
                }
                else {
                    this.$toast.error( this.$t('receptions.text_messages.failed_while_sending'));
                }
            })
            .catch(error => {
                this.$toast.error( this.$t('receptions.text_messages.failed_while_sending'));
            })
            .finally(() => {
                
            })
        },
        makeRequest(){
            let reception_items = [];
            this.rec_items.forEach((item) => {
                // push only those, whose received quantity is greater then 0 else, backend code will process them also
                if(item.received > 0){
                    reception_items.push({
                        srno: item.id,
                        article_id: item.article.id,
                        localisation: item.localisation,
                        totalOrdered: item.totalOrdered,
                        price: item.price,
                        received: parseInt(item.received_quantity) + parseInt(item.received),
                        old_reception: item.reception_number ?? null,
                        dot_quantities: item.dot_quantities ?? null
                    });
                }
            });
            let request = {
                'delivery_note_ref': this.delivery_note_ref,
                'rec_items': reception_items,
                'supplier': this.supplier ? this.supplier.id : null
            }
            return request;
        },
        getBackgroundColorForReceivedBox(confirmed_quantity, alreadyreceivedQuantity, receivedQuantity){
            if((confirmed_quantity - alreadyreceivedQuantity) < receivedQuantity){
                return 'rgb(255, 234, 234)'; // red
            }
            else if((confirmed_quantity - alreadyreceivedQuantity) == receivedQuantity){
                return 'rgb(205, 255, 193)'; // green
            }
            else{
                return 'rgb(242, 255, 153)'; // yellow
            }
        },
        showImage(item) {
            alert('THE IMAGE');
        },
        addArticleToDataTableFromSelectedArticle(){
            console.log('rec items length', this.rec_items.length);
            let count = this.rec_items.length + 1;
            let articleDetail = {
                id: count,
                article : this.article,
                localisation: this.localisation,
                price : this.article.unit_price,
                totalOrdered :0,
                confirmed_quantity: 0,
                received_quantity: 0,
                received : 1,
                supplier : this.supplier
            };

            let itemFound = false;
            this.rec_items.forEach((item,index) => {
                console.log('checking items', item);
                if(item.article.id == this.article.id){
                    item.received ++;
                    item.localisation = this.localisation;
                    itemFound = true;
                    this.array_move(this.rec_items,index, 0 );
                }
            });

            if( !itemFound ){
                this.rec_items.push(articleDetail);
                this.array_move(this.rec_items,this.rec_items.length - 1, 0 );
            }

            this.article = null;
            this.articles = [];
            this.parentSearchSelectedArticleId = null;
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        addArticleToDataTableFromChangedSupplier(supplierId){
            this.isLoadingInProgress = true;
            axios.get(API_BASE_URL + '/suppliers/'+ supplierId +'/orders'  ,{headers: this.header})
            .then(({data}) => {
                data.forEach((order) => {
                    this.isLoadingArticle = false;

                    let count = this.rec_items.length + 1;
                    let articleDetail = {
                        id: count,
                        article : order.supplier_order_details.article,
                        localisation: this.localisation,
                        price : order.supplier_order_details.acquisition_price,
                        totalOrdered : order.supplier_order_details.ordered_quantity,
                        confirmed_quantity: order.supplier_order_details.confirmed_quantity,
                        received_quantity: order.supplier_order_details.received_quantity,
                        received : 0,
                        supplier : this.supplier,
                        reception_number: order.supplier_order_reception ? order.supplier_order_reception.reception_number : null
                    };

                    // Check if duplicate items present then increase ordered quantity
                    let itemFound = false;
                    this.rec_items.forEach((item) => {
                        if(item.article.id == order.supplier_order_details.article.id){
                            item.totalOrdered += order.supplier_order_details.ordered_quantity;
                            item.confirmed_quantity += order.supplier_order_details.confirmed_quantity;
                            item.received_quantity += order.supplier_order_details.received_quantity;
                            itemFound = true;
                        }
                    });

                    if( !itemFound ){
                        this.rec_items.push(articleDetail);
                    }
                });
            })
            .catch(() => {
                this.isLoadingInProgress = false;
            })
            .finally(() => {
                this.isLoadingInProgress = false;
                this.isLoadingInProgress = false;
                this.parentSearchTextBox = null;
            });
            
            this.article = null;
            this.articles = [];
            this.parentSearchSelectedArticleId = null;
        },
        deleteItem(item) {

        },
        updatePagination (page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage (itemsPerPage) {
            this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
            this.fetchData();
            this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
        },
        resetSelectedArticle(){
            this.article='';
        },
    },
    computed: {
        headers(){
            return [
                { text: this.$t('receptions.id'),              align: 'start',   sortable: false, value:'id', width:'10' },
                { text: this.$t('receptions.article'),         align: 'start',   sortable: false, value:'id', width:'400' },
                { text: this.$t('receptions.localisation'),    align: 'start',   sortable: false, value:'id', width:'120' },
                { text: this.$t('receptions.ordered_quant'),   align: 'end',     sortable: false, value:'id', width:'100'  },
                { text: this.$t('receptions.confirmed_quant'), align: 'end',     sortable: false, value:'id', width:'100'},
                { text: this.$t('receptions.received_quant'),  align: 'end',     sortable: false, value:'id', width:'100'},
                { text: this.$t('receptions.reception'),       align: 'end',     sortable: false, value:'id', width:'150'},
                { text: this.$t('receptions.op'),              align: 'end',     sortable: false, value:'id', width:'50'}
            ];
        }
    },
    watch:   {
        localisation(val){
            // this.rec_items.forEach((item) => {
            //         item.localisation = val;
            // });
        },
        parentSearchTextBox(val){
            console.log("parent search text box");
            if(val && val.length == 13 && !isNaN(Number(val))){
                console.log("parent search article done 13" ,val);
                this.dialog=false;
                this.isLoadingInProgress = true;

                let itemFound = false;
                this.rec_items.forEach((item,index) => {
                    if(item.article.identifier == val){
                        item.received ++;
                        itemFound = true;
                        item.localisation = this.localisation;
                        this.isLoadingInProgress = false;
                        this.array_move(this.rec_items,index, 0 );
                        
                    }
                });

                if( ! itemFound){
                    axios.get(API_BASE_URL + '/billings/articles/search?page=1&items_per_page=10' + (this.parent_familyClient ? ('&family_client_for_pricing=' + this.parent_familyClient) : '') + ('&identifier=' + val)  ,{headers: this.header})
                    .then(({data}) => {
                        console.log(data.data[0]);
                        if(data.data.length == 0){
                            this.$toast.error( this.$t('scheduler.appointment.no_pricing_found_for_this_article'));
                        }
                        this.articles = [data.data[0]];
                        this.article = data.data[0]
                        this.isLoadingArticle = false;

                        this.addArticleToDataTableFromSelectedArticle();
                    })
                    .catch(() => {
                        this.isLoadingInProgress = false;
                    })
                    .finally(() => {
                        this.isLoadingInProgress = false;
                    });
                }
                this.$nextTick(() => {
                    this.parentSearchTextBox = null;
                });
            }
        },
        parentSearchSelectedArticleId(val){
            if(val && !isNaN(Number(val)) ){
                this.dialog=false;
                this.isLoadingInProgress = true;
                axios.get(API_BASE_URL + '/billings/articles/search?page=1&items_per_page=10' + (this.parent_familyClient ? ('&family_client_for_pricing=' + this.parent_familyClient) : '') + ('&article_ids=' + val)  ,{headers: this.header})
                .then(({data}) => {
                    console.log(data.data[0]);
                    if(data.data.length == 0){
                        this.$toast.error( this.$t('scheduler.appointment.no_pricing_found_for_this_article'));
                    }
                    this.articles = [data.data[0]];
                    this.article = data.data[0]
                    this.isLoadingArticle = false;
                    
                    this.addArticleToDataTableFromSelectedArticle();

                    var articleFilters = JSON.parse(localStorage.getItem("ARTICLE_MODEL_FILTERS") );
                    articleFilters['id'] = val;
                    localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(articleFilters));
                })
                .catch(() => {
                    this.isLoadingInProgress = false;
                })
                .finally(() => {
                    this.isLoadingInProgress = false;
                });
            }
            else{
                this.articles = [];
                this.article = '';
                this.unit_price = 0.00;
                this.red_percentage = 0.00;
            }
        },
        supplier:{
            handler: function(val){
                if(val){
                    this.rec_items = [];
                    this.addArticleToDataTableFromChangedSupplier(val.id);
                }
                else{
                    this.rec_items = [];
                }
            },
        }
    }
}
</script>

<style scoped>
    .articlenotset{
        display: flex !important;
    }
    .articleset{
        display: none !important;
    }
    .selectedArticleTextBox{
        background-color: rgb(220, 255, 220)
    }
    .reception_received{
        width: 120px;
    }
</style>
