<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="loading"
        max-width="700"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            class="ma-8"
            color="primary"
            v-bind="props"
            @click="dialog = !dialog"
            id="addVehicleHistoryButton"
        >
          {{ $t("nav_buttons.add") }}
          <v-icon dark right> mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ formAction }} {{ $t('vehicles.vehicle_tab.vehicle_history') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
<!--                <v-text-field-->
<!--                    :label="$t('vehicles.vehicle_tab.title')"-->
<!--                    v-model="form.title"-->
<!--                    required-->
<!--                    class="to-upper"-->
<!--                ></v-text-field>-->
                <v-combobox
                    clearable
                    :label="$t('vehicles.vehicle_tab.title')"
                    :items="titlePredictions"
                    variant="underlined"
                    v-model="form.title"
                    @keyup="vehicleHistorySearch = $refs.titleSearchInput.internalSearch"
                    ref="titleSearchInput"
                    class="to-upper"
                ></v-combobox>
              </v-col>
              <v-col
                  cols="6"
              >
                <v-text-field
                    :label="$t('vehicles.vehicle_tab.km')"
                    v-model="form.km"
                    required
                    class="to-upper"
                    @keydown="(event) => handleInput(event, 'int')"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="6"
              >

                <v-menu
                    v-model="menu_reg_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.date_time"
                        :label="$t('vehicles.vehicle_tab.operation_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="form.date_time = null"
                        append-icon="mdi-close"
                        class="mr-2 to-upper"
                        hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      v-model="form.date_time"
                      @input="menu_reg_date = false"
                      no-title
                      scrollable
                      :locale="userLanguageId"
                      hide-details
                  ></v-date-picker>
                </v-menu>

              </v-col>
              <v-col
                  cols="12"
              >
                <v-textarea
                    :label="$t('vehicles.vehicle_tab.description')"
                    v-model="form.description"
                    class="to-upper"
                ></v-textarea>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" color="grey lighten-2" @click="dialog = false">
            {{ $t('vehicles.Cancel') }}
            <v-icon right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn :loading="loading" color="success" @click="save()">
            {{ $t('vehicles.Save') }}
            <v-icon dark right> mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import moment from "moment";
import titlePredictionMixin from "@/mixins/titlePredictionMixin.";

export default {
  name: "VehicleHistoryFormModal",
  mixins:[titlePredictionMixin],
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    vehicleHistory: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      form: {
        title: "",
        description: "",
        vehicle_id: this.vehicle.id,
        km: this.vehicle.km,
        date_time: moment().format('YYYY-MM-DD')
      },
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken
        }
      },
      menu_reg_date: false,
    };
  },
  mounted() {
    if (this.vehicleHistory) {
      this.form = this.vehicleHistory;
    }
  },
  watch: {
    vehicleHistory(val) {
      if(!val) return;
      this.form = this.vehicleHistory;
      if(this.form.date_time){
        this.form.date_time = moment(this.form.date_time).format('YYYY-MM-DD')
      }
    },
    dialog(val){
      if(val){
        this.getTitlePredictions();
      }else{
        this.form = {
          title: "",
          description: "",
          vehicle_id: this.vehicle.id,
          km: this.vehicle.km,
          date_time: moment().format('YYYY-MM-DD')
        }
        this.$emit('close');

      }
    }
  },
  computed: {
    formAction() {
      return this.vehicleHistory ? this.$t('vehicles.vehicle_tab.edit') : this.$t('vehicles.vehicle_tab.add');
    },
    loading() {
      return this.$store.state.progressBarLoading;
    },
    titlePredictions(){
      // this.vehicleHistorySearch == "" Return the first 10 elements of this.allTitlePredictions
      return this.allTitlePredictions.filter((item) => {
        return this.vehicleHistorySearch
            ? item.toLowerCase().includes(this.vehicleHistorySearch.toString().toLowerCase())
            : true;
      }).slice(0, 10)
    }
  },
  methods: {
    save() {
      if (!this.form.title || this.form.title === '') {
        return this.$toast.error(this.$t('vehicles.vehicle_tab.title_error'));
      }
      this.form.title = this.form.title.toUpperCase();
      this.form.description = this.form.description.toUpperCase();

      let axiosMethod = this.vehicleHistory ?
          axios.put(`${API_BASE_URL}/vehicle_history/${this.vehicleHistory.id}`, this.form, this.formConfig) :
          axios.post(`${API_BASE_URL}/vehicle_history`, this.form, this.formConfig);

      let successMessage = this.vehicleHistory ? this.$t('vehicles.vehicle_tab.edit_success') : this.$t('vehicles.vehicle_tab.add_success');
      let errorMessage = this.vehicleHistory ? this.$t('vehicles.vehicle_tab.edit_error') : this.$t('vehicles.vehicle_tab.add_error');

      

      axiosMethod.finally(() => {
        
      }).then((response) => {
        this.$toast.success(successMessage);
              this.form= {
                title: "",
                description: "",
                vehicle_id: this.vehicle.id,
              };
             this.$emit('updateVehicleHistory', response.data.data)

        this.dialog = false;
      }).catch((error) => {
        console.log(error.response.data)
        return this.$toast.error(this.filterFirstError(error.response.data.errors ?? errorMessage ));
      })
    }
  },
};

</script>