import {mapState} from "vuex";
import {API_BASE_URL} from "@/config";
import axios from "@/plugins/axios";
import { DateTime } from 'luxon';
import { update } from "lodash";
import moment from "moment";

export default {
    data() {
        return {
            articlePayments: {},
        }
    },
    computed: {
        ...mapState(['selectedBillData']),
        totalPaid(){
            let total = 0;
            console.log("article_payments_total_paid", this.articlePayments);
            if(this.articlePayments && this.articlePayments.length > 0){
                this.articlePayments.forEach(item => {
                    if(item && typeof item == 'object' && item.amount){
                        total += parseFloat(item.amount || 0);
                    }
                    else if(item && typeof item == 'number'){
                        total += parseFloat(item.amount || 0);
                    }
                });
            }

            return total.toFixed(2);
        },
        paymentBalance(){
            let total = this.articleTotal - this.totalPaid;
            console.log("article_payments_total_paid ________________________", this.articleTotal, this.totalPaid);
            return (total > 0 ? total : 0).toFixed(2);
        },
        selectedBillType(){
            if(!this.selectedBillData){
                return null;
            }

            return this.selectedBillData?.type?.key ?? null;
        }
    },
    watch: {
        paymentBalance:{
            handler: function(val) {
                console.log("payment balance", val, this.articlePayments);
                if(this.articlePayments && this.articlePayments.length > 0){
                    // Mark only Paid/Partial Paid when atleast one item is added in articlePayments
                    if(this.paymentBalance == 0){
                        this.setBillStatus('payed');
                    }
                    else{
                        this.setBillStatus('partial_payed');
                    }
                }
            }
        },
        selectedBillData(val){
            console.log("selected bill data", val);
            this.articlePayments = [];

            let payments = val?.payments || null;
            if(
                (!payments || !Array.isArray(payments )) ||
                (Array.isArray(payments) && payments.length === 0)
            ){
                return;
            }
            console.log("selected bill data payments", payments);

            payments.forEach(payment => {
                this.articlePayments.push(payment);
                // this.articlePaymentsTime[payment.payed_by_id] = payment.updated_at ?? moment().format('YYYY-MM-DD');
            });
        }
    },
    methods: {
        async setBillStatus(status_key){
            let allOrderStatuses = [];
            await this.getCodes('order_status', 'billings.statuses')
            .then((data) => {
                allOrderStatuses = data;
            });
            console.log("all order status", allOrderStatuses);
            if(allOrderStatuses && allOrderStatuses.length > 0){
                allOrderStatuses.forEach((element) => {
                    if(element.key == status_key){
                        this.order_status = element;
                    }
                });
            }
        },
        addRemainingBillToField(item){
            let balance = (parseFloat(this.articleTotal).toFixed(2) - this.totalPaid)
            console.log("order_balance", balance);

            this.saveBillPayment(item, balance.toFixed(2));
        },
        saveBillPayment(item, payed_by_id, amount, executed_at){
            return new Promise((resolve, reject) => {
                console.log("date time updated at------=======", item);
                console.log(amount)
                const payload = {
                    order_id : this.selectedBillData.id,
                    amount : amount ?? 0,
                    payed_by_id: payed_by_id ? ( typeof payed_by_id == 'object' ? payed_by_id.id : payed_by_id) :  null,
                    currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : this.defaultCurrencyId,
                }
                if(executed_at){
                    let newFormattedDate = moment(executed_at).tz(this.currentTimeZone).format('YYYY-MM-DD HH:mm:ss');
                    console.log("date time updated at",this.currentTimeZone,  executed_at, newFormattedDate);
                    payload['executed_at'] = newFormattedDate;
                }
                console.log(payload)
    
                const existingPayment = item && typeof item == 'object' && item.id && typeof item.id == 'number' ? item : null;
    
                console.log(existingPayment, "existing_payment")
                
                const requestMethod = existingPayment ? 'put' : 'post';
    
                const url = requestMethod === 'post' ? API_BASE_URL + '/order_payments' : API_BASE_URL + '/order_payments/' + existingPayment.id;
    
                const headers = {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.AccessToken,
                    }
                }
    
                
                axios[requestMethod](url, payload, headers)
                    .then(({data}) => {
                        return resolve(data);
                    })
                    .finally(() => {})
            })
        }
    }
}