<template>
  <v-dialog max-width="800" :value="value" persistent scrollable>
    <v-card>
      <v-card-title>
        {{ $t('clients.attention') }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col md="12">
                <p v-html="note"></p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close()">
          <OkayButton/>
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import OkayButton from "@/components/Buttons/OkayButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";

export default {
  name: "CompanyBankForm",
  components: {OkayButton},
  props: {
    note: {
      required: false
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },

  methods: {
    close() {
      this.$emit('input', false);
      this.value = false;
    },
  }
}
</script>