<template>
  <div class="pt-2">
    <attachment-dialog
        :showDialog.sync="attachmentDialog"
        :data="selectedDepot && selectedDepot.id"
        :countKey="'tyre_depot'"
        :resource="'tyre_depot'"
    ></attachment-dialog>
    <v-row>
      <v-col cols="12" sm="2">
        <v-row class="ma-0 pa-0">
          <filter-customer :selected_vehicle.sync="selected_vehicle"></filter-customer>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10">
        <v-row>
          <v-col cols="12" sm="9">
            <v-row>
              <!-- <v-icon class="mr-3 tyre-hotel-heading"> mdi-format-float-left </v-icon>
              <span class="tyre-hotel-heading"> {{ $t('tyre_hotel.deposits') }} </span> -->
              <v-spacer></v-spacer>
              <!-- <v-btn dense class="mr-2"> -->
              <v-btn small :disabled="selected_vehicle == null" @click="openHistoryTableDialog" class="mr-2 px-4" color="primary">
                {{ $t('tyre_hotel.interventions') }}
                <v-icon class="ml-2" style="color:white">mdi-clipboard-text-clock</v-icon>
              </v-btn>
              <v-btn small :disabled="selected_vehicle == null" @click="openAddTyreDialog" class="mr-2 px-4" color="primary">
                {{ $t('tyre_hotel.add_tyres') }}
                <v-icon dark right> mdi-plus</v-icon>
              </v-btn>
              <v-btn small :disabled="selected_vehicle == null || ! selectedDepot" @click="openModifyTyreDialog"
                     class="mr-2 px-4" color="primary">
                {{ $t('tyre_hotel.to_modify') }}
                <v-icon dark right> mdi-pencil</v-icon>
              </v-btn>
              <v-btn small :disabled="selected_vehicle == null || ! checkIfAnyDepotDetailSelected()" @click="erasePopUp()"
                     class="mr-2  px-4" color="error">
                {{ $t('tyre_hotel.to_erase') }}
                <v-icon dark right> mdi-delete</v-icon>
              </v-btn>
              <v-btn small class="mr-2  px-4" color="primary" @click="isHistory = !isHistory">
                {{ $t('tyre_hotel.history') }}
                <v-icon v-if="isHistory" dark right> mdi-check</v-icon>
                <v-icon v-else dark right> mdi-history</v-icon>
              </v-btn>
              <v-btn small @click="changeDepotStatusPopUp" class="mr-2 px-4"
                     v-if="(selectedDepot && selectedDepot.status && selectedDepot.status.key ==='depot_requested_out')"
                     color="warning"> {{ $t('tyre_hotel.is_out') }}
                <v-icon class="ml-2">mdi-car-arrow-right</v-icon>
              </v-btn>

              <v-btn
                     small
                     @click="launchReturnRequestModal"
                     :disabled="selected_vehicle == null || ! checkIfAnyDepotDetailSelected()" class="mr-2 px-4"
                     color="success">
                {{ $t('tyre_hotel.return_request') }}
                <v-icon dark right> mdi-calendar</v-icon>
              </v-btn>

              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small v-bind="attrs" v-on="on" class="mr-2" color="success">
                    {{ $t('nav_buttons.print') }}
                    <v-icon dark right> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item :disabled="selected_vehicle == null || ! checkIfAnyDepotDetailSelected()" class="mr-2"
                                @click="printReport()">
                      <v-icon  class="mr-2" color="success"> mdi-book-open-page-variant</v-icon>
                      {{ $t('tyre_hotel.report') }}
                  </v-list-item>
                  <v-list-item :disabled="selected_vehicle == null || ! checkIfAnyDepotDetailSelected()" class="mr-2"
                               @click="printLabel()">
                      <v-icon class="mr-2" color="success"> mdi-printer</v-icon>

                      {{ $t('tyre_hotel.bulk.print_labels') }}
                  </v-list-item>
                  <v-list-item v-if="selectedDepot && selectedDepot.depot_manager && selectedDepot.status.key === 'depot_requested_out'" @click="printRequestedOut()">

                      <v-icon class="mr-2" color="success"> mdi-printer</v-icon>

                      {{ $t('tyre_hotel.print_requested_out') }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- </v-btn> -->
            </v-row>
            <v-row class="mt-4 mb-4">
              <v-data-table
                  fixed-header
                  :footer-props="{
                                    'items-per-page-options': dataTableOptions
                                }"
                  :headers="depositHeader"
                  :hide-default-footer="true"
                  :items="depot"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  :sort-by="sortBy"
                  :loading-text="this.$t('loading')"
                  class="elevation-0"
                  item-key="id"
                  height="49vh"
                  style="width: 100%"
                  hide-no-data
              >
                <template v-slot:item="{ item }">
                  <tr :class="item == selectedDepot ? 'bg-grey' : 'bg-white'" @click="selectDepot(item)"
                      v-if="item.deleted_at ? (isHistory) : (true)">
                    <td></td>
                    <td :id="item.id" :data="selectedDepot && selectedDepot.id == item.id ? highlightRow(item.id) : ''">
                        <span v-if="item.status.key">
                            <DepotStatus :status-key="item.status.key"/>
                        </span>

                    </td>
                    <td>
                      {{ item.localisation }}
                    </td>
                    <td>
                      {{ item.created_at }}
                    </td>
                    <td>
                      {{ item.requested_out_for }}
                    </td>
                    <td>
                      <template v-if="isHistory">
                        {{ item.deleted_at }}
                      </template>
                    </td>
                    <td>
                        {{ item.depot_manager }}
                    </td>
                    <td align="right">
                        <v-icon v-if="checkIfSomeErrorInDepot(item.details)" color="error"> mdi-fridge-alert</v-icon>
                        <v-icon v-if="item.note" class="ml-2" color="error"> mdi-information-outline</v-icon>
                    </td>
                    <td>
                        <!-- <v-badge bordered color="orange" :value="item.attachments_count" :content="item.attachments_count > 0 ? item.attachments_count : ''" overlap offset-x="20" offset-y="16">
                            <v-btn class="white--text" color="error" depressed icon @click="showAttachmentDialog">
                                <v-icon color="primary">mdi-attachment</v-icon>
                            </v-btn>
                        </v-badge> -->
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </v-row>
          </v-col>
          <v-col cols="12" sm="3">
            <vehicle-details class="mt-n5" :vehicle_detail.sync="selected_vehicle"></vehicle-details>
          </v-col>
        </v-row>
        <v-row>
          <v-row>
            <v-col cols="12" sm="12">
                            <span class="pr-4">
                                <v-icon class="mb-2 mr-3 tyre-hotel-heading"> mdi-account </v-icon>
                                <span class="tyre-hotel-heading">{{ $t('tyre_hotel.deposit_details.text') }}</span>
                            </span>
              <v-data-table
                  fixed-header
                  :footer-props="{
                                    'items-per-page-options': dataTableOptions
                                }"
                  :hide-default-footer="true"
                  :headers="depositDeatilsHeader"
                  :items="selectedDepotForSubTable"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  :sort-by="sortBy"
                  :loading-text="this.$t('loading')"
                  class="elevation-0"
                  item-key="id"
                  height="32vh"
                  style="width: 100%"
              >
                <template v-slot:item="{ item }">
                  <tr :style="{ backgroundColor: getBackgroundColor(item)}">
                    <td>
                      <v-checkbox
                          class="ma-0 mt-n1"
                          v-model="item.checked"
                          hide-details="auto"
                      ></v-checkbox>
                    </td>
                    <td>
                                        <span v-if="item.position && item.position.id == 10281000">
                                            <v-icon> mdi-cancel </v-icon>
                                            {{ $t('tyre_hotel.position.no_position_shorthand') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281001">
                                            <v-icon> mdi-arrow-top-right-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.top_right_shorthand') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281002">
                                            <v-icon> mdi-arrow-top-left-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.top_left_shorthand') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281003">
                                            <v-icon> mdi-arrow-bottom-right-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.bottom_right_shorthand') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281004">
                                            <v-icon> mdi-arrow-bottom-left-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.bottom_left_shorthand') }}
                                        </span>
                                        <!-- Second Tyres -->
                      <span v-if="item.position && item.position.id == 10281006">
                                            <v-icon> mdi-arrow-top-right-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.top_right_shorthand_2') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281007">
                                            <v-icon> mdi-arrow-top-left-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.top_left_shorthand_2') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281008">
                                            <v-icon> mdi-arrow-bottom-right-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.bottom_right_shorthand_2') }}
                                        </span>
                      <span v-if="item.position && item.position.id == 10281009">
                                            <v-icon> mdi-arrow-bottom-left-bold-box </v-icon>
                                            {{ $t('tyre_hotel.position.bottom_left_shorthand_2') }}
                                        </span>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.article.title_description[0].description }}
                        </template>
                        <template v-else>
                            {{ item.article_name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.article.property.property_03 }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </td>
                    <td>

                        <template v-if="item.article_id">
                            {{ item.article.property.property_04 }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </td>
                    <td>

                        <template v-if="item.article_id">
                            {{ item.article.property.property_05 }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.article.property.property_06 }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.article.property.property_07 }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.mm }}
                        </template>
                        <template v-else>
                            {{ item.mm }}
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.couple }}
                        </template>
                        <template v-else>
                            {{ item.couple }}
                        </template>
                    </td>
                    <td>
                        <template v-if="item.article_id">
                            {{ item.pressure }}
                        </template>
                        <template v-else>
                            {{ item.pressure }}
                        </template>
                    </td>
                    <td>
                        <span v-if="item.rimtype">
                            {{ item.rimtype.value }}
                        </span>
                    </td>
                    <td>
                      <v-icon v-if="item.is_with_hubcaps == 1" color="green">
                        mdi-check
                      </v-icon>
                    </td>
                    <td>
                      <v-icon color="red"
                              v-if="item.depot_article_status_id && item.depot_article_status_id != 10271000">
                        mdi-information-outline
                      </v-icon>
                    </td>
                  </tr>
                </template>

              </v-data-table>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <depot-add-modify-tyre
        v-if="modify_stock_dialog == true"
        :depotDetails.sync="selectedDepot"
        :vehicleDriverDetails.sync="selected_vehicle"
        @modifyClose="change_add_modify_tyre_close"
    ></depot-add-modify-tyre>
    <load-localisation
        v-if="loadLocalisationDialog == true"
        @modifyLoadLocalisationClose="closeLoadLocalisation"
    ></load-localisation>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-btn @click="loadLocalisation()" class="d-none" id="add-localisation"></v-btn>
    <span class="d-none" id="print-labels" @click="printLabelsDialog = true"></span>
    <v-dialog v-model="printLabelsDialog" max-width="800px">
      <v-form @submit.prevent="processPrintLabels">

        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('tyre_hotel.print_label') }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-text-field @keydown="(event) => {
                  handleInput(event, 'alpha', 5)
                }"
                              @keyup="printLabelModel.fromRow = printLabelModel.fromRow.toUpperCase()"

                              v-model="printLabelModel.fromRow" :label="$t('tyre_hotel.from_row')"></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field @keyup="printLabelModel.toRow = printLabelModel.toRow.toUpperCase()" class="to-upper" @keydown="handleInput($event, 'alpha', 5)"
                              v-model="printLabelModel.toRow" :label="$t('tyre_hotel.to_row')"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-text-field class="to-upper" @keydown="handleInput($event, 'int', 5)"
                              v-model="printLabelModel.fromColumn" :label="$t('tyre_hotel.from_column')"></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field class="to-upper" @keydown="handleInput($event, 'int', 5)"
                              v-model="printLabelModel.toColumn" :label="$t('tyre_hotel.to_column')"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-text-field class="to-upper" @keydown="handleInput($event, 'int', 5)"
                              v-model="printLabelModel.fromLevel" :label="$t('tyre_hotel.from_level')"></v-text-field>
              </v-col>
              <v-col md="6">
                <v-text-field class="to-upper" @keydown="handleInput($event, 'int', 5)"
                              v-model="printLabelModel.toLevel"
                              :label="$t('tyre_hotel.to_level')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey-lighten-2" @click="printLabelsDialog = false">
              {{ $t("close") }}
              <v-icon dark right> mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn color="primary" type="submit">
              {{ $t('nav_buttons.print') }}
              <v-icon dark right> mdi-printer</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

    </v-dialog>
    <return-request-modal
        v-model="showReturnRequestModal"
        :depot="selectedDepot"
        @updated="() => fetchDepot(selected_vehicle.id)"
    />
    <history-table v-model="historyTableDialog" :model_id="historyTableModelId" :model="historyTableModel" />
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import {API_BASE_URL, VIEW_MODE} from "@/config";
import {TYRE_DEPOT_MODE} from "@/config";
import {ADD_MODE} from "@/config";
import FilterCustomer from "@/components/TyreHotel/FilterCustomer";
import VehicleDetails from "@/components/TyreHotel/VehicleDetails";
import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
import ConfirmationModel from "@/components/ConfirmationModel";
import {DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import LoadLocalisation from '@/components/TyreHotel/LoadLocalisation.vue';
import DepotStatus from "@/components/TyreHotel/DepotStatus.vue";
import ReturnRequestModal from "@/components/Depot/ReturnRequestModal";
import historyTableMixin from "@/mixins/historyTableMixin";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";
import AttachmentDialog from "@/components/AttachmentDialog";

export default {
  name: "TyreHotelListing",
  components: {
    HistoryTable,
    DepotStatus,
    "filter-customer": FilterCustomer,
    "vehicle-details": VehicleDetails,
    "depot-add-modify-tyre": DepotAddModifyTyre,
    "confirmation-model": ConfirmationModel,
    "load-localisation": LoadLocalisation,
    ReturnRequestModal,
    "attachment-dialog": AttachmentDialog,
  },
  mixins: [historyTableMixin],
  data() {
    return {
      attachmentDialog: false,
      showReturnRequestModal: false,
      printLabelsDialog: false,
      printLabelModel: {
        fromRow: null,
        toRow: null,
        fromColumn: null,
        toColumn: null,
        fromLevel: null,
        toLevel: null,
      },
      loadLocalisationDialog: false,
      isHistory: false,
      modify_stock_dialog: false,
      selected_vehicle: {},
      autocompleteValueLocal: 'off',
      depot: [],
      token: this.$store.state.AccessToken,
      header: {Authorization: 'Bearer ' + this.$store.state.AccessToken},
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: 'description',
      sortDesc: false,
      options: {},
      pagination: {},
      selectedDepot: null,
      selectedDepotForSubTable: [],
      return_request_menu: false,
      return_request_date: null,
      nowDate: new Date().toISOString().slice(0, 10),
      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      lastDepotSelected: null,
      depotStatuses: [],
    }
  },
  methods: {
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    launchReturnRequestModal() {
      this.showReturnRequestModal = false;
      setTimeout(() => this.showReturnRequestModal = true, 10)
    },
    processPrintLabels() {
      if (Object.values(this.printLabelModel).some(i => !this.isset(i))) {
        return this.$toast.error(this.$t('fill_all_field'))
      }

      let {fromRow, toRow, fromColumn, toColumn, fromLevel, toLevel} = this.printLabelModel

      let url = API_BASE_URL + `/depot/print-locations?from_row=${fromRow}&to_row=${toRow}&from_column=${fromColumn}&to_column=${toColumn}&from_level=${fromLevel}&to_level=${toLevel}`
      let fileName = this.$t('print_labels')
      this.printLabelsDialog = false;
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    loadLocalisation() {
      console.log("reached");
      this.loadLocalisationDialog = true;
    },
    setSelectedDepotStatusAsDepotOut() {
      return new Promise((resolve, reject) => {
        
        let newDepotStatus = this.depotStatuses.find(i => i.key === 'depot_out');
        return axios.put(API_BASE_URL + '/depots/' + this.selectedDepot.id + '/status', {
          status_id: newDepotStatus.id
        }, {
          headers: this.header
        }).finally(() => {
          
        }).then(({data}) => {
          let depotIndex = this.depot.findIndex(d => d.id === this.selectedDepot.id);
          this.depot[depotIndex].status_id = newDepotStatus.id
          this.depot[depotIndex].status = newDepotStatus
          this.$toast.success("Article removed from Depot Successfully")
          resolve(true)
        })
      });
    },
    getDepotStatuses() {
      this.getCodes('depot_statuses', false).then(data => this.depotStatuses = data)
    },
    getBackgroundColor(item) {
      if (item.depot_article_status_id && item.depot_article_status_id != 10271000) {
        return 'rgb(252, 185, 185)';
      }
      if (item.mm) {
        if (item.mm < 3) {
          return 'rgb(252, 185, 185)';
        } else if (item.mm >= 3 && item.mm < 5) {
          return 'rgb(254, 236, 199)';
        } else {
          return 'rgb(217, 255, 202)';
        }
      }
    },
    printLabel() {
      let url = API_BASE_URL + "/depot/" + this.selectedDepot.md5_id + "/print?document=labels&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.selectedDepot.client.full_name
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    printRequestedOut() {
      let url = API_BASE_URL + "/depot/" + this.selectedDepot.md5_id + "/print?document=depot_out&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.selectedDepot.client.full_name
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    printReport() {
      let url = API_BASE_URL + "/depot/" + this.selectedDepot.md5_id + "/print?document=report&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.selectedDepot.client.full_name
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    checkIfSomeErrorInDepot(depotDetails) {
      let isError = false;
      depotDetails.forEach((rowdepot) => {
        if (rowdepot.article_status && rowdepot.article_status.key == 'damaged') {
          isError = true;
        }
      })
      return isError;
    },
    change_add_modify_tyre_close(modifyStockDialog, fetchDepot) {
      if (fetchDepot) {
        this.fetchDepot(this.selected_vehicle.id);
      }
      this.modify_stock_dialog = false;
    },
    closeLoadLocalisation(modifyStockDialog) {
      this.loadLocalisationDialog = false;
    },
    openAddTyreDialog() {
      this.lastDepotSelected = this.selectedDepot;
      this.selectedDepot = {};
      this.modify_stock_dialog = true
    },
    openModifyTyreDialog() {
      if (Object.keys(this.selectedDepot).length === 0) {
        this.selectedDepot = this.lastDepotSelected ?? this.depot[0];
      }
      this.modify_stock_dialog = true
    },
    selectDepot(row) {
      this.selectedDepot = row;

      row.details.forEach((detail) => {
        detail.checked = true;
      })
      this.selectedDepotForSubTable = row.details;
    },
    fetchDepot(vehicleID) {
      this.depot = [];
      this.selectedDepot = null;
      this.selectedDepotForSubTable = [];
      
      axios.get(API_BASE_URL + '/depots?soft_deleted_also=true&' + 'vehicle_id=' + vehicleID, {headers: this.header})
          .then(({data}) => {
            this.depot = data.data;
            // Assign Checked property to all the depot items
            data.data.forEach((depot) => {
              depot.checked = false;
              if (!this.selectedDepot && !depot.deleted_at) {
                this.selectedDepot = depot;
              }
            })


            // Assign the first depot
            if (data.data.length > 0 && this.selectedDepot) {
              // Check all the sub positions by default
              this.selectedDepot.details.forEach((detail) => {
                detail.checked = true;
              })
              this.selectedDepotForSubTable = this.selectedDepot.details;
            }
          })
          .catch(function (error) {
            console.log('an error occured ' + error);
          })
          .finally(() => {
            
          });
    },
    checkIfAnyDepotDetailSelected() {
      let isSelected = false;
      if (this.selectedDepot && this.selectedDepot.details) {
        this.selectedDepot.details.forEach((item) => {
          if (item.checked == true) {
            isSelected = true;
          }
        });
      }
      return isSelected;
    },
    getSelectedDepotPositions() {
      let selectedDepotIds = [];
      if (this.selectedDepot && this.selectedDepot.details) {
        this.selectedDepot.details.forEach((item) => {
          if (item.checked == true) {
            selectedDepotIds.push(item.position_id);
          }
        });
      }
      return selectedDepotIds;
    },
    confirmationButtonClicked(buttonClicked) {
      console.log("Confirmation button clicked", buttonClicked);
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    erasePopUp() {
      this.confirmationDialogConfirmationText = this.$t('tyre_hotel.erase_confirmation_text');
      this.confirmationDialogFalseText = this.$t('tyre_hotel.erase_false_button_text');
      this.confirmationDialogTrueText = this.$t('tyre_hotel.erase_true_button_text');
      this.confirmationDialogOperation = 'erase';
      this.confirmationDialog = true;
    },
    changeDepotStatusPopUp() {
      this.confirmationDialogConfirmationText = this.$t('tyre_hotel.change_status_to_depot_out_confirmation_tex');
      this.confirmationDialogFalseText = this.$t('tyre_hotel.erase_false_button_text');
      this.confirmationDialogTrueText = this.$t('tyre_hotel.erase_true_button_text');
      this.confirmationDialogOperation = 'change_status_to_depot_out';
      this.confirmationDialog = true;
    },
    rrCancelPopUp() {
      this.return_request_menu = false;
      this.return_request_date = null
    },
    rrPopUp() {
      if (this.return_request_date) {
        this.confirmationDialogConfirmationText = this.$t('tyre_hotel.return_request_confirmation_text');
        this.confirmationDialogFalseText = this.$t('tyre_hotel.return_request_false_button_text');
        this.confirmationDialogTrueText = this.$t('tyre_hotel.return_request_true_button_text');
        this.confirmationDialogOperation = 'return_request';
        this.confirmationDialog = true;
      }
    },
    erase() {
      axios.put(API_BASE_URL + '/depots/' + this.selectedDepot.id + '/erase', {positions: this.getSelectedDepotPositions()}, {headers: this.header})
          .then((data) => {
            this.fetchDepot(this.selected_vehicle.id);
          })
          .catch((error) => {

          })
          .finally(() => {

          })
    },
    returnRequest() {
      axios.put(API_BASE_URL + '/depots/' + this.selectedDepot.id + '/rr', {
        positions: this.getSelectedDepotPositions(),
        rr_date: this.return_request_date
      }, {headers: this.header})
          .then((data) => {
            this.fetchDepot(this.selected_vehicle.id);
          })
          .catch((error) => {

          })
          .finally(() => {

          })
    },
    highlightRow(itemId) {
      this.$nextTick(() => {
        if (document.getElementsByClassName('tyre-depot-matched-row').length > 0) {
          document.getElementsByClassName('tyre-depot-matched-row')[0].classList.remove('tyre-depot-matched-row');
        }
        document.getElementById(itemId).parentElement.parentElement.className = "tyre-depot-matched-row";
      })
    },
  },
  computed: {
    depositHeader() {
      return [
        {text: " ", align: 'start', sortable: false, value: 'checkbox', width: '10'},
        {text: this.$t('tyre_hotel.deposit.status'), align: 'start', sortable: false, value: 'status', width: '15%'},
        {
          text: this.$t('tyre_hotel.deposit.localized'),
          align: 'start',
          sortable: false,
          value: 'localisation',
          width: '15%'
        },
        {
          text: this.$t('tyre_hotel.deposit.entry_date'),
          align: 'start',
          sortable: false,
          value: 'created_at',
          width: '20%'
        },
        {
          text: this.$t('tyre_hotel.deposit.return_request_date'),
          align: 'start',
          sortable: false,
          value: 'requested_out_for',
          width: '20%'
        },
        {
          text: this.$t('tyre_hotel.deposit.exit_date'),
          align: 'start',
          sortable: false,
          width: '10%',
          value: 'deleted_at',
        },
        {
          text: this.$t('tyre_hotel.deposit.depot_manager'),
          align: 'start',
          sortable: false,
          value: 'depot_manager',
          width: '20%'
        },
        {text: "", align: 'end', sortable: false, value: 'someerrors', width: '10%'},
        {text: "", align: 'end', sortable: false, value: 'attachmentsAdded', width: '10%'},
      ];
    },
    depositDeatilsHeader() {
      return [
        {text: " ", align: 'start', sortable: false, value: 'checkbox', width: '10'},
        {
          text: this.$t('tyre_hotel.deposit_details.position'),
          align: 'start',
          sortable: false,
          value: 'position',
          width: '50'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.profile'),
          align: 'start',
          sortable: false,
          value: 'article',
          width: '350'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.width'),
          align: 'start',
          sortable: false,
          value: 'article_width',
          width: '5'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.height'),
          align: 'start',
          sortable: false,
          value: 'article_height',
          width: '5'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.rim'),
          align: 'start',
          sortable: false,
          value: 'article_rim',
          width: '5'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.li'),
          align: 'start',
          sortable: false,
          value: 'article_li',
          width: '5'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.si'),
          align: 'start',
          sortable: false,
          value: 'article_si',
          width: '5'
        },
        {text: this.$t('tyre_hotel.deposit_details.mm'), align: 'start', sortable: false, value: 'mm', width: '5'},
        {
          text: this.$t('tyre_hotel.deposit_details.tightening_torque'),
          align: 'start',
          sortable: false,
          value: 'couple',
          width: '150'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.pressure'),
          align: 'start',
          sortable: false,
          value: 'pressure',
          width: '5'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.rim_type'),
          align: 'start',
          sortable: false,
          value: 'rim_type',
          width: '100'
        },
        {
          text: this.$t('tyre_hotel.deposit_details.with_enjo'),
          align: 'start',
          sortable: false,
          value: 'with_enjo',
          width: '5'
        },
        // { text: this.$t('tyre_hotel.deposit_details.alu'),              align: 'start',     sortable: false, value:'alu', width:'5' },
        {text: '', align: 'start', sortable: false, value: 'article_status', width: '5'},
      ];
    }
  },
  mounted() {
    this.header = {Authorization: 'Bearer ' + this.token};
    this.autocompleteValueLocal = this.autocompleteValue();
    this.options.itemsPerPage = 9999;
    this.$store.commit("setFormMode", TYRE_DEPOT_MODE);
    this.getDepotStatuses()
  },
  watch: {
    selectedDepot:{
        handler: function(val){
            this.$store.commit("setCurrentPageData", val);
            if(!val) return

            this.setHistoryModalData("Depot", val.id)
        },
        immediate: true
    },
    selected_vehicle: {
      handler: function () {
        if (this.selected_vehicle) {
          this.fetchDepot(this.selected_vehicle.id);
        } else {
          this.depot = [];
          this.selectedDepot = null;
          this.selectedDepotForSubTable = [];
        }
      }
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (this.confirmationDialogOperation == 'erase' && this.confirmationDialogButtonClickedIs == true) {
          this.erase();
        }

        if (this.confirmationDialogOperation == 'return_request' && this.confirmationDialogButtonClickedIs == true) {
          this.returnRequest();
        }

        if (this.confirmationDialogOperation === 'change_status_to_depot_out') {
          this.setSelectedDepotStatusAsDepotOut();
        }

        this.confirmationDialogButtonClickedIs = null;
      }
    }
  }
}
</script>
<style scoped>
/* .tyre-hotel-heading{
    font-size: 20px;
} */
.tyre-depot-matched-row {
  background-color: rgb(230, 230, 230) !important;
  border: 2px solid rgb(230, 230, 230) !important;
}

.damaged-row {
  background-color: #ffeaea !important;
  color: #ff5252;
}

.damaged-row:hover {
  background-color: #ffeaea !important;
  color: #ff5252;
}

.v-text-field__slot >>> input {
  text-transform: uppercase !important;
}
</style>