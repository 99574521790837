export let API_BASE_URL = process.env.VUE_APP_APP_URL + '/api';
export const FAMILY_ARTICLE_CARS = 5;

export const RESET_MODE              = "000000000";
export const VIEW_MODE               = "000000001";
export const ADD_MODE                = "000000010";
export const UPDATE_MODE             = "000000011";
export const EDIT_MODE               = "000000100";
export const DELETE_MODE             = "000001000";
export const DETAILS_MODE            = "000010000";
export const JUST_CANCEL_MODE        = "000000111";
export const BILLING_MODE            = "000100000";
export const SCHEDULER_MODE          = "000110000";
export const SCHEDULER_EDIT_MODE     = "000111000";
export const SCHEDULER_LISTING_MODE  = "000111100";
export const RECEPTION_CREATE_MODE   = "000100001";
export const STOCK_LISTING           = "110000000";
export const TYRE_DEPOT_MODE         = "111000000";
export const RECURRENCE_MODE         = "000001111";
export const REPORT_MODE             = "000011111";
export const EXPEDITION_MODE         = "000111111";
export const EXPEDITION_LISTING_MODE = "000111110";
export const EXPENSE_MODE            = "001111111";
export const EXPENSE_LISTING_MODE    = "001111110";
export const BILLING_LISTING_MODE    = "000110001";


export const COLOR_LONG_SUPPLIER_DELIVERY_DATE = "lightsalmon";

export const COLOR_SHORT_SUPPLIER_DELIVERY_DATE = "#d3ffd3";

export const COLOR_BEST_SUPPLIER_PRICE = "lightgreen";

export const PEPPOL_BILL_TYPES = ["bill", "credit_note"];


export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [ 30, 60, 100 ];
