<template>
  <div :key="employeeContracts.length">
    <v-data-table
        :headers="headers"
        :items="formattedEmployeeContracts"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        fixed-header
        :footer-props="{
            'items-per-page-options': [...perPageOptions],

          }"
        :server-items-length="total"
        class="elevation-0"
        :height="'calc(-150px + 100vh)'"
    >
      <!--  Slot item and tr-->
      <template v-slot:item="{ item }">
        <tr style="text-align: center"
            @click="editItem(item)"
        >
          <td>{{ item.serial }}</td>
          <td style="text-align: left">{{ item.employee_label }}</td>
          <td style="text-align: left">{{ item.contract_label }}</td>
          <td>{{ item.start_date_label }}</td>
          <td>{{ item.end_date_label }}</td>
          <td style="text-align: left">{{ item.age_label }}</td>
          <td>
            <v-btn icon>
              <v-icon v-if="item.is_active" color="success">mdi-check</v-icon>
              <v-icon v-else color="error">mdi-close</v-icon>
            </v-btn>
          </td>
<!--          <td>-->
<!--            <span :id="'action_' + item.id" style="display: none">-->
<!--            <v-btn icon @click="openDetails(item)">-->
<!--              <v-icon color="primary">mdi-eye</v-icon>-->
<!--            </v-btn>-->
<!--          </span>-->
<!--          </td>-->
        </tr>
      </template>
    </v-data-table>
    <employee-contract-details
        v-model="showForm"
        :employeeContract="selectedEmployeeContract"
        @updated="updatedHandler"
        @deleted="deletedHandler"
    />
    <employee-contract-form
        :employee-contract="selectedEmployeeContract"
        v-model="formDialog"
        @created="createdHandler"
        @updated="updatedHandler"
    />
    <span class="d-none" id="add-new" @click="showFormDialog()"></span>
  </div>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";

export default {
  name: 'EmployeeContractsListing',
  mixins: [clockingMixin],
  components: {
    EmployeeContractDetails: () => import('@/views/Clocking/EmployeeContracts/Detail.vue'),
    EmployeeContractForm: () => import('@/views/Clocking/EmployeeContracts/Form.vue')
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('srno'),
          value: 'serial',
          align: 'center',
          sortable: false,
          width: 50
        },
        {
          text: this.translate('employee'),
          value: 'employee_label',
          sortable: false,
          align: 'left'
        },
        {
          text: this.translate('contract'),
          value: 'contract_label',
          sortable: false,
          align: 'left'
        },
        {
          text: this.translate('start_date'),
          value: 'start_date_label',
          align: 'center',
          sortable: false
        },
        {
          text: this.translate('end_date'),
          value: 'end_date_label',
          align: 'center',
          sortable: false
        },
        {
          text: this.translate('age'),
          value: 'age_label',
          align: 'left',
          sortable: false
        },
        {
          text: this.translate('active'),
          value: 'status',
          align: 'center',
          sortable: false
        },
        // {
        //   text: '',
        //   value: 'action',
        //   align: 'center',
        //   sortable: false,
        //   width: 150
        // },
      ],
      selectedEmployeeContract: null,
      showForm: false,
      formDialog: false,
      timerId: null
    }
  },
  async mounted() {
    this.employeeContracts = await this.getEmployeeContracts();
  },
  computed: {
    loading() {
      return this.$store.state.progressBarLoading;
    },
    formattedEmployeeContracts() {
      return this.employeeContracts.map((employeeContract, index) => {
        return {
          serial: index + 1,
          employee_label: employeeContract.employee.name,
          contract_label: employeeContract.contract.name,
          start_date_label: this.formatDateWithLocale(employeeContract.start_date, true),
          end_date_label: employeeContract.end_date ? this.formatDateWithLocale(employeeContract.end_date, true) : '',
          age_label: employeeContract.age ? (employeeContract.age + ' ' + this.translate('months')) : '',
          status: employeeContract.is_active,
          ...employeeContract
        }
      });
    }

  },
  watch: {
//
  },
  methods: {
    openDetails(employeeContract) {
      this.selectedEmployeeContract = employeeContract;
      this.showForm = false;
      setTimeout(() => {
        this.showForm = true;
        clearTimeout(this.timerId)
      }, 5);
    },
    editItem(employeeContract) {
      this.selectedEmployeeContract = employeeContract;
      this.formDialog = false;
      this.timerId = setTimeout(() => {
        this.formDialog = true;
      }, 10);
    },
    showFormDialog() {
      this.formDialog = false;
      this.selectedEmployeeContract = null;
    this.timerId = setTimeout(() => this.formDialog = true, 10);
    },
   async createdHandler(newEmployeeContract) {
      if(this.employeeContracts.length === 0){
        this.employeeContracts = await this.getEmployeeContracts();
        return;
      }

      this.employeeContracts.unshift(newEmployeeContract);
    },
    updatedHandler(employeeContract) {
      const index = this.employeeContracts.findIndex(item => item.id === employeeContract.id);
      this.employeeContracts.splice(index, 1, employeeContract);
      this.selectedEmployeeContract = employeeContract
    },
    deletedHandler(employeeContract) {
      const index = this.employeeContracts.findIndex(item => item.id === employeeContract.id);
      this.employeeContracts.splice(index, 1);
      this.selectedEmployeeContract = null;
      this.showForm = false;
    },
   async updatePagination(page) {
      this.page = page;
      this.employeeContracts = await this.getEmployeeContracts();
    },
    async updateItemsPerPage(itemsPerPage) {
      this.perPage = itemsPerPage
      this.employeeContracts = await this.getEmployeeContracts();
    },
  }
}
</script>