<template>
  <v-card flat v-if="remount">

    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-form ref="form" class="main_form" @submit.prevent="submit">
      <v-btn
          @click="showAttachmentDialog()"
          class="d-none"
          id="add-attachment"
      ></v-btn>
      <attachment-dialog
          :showDialog.sync="attachmentDialog"
          :data="selectedBill && selectedBill.id"
          :countKey="'billing'"
          :resource="'billing'"
      ></attachment-dialog>
      <v-container fluid class="mt-n4">
        <v-overlay :value="!isPageLoaded">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row class="ma-0 pa-0 mt-4">
          <v-col
              class="d-flex pb-0 pa-0 ma-0"
              cols="12"
              :sm="parentClientIsBlocked ? 4 : 5"
          >
            <v-row class="pa-0 ma-0">
              <v-col class="d-flex pa-0 ma-0">
                <v-autocomplete
                    v-if="checkTag('billing') && ! changeSelectedBillIdentifierForTemp"
                    :autocomplete="autocompleteValueLocal"
                    v-model="selectedBill"
                    class="mr-2 mt-2 to-upper"
                    :items="bills"
                    :search-input.sync="search_bill"
                    item-text="bill_number"
                    item-value="id"
                    :loading="isLoadingSearchRegiClient"
                    :label="$t('scheduler.search_bill')"
                    :placeholder="$t('scheduler.start_typing')"
                    return-object
                    :append-icon="isEditingAllowedOfIdentifier && selectedBill && billType && billType.key == 'bill' ? 'mdi-pencil' : ''"
                    :clearable="selectedBill ? true : false"
                    clear-icon="mdi-close"
                    v-on:keydown="handleInput($event, 'alphanumeric', 20)"
                    v-on:keydown.enter.prevent=""
                    ref="billDropdown"
                    @click:clear="
                    onResetPageData();
                    search_bill = null;
                  "
                    @click:append="changeSelectedBillIdentifier()"
                    :readonly="selectedBill !==null && selectedBill!== undefined"
                    :outlined="is_boxes"
                    :hide-no-data="true"
                    dense
                    hide-details
                    :style="{maxWidth: '250px', minWidth: '250px'}"
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item-content>
                      <span
                          class="pl-7 bill_type"
                          v-if="item.type.key == 'bill'"
                      >
                        Bill
                      </span>
                      <span
                          class="pl-7 bill_type"
                          v-if="item.type.key == 'purchase_order'"
                      >
                        Purchase Order
                      </span>
                      <span
                          class="pl-7 bill_type"
                          v-if="item.type.key == 'estimation'"
                      >
                        Estimation
                      </span>
                      <span
                          class="pl-7 bill_type"
                          v-if="item.type.key == 'credit_note'"
                      >
                        Credit Note
                      </span>
                      <span>
                        <v-icon
                            class="ma-0"
                            v-if="item.type.key == 'bill'"
                            color="red"
                            right
                        >
                          mdi-script-text
                        </v-icon>
                        <v-icon
                            class="ma-0"
                            v-if="item.type.key == 'purchase_order'"
                            color="green"
                            right
                        >
                          mdi-tag-text
                        </v-icon>
                        <v-icon
                            class="ma-0"
                            v-if="item.type.key == 'estimation'"
                            color="black"
                            right
                        >
                          mdi-book-open
                        </v-icon>
                        <v-icon
                            class="ma-0"
                            v-if="item.type.key == 'credit_note'"
                            color="black"
                            right
                        >
                          mdi-note-multiple
                        </v-icon>
                        {{ item.bill_number }}
                      </span>
                      <br/>
                      <span class="pl-7 client_name" v-if="item.client">
                        <template v-if="item.client.company">
                          Company -
                        </template>
                        <template v-else> Private - </template>
                        {{ item.client.name }}
                        {{
                          item.client.first_name ? item.client.first_name : ""
                        }}
                      </span>
                    </v-list-item-content>
                  </template>
                  <template #prepend-inner >
                    <v-icon  v-if="selectedBill
                    && (isset(selectedBill.child_order_id) || isset(selectedBill.parent_order_id))" @click="openOrderHistoryModal">mdi-history</v-icon>
                  </template>
                </v-autocomplete>
                <v-text-field
                    class="mr-2 mt-2 to-upper"
                    v-if="changeSelectedBillIdentifierForTemp"
                    :label="$t('billings.search_bill')"
                    :placeholder="$t('billings.start_typing')"
                    v-model="editIdentifier"
                    :hide-no-data="true"
                    append-icon="mdi-check"
                    @click:append="
                    saveBillIdentifier()
                  "
                    @click:clear="
                    disallowEditIdentifier()
                  "
                    clearable
                    hide-details
                    dense
                    :style="{maxWidth: '250px', minWidth: '250px'}"
                ></v-text-field>
                <!-- <v-text-field
                    v-if="appointmentdata && appointmentdata.interventions"
                    readonly
                    disabled
                    :label="$t('scheduler.collaborators')"
                    :value="getInterventions(appointmentdata.interventions)"
                    class="ml-2 mt-2"
                    :style="{maxWidth: '170px'}"
                    hide-details
                    dense
                ></v-text-field> -->

                   <span v-if="selectedBill"><v-btn @click="openHistoryTableDialog" icon class="mt-1">
                     <v-icon style="color:royalblue">mdi-clipboard-text-clock</v-icon>
                   </v-btn></span>
              </v-col>

              <v-col class="d-flex pb-0 mt-4 pa-0 ma-0" cols="12" sm="3"
                      v-if="checkIfSelectedBillHasArticleNotDeliveredYet()">
                <span
                    style="color:red"
                >{{
                    $t(
                        "billings.text_messages.supplier_stock_not_delivered_yet"
                    )
                  }}</span
                >
              </v-col>

            </v-row>
          </v-col>

          <v-col
              class="d-flex pb-0 pa-0 ma-0 justify-end"
              cols="12"
              :sm="parentClientIsBlocked ? 8 : 7"
          >
            <NoteEditorModal
                :dialog.sync="showBillNote"
                v-if="showBillNote"
                v-model="bill_note_temp"
                @close="closeAddNoteModel()"
                @save="saveAddNoteModel()"
            />
            <v-checkbox
                class="mt-5 mr-3"
                v-model="appointment_is_executed"
                :disabled="checkIfPastDateAppointment()"
                :label="$t('scheduler.is_work')"
                hide-details
            ></v-checkbox>
            <v-text-field
                v-if="parent_company_client_name && parent_company_client_name && parent_company_client_name.credit_limit >= 0"
                v-model="customer_balance"
                :label="$t('billings.customer_balance')"
                readonly
                class="mr-2 to-upper"
                :class="customer_balance > 0 ? 'customerbalance' : ''"
                v-on:keydown.enter.prevent=""
                append-icon="mdi-information"
                disabled
                :outlined="is_boxes"
                :dense="is_boxes"
                :style="{maxWidth: '120px'}"
                hide-details
            >
              <template #append>
                <v-icon v-if="customer_balance > 0" color="red"
                >mdi-information
                </v-icon
                >

                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        v-if="parentClientIsBlocked"
                        class="ml-1"
                        color="red"
                    >mdi-account-alert
                    </v-icon
                    >
                  </template>
                  {{ $t("clients.client_blocked_full") }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-text-field
                v-if="ispayment_method"
                :disabled="isRecalculatingTableData"
                class="pr-2"
                :label="$t('billings.balance') + ': ' + paymentBalance"
                return-object
                v-on:keydown.enter.prevent=""
                :outlined="is_boxes"
                :dense="is_boxes"
                :style="{maxWidth: '200px'}"
                hide-details
                @focus="showPaymentModel = true"
                :append-icon="'mdi-open-in-new'"
            >
            </v-text-field>
            <PaymentMethod v-if="showPaymentModel" :articleTotal.sync="articleTotal"
                            :articlePayments.sync="articlePayments" :articlePaymentsTime.sync="articlePaymentsTime"
                            :showDialog.sync="showPaymentModel" :payment_methods="payment_methods"
                            :currencySymbol="currencySymbol"></PaymentMethod>
            <v-select
                @click="filterOutStatuses"
                v-if="isVisibleOrderStatus"
                :disabled="disableOrderOrExpenseStatus(selectedBill)"
                v-model="order_status"
                :items="billstatuses"
                :label="$t('billings.status')"
                item-text="text"
                item-value="id"
                return-object
                v-on:keydown.enter.prevent=""
                style="max-width: 130px"
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-details
            ></v-select>

            <PeppolStatusField v-if="$route.path.includes('edit')"/>

            <v-col class="d-flex pa-0 ma-0" cols="12" :sm="6">
                <v-menu
                    ref="menu"
                    v-model="menu_created_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details
                    v-on:keydown.enter.prevent=""
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="created_date"
                        :label="$t('billings.created_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        v-on:keydown.enter.prevent=""
                        @focus="created_date = null"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        class="to-upper mr-1"
                        hide-details
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        :first-day-of-week="1"
                        :weekday-format="getDay"
                        v-model="created_date"
                        no-title
                        scrollable
                        :locale="locale"
                        hide-details
                    >
                    <v-spacer></v-spacer>
                    </v-date-picker>
              </v-menu>
              <v-menu
                  ref="menu"
                  v-model="menu_due_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  hide-details
                  v-on:keydown.enter.prevent=""
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="due_date"
                      :label="$t('billings.due_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-on:keydown.enter.prevent=""
                      @focus="due_date = null"
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      class="to-upper mr-1"
                      hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    v-model="due_date"
                    no-title
                    scrollable
                    :locale="locale"
                    hide-details
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            <OrderReminders
                v-if="showPaymentReminder" 
                :order_reminders="order_reminders"
                :showDialog.sync="showPaymentReminder"
                :order="selectedBill"
                :parent_email="parent_company_client_email"
            ></OrderReminders>
              <v-select
                  v-if="billType && (billType.key == 'credit_note' || billType.key == 'bill')"
                  :disabled="selectedBill && selectedBill.status_id == 10251003"
                  v-model="order_reminder_type"
                  :items="orderReminderTypes"
                  :label="$t('billings.order_reminder_type')"
                  item-text="text"
                  item-value="id"
                  return-object
                  v-on:keydown.enter.prevent=""
                  style="max-width: 130px"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  hide-details
                  @click:append-outer="showPaymentReminder = true"
              >
                <template v-slot:append-outer>
                  <div @click="showPaymentReminder = true">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-1"
                            fab
                            dark
                            x-small
                            color="error"
                            @click="showPaymentReminder = true"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <span style="font-size: 16px">
                            {{ order_reminder_type_counts }}
                          </span>
                        </v-btn>
                      </template>
                      <span>{{ $t('billings.order_reminders') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="group_label mt-n5" v-if="checkTag('clients')">
          <v-col class=" pb-0">
            <span style="color: #2196F3"> {{ $t('billings.client') }}
                <ClientReclamation v-if="checkIfReclamationNeedToShow(parent_company_client_name) && checkIfReclamationNeedToShowForMainClient(parent_company_client_name)" :note.sync="parent_company_client_name.note"></ClientReclamation>
            </span>
            <create-edit-client
                v-if="checkTag('clients')"
                ref="clientComp"
                :comp_entityType.sync="parent_entityType"
                :comp_client_id.sync="parent_client_id"
                :comp_company.sync="parent_company"
                :comp_company_client_name.sync="parent_company_client_name"
                :comp_company_client_first_name.sync="
              parent_company_client_first_name
            "
                :comp_company_client_names.sync="parent_company_client_names"
                :comp_search_client.sync="parent_search_client"
                :comp_company_client_phone.sync="parent_company_client_phone"
                :comp_company_client_gsm.sync="parent_company_client_gsm"
                :comp_company_client_fax.sync="parent_company_client_fax"
                :comp_company_client_email.sync="parent_company_client_email"
                :comp_company_client_language.sync="parent_company_client_language"
                :comp_web.sync="parent_web"
                :comp_currency.sync="parent_currency"
                :comp_representative_company_id.sync="
              parent_representative_company_id
            "
                :comp_user.sync="parent_user"
                :comp_note.sync="parent_note"
                :comp_familyClient.sync="parent_familyClient"
                :comp_denomination.sync="parent_denomination"
                :comp_tva.sync="parent_tva"
                :comp_address.sync="parent_address"
                :comp_shipping_address.sync="parent_shipping_address"
                :comp_same_as.sync="parent_address_same_as"
                :comp_is_shipping_visible="true"
                :comp_is_billing_visible="true"
                :comp_order_counts="selectedBill ? selectedBill.orders_count : null"
                :comp_taxation_reason_id.sync="parent_taxation_reason_id"
                :hideDenomination="true"
                @entitytypeChangedToCompany="entitytypeChangedToCompany"
            />
          </v-col>
        </v-row>

        <v-row class="group_label pt-4" v-if="checkTag('projects')"> 
            <v-col cols="12" sm="12" class="mt-0 pt-0">
                <create-edit-project
                    v-if="checkTag('projects')"
                    ref="clientProjectComp"
                    :project_client="parent_company_client_name"
                    :project.sync="parent_project"
                    :project_name.sync="parent_project_name"
                    :project_note.sync="parent_project_note"
                    :project_status_id.sync="parent_project_status"
                />
            </v-col>
        </v-row>

        <v-row class="group_label pt-4" v-if="checkTag('vehicles') &&  isVehicleModuleEnabled">
          <v-col cols="12" sm="12" class="mt-0 pt-0">
            <create-edit-vehicle
                v-if="checkTag('vehicles')"
                ref="vehicleComp"
                :comp_selected_bill.sync="selectedBill"
                :comp_selected_appointment.sync="appointmentdata"
                :comp_vehicle_type.sync="parent_vehicle_type"
                :comp_registration_no.sync="parent_registration_no"
                :comp_brand.sync="parent_brand"
                :comp_model.sync="parent_model"
                :comp_km.sync="parent_km"
                :comp_reg_date.sync="parent_reg_date"
                :comp_creditor.sync="parent_creditor"
                :comp_creditors.sync="creditors"
                :comp_vin.sync="parent_vin"
                :comp_is_leasing.sync="parent_is_leasing"
                :comp_client_detail.sync="parent_company_client_name"
                :comp_driver.sync="parent_driver"
                :comp_second_driver.sync="parent_second_driver"
                :comp_addedRegistrationNumber.sync="parent_addedRegistrationNumber"
                :comp_available_depots="availableDepots"
            />
          </v-col>
        </v-row>

        <v-row class="group_label mb-0 pa-0 pt-n2" :class="checkTag('vehicles') &&  isVehicleModuleEnabled ? 'mt-n2' : 'mt-7'" v-if="checkTag('appointments')">
          <v-col cols="12" sm="12" class="mt-2">
            <v-row>
                  <span style="color: #2196F3" class="pl-2">
                    {{ $t("scheduler.appointment_details") }}
                  </span>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" class="mt-0 pt-0">
            <v-row>
              <v-col cols="12" sm="12" md="1" v-if="showIsFullDayCheckbox">
                <v-checkbox v-model="is_full_day" :label="$t('full_day')" style="margin-right: 5px"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="1" class="pl-0" v-if="is_full_day">
                <v-dialog
                    ref="fromDateDialog"
                    v-model="fromDateDialog"
                    :return-value.sync="fromDate"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fromDate"
                        :label="$t('scheduler.from_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="fromDate"
                      :min="minDate"
                      :locale="locale"
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="fromDateDialog = false">
                      {{ $t("cancel") }}
                      <v-icon dark right> mdi-keyboard-return</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="$refs.fromDateDialog.save(fromDate)">
                      {{ $t("ok") }}
                      <v-icon dark right> mdi-check</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="12" md="1" class="pl-0" v-if="is_full_day">
                <v-dialog
                    ref="toDateDialog"
                    v-model="toDateDialog"
                    :return-value.sync="toDate"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="toDate"
                        :label="$t('scheduler.to_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!fromDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="toDate"
                      :min="fromDate"
                      :locale="locale"
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="toDateDialog = false">
                      {{ $t("cancel") }}
                      <v-icon dark right> mdi-keyboard-return</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="$refs.toDateDialog.save(toDate)">
                      {{ $t("ok") }}
                      <v-icon dark right> mdi-check</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  :md="showIsFullDayCheckbox ? 1 : 2"
                  v-if="!is_full_day"
              >
                <v-dialog
                    ref="dateDialog"
                    v-model="dateDialog"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        :label="$t('scheduler.select_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      :min="minDate"
                      :locale="locale"
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="dateDialog = false">
                      {{ $t("cancel") }}
                      <v-icon dark right> mdi-keyboard-return</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="$refs.dateDialog.save(date)">
                      {{ $t("ok") }}
                      <v-icon dark right> mdi-check</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" md="1" v-if="!is_full_day">
                <v-dialog
                    ref="fromTimeDialog"
                    v-model="fromTimeDialog"
                    :return-value.sync="fromTime"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="fromTime"
                        :label="$t('scheduler.time_from')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="to-upper"
                        hide-details
                        :disabled="!date"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      scrollable
                      v-if="fromTimeDialog"
                      v-model="fromTime"
                      full-width
                      format="24hr"
                      hide-details
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="fromTimeDialog = false">
                      {{ $t("cancel") }}
                      <v-icon dark right> mdi-keyboard-return</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="$refs.fromTimeDialog.save(fromTime)">
                      {{ $t("ok") }}
                      <v-icon dark right> mdi-check</v-icon>
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12" md="1" v-if="!is_full_day">
                <v-dialog
                    ref="toTimeDialog"
                    v-model="toTimeDialog"
                    :return-value.sync="toTime"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="toTime"
                        :label="$t('scheduler.time_to')"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="to-upper"
                        hide-details
                        :disabled="!date || !fromTime"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      scrollable
                      v-if="toTimeDialog"
                      v-model="toTime"
                      full-width
                      format="24hr"
                      :min="fromTime"
                      hide-details
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-2" @click="toTimeDialog = false">
                      {{ $t("cancel") }}
                      <v-icon dark right> mdi-keyboard-return</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="$refs.toTimeDialog.save(toTime)">
                      {{ $t("ok") }}
                      <v-icon dark right> mdi-check</v-icon>
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col
                  cols="12"
                  sm="11"
                  :md="2"
                  style="display: flex; justify-content: space-between"
              >
                <v-menu
                    v-model="menu"
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                    hide-details
                >
                  <template v-slot:activator="{ on }">
                    <div :style="swatchStyle" v-on="on"/>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="color"
                          mode="hexa"
                          class=" mt-10"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-combobox
                    :autocomplete="autocompleteValueLocal"
                    v-model="work_type"
                    :items="work_types"
                    item-value="id"
                    item-text="name"
                    class="to-upper"
                    :label="$t('scheduler.work_type')"
                    :error-messages="
                    $store.state.validation && !$v.work_type.required
                      ? [this.$t('scheduler.text_messages.work_type_required')]
                      : []
                  "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    :style="{ 'max-width': '80%' }"
                    hide-details
                ></v-combobox>
              </v-col>
              <v-col cols="12" sm="12" md="1">
                <v-combobox
                    :autocomplete="autocompleteValueLocal"
                    v-model="work_resource"
                    @change="setWorkType"
                    :items="workresources"
                    item-value="id"
                    item-text="name"
                    class="to-upper"
                    :label="$t('scheduler.work_resource')"
                    :error-messages="
                    $store.state.validation && !$v.work_resource.required
                      ? [this.$t('scheduler.text_messages.work_resource_required')]
                      : []
                  "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-details
                ></v-combobox>
              </v-col>

              <v-col cols="auto" sm="12" md="1">
                <v-combobox
                    :autocomplete="autocompleteValueLocal"
                    v-model="notification_type"
                    :items="notification_types"
                    item-value="id"
                    class="to-upper"
                    item-text="text"
                    :label="$t('scheduler.reminder')"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-details
                ></v-combobox>
              </v-col>

              <v-col
                  cols="auto"
                  sm="12"
                  :md="this.is_full_day ? 2 : 1"
                  v-if="$store.state.inSchedulerEditMode"
              >
                <v-select
                    id="#appointmentStatus"
                    :items="appointmentStatusesItem"
                    item-text="label"
                    item-value="value"
                    v-model="appointmentStatus"
                    :label="$t('scheduler.status')"
                    :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                    hide-details
                >
                  <!-- <template v-slot:selection="{ item }">
                    <div
                      :style="{ backgroundColor: item.color, width: '100%' }"
                      class="px-1"
                    >
                      {{ item.label }}
                    </div>
                  </template> -->
                  <template #prepend-inner>
                    <v-icon v-if="selectedAppointmentStatusIs('appointment_active')"
                    >mdi-check
                    </v-icon
                    >
                    <v-icon
                        color="red"
                        v-if="selectedAppointmentStatusIs('appointment_canceled')"
                    >mdi-bell-cancel
                    </v-icon
                    >

                    <v-icon
                        v-if="selectedAppointmentStatusIs('appointment_is_executed')"
                        color="green"
                    >mdi-flag-checkered
                    </v-icon
                    >

                    <v-icon
                        v-if="selectedAppointmentStatusIs('appointment_not_executed')"
                    >mdi-bell-check-outline
                    </v-icon
                    >
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-list-item
                        class="pa-2"
                        :style="{ backgroundColor: item.color, color: '#000000' }"
                        @click="
                        () => {
                          if (appointmentStatus == item.value) {
                            return;
                          }
                          oldAppointmentStatus = appointmentStatus;
                          appointmentStatus = item.value;
                          appointmentStatusDialog = true;
                        }
                      "
                    >
                      <v-list-item-content>
                        <span>
                          <v-icon
                              v-if="
                              selectedAppointmentStatusIs(
                                'appointment_active',
                                item.value
                              )
                            "
                          >mdi-check</v-icon
                          >
                          <v-icon
                              color="red"
                              v-if="
                              selectedAppointmentStatusIs(
                                'appointment_canceled',
                                item.value
                              )
                            "
                          >mdi-bell-cancel</v-icon
                          >

                          <v-icon
                              v-if="
                              selectedAppointmentStatusIs(
                                'appointment_is_executed',
                                item.value
                              )
                            "
                              color="green"
                          >mdi-flag-checkered</v-icon
                          >

                          <v-icon
                              v-if="
                              selectedAppointmentStatusIs(
                                'appointment_not_executed',
                                item.value
                              )
                            "
                          >mdi-bell-check-outline</v-icon
                          >

                          {{ item.label }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <div class="d-flex nowrap">
                <v-text-field
                    class="to-upper"
                    :value="formattedFreeText"
                    :label="
                    warning_note
                      ? $t('scheduler.warning_note')
                      : $t('scheduler.free_text')
                  "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    disabled
                    style="width:90%"
                >
                </v-text-field>
                <v-btn icon>
                  <v-icon class="mt-6 ml-2" @click="showNoteEditor()">mdi-open-in-new</v-icon>
                </v-btn>
                </div>
                <NoteEditorModal
                  :dialog="noteDialog"
                  :value="free_text"
                  @close="noteDialog=false"
                  @save="noteDialog=false"
                  @input="(val) => { this.free_text = val }"
                  ></NoteEditorModal>
              </v-col>
              <v-col class="ma-0 pa-0 mt-3">
                <v-checkbox
                    :label="$t('scheduler.is_pickup_appointment')"
                    v-model="is_pickup_appointment"
                    @click="is_pickup_appointment = ! is_pickup_appointment; pickup_address = null"
                    color="green"
                    hide-details
                    readonly
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="group_label ma-0 pa-0 mt-n6" v-if="checkTag('appointments') && is_pickup_appointment">
          <v-col cols="12" sm="12" class="mt-1">
            <v-row>
                  <span style="color: #2196F3">
                    {{ $t("scheduler.pickup_appointment_address") }}
                  </span>
            </v-row>
          </v-col>
          <v-col class="ma-0 pa-0 mt-n2" cols="12" sm="6">
            <AddressInput
                :external-predictions="driverAddresses"
                v-model="pickup_address"
                :showLocationName="true"
                :label="$t('billings.address')"
            />
          </v-col>
          <v-col class="ma-0 pa-0 pt-1">
                <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    class="to-upper right-aligned-input shrink"
                    :label="$t('scheduler.pickup_start_datetime')"
                    v-model="pickup_start_datetime"
                    type="time"
                ></v-text-field>
          </v-col>
          <v-col class="ma-0 pa-0 pt-1">
                <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    class="to-upper right-aligned-input shrink"
                    :label="$t('scheduler.pickup_end_datetime')"
                    v-model="pickup_end_datetime"
                    type="time"
                ></v-text-field>
          </v-col>
          <v-col  cols="12" sm="4">
            <v-textarea
                v-model="pickup_note"
                :label="$t('expeditions.note')"
                rows="1"
                class="to-upper mt-n1"
                hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- <v-row class="mb-2">
          <v-col sm="11" md="10" class="mt-6">
            <template v-if="selectedBill">

              <span class="pl-4" v-if="parentOrder">
                {{ $t("parent") }}:
                <a @click="editBill(parentOrder)">{{
                  parentOrder.bill_number
                }}</a>
              </span>

              <span class="pl-4" v-if="childOrder">
                {{ $t("child") }}:
                <a @click="editBill(childOrder)">{{
                  childOrder.bill_number
                }}</a>
              </span>
            </template>
          </v-col>
          <v-col
            sm="12"
            md="2"
            v-if="(selectedArticles && selectedArticles.length > 0) || true"
            align="right"
          >
            <NoteEditorModal
              v-model="bill_note_temp"
              @close="closeAddNoteModel()"
              @save="saveAddNoteModel()"
            />
          </v-col>
        </v-row> -->
        <v-row class="mt-n2" v-if="checkTag('billing')">
          <v-data-table
              v-if="resetted_data_table"
              :footer-props="{
              'items-per-page-options': dataTableOptions,
            }"
              :headers="dataHeader"
              :hide-default-footer="true"
              :hide-default-header="true"
              :items="selectedArticles"
              v-sortable-data-table
              :item-class="row_classes"
              :items-per-page="10000"
              class="elevation-0 mb-10"
              group-by="group"
              show-group-by
              style="width: 100%"
              disable
          >
            <template v-slot:group.header="{ group }">
                <template v-if="group && group.trim() !== ''">
                    <td :colspan="dataHeader.length" class="group-header" @click="toggleGroup(group)">
                        <v-icon>{{ isGroupExpanded(group) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                        <strong>{{ group }}</strong>
                    </td>
                </template>
            </template>
            <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
              <tr>
                <th
                    v-for="header in headers"
                    :key="header.value"
                    scope="col"
                    class="text-right"
                    :style="getHeaderWidth(header, header.width)"
                >
                  <template v-if="header.value == 'article_original_name'">
                    <v-row justify="space-between">
                      <v-col class="d-flex" cols="12" sm="2">
                        {{ header.text }}
                      </v-col>
                      <v-col
                          v-if="
                            selectedFamilyArticle &&
                              selectedFamilyArticle.is_stock_managed == 1
                          "
                          cols="12"
                          sm="3"
                          class="ma-1 pa-0"
                          style="height:20px; display: flex; justify-content: flex-end"
                          v-on:keydown.enter.prevent="
                            parentInternalStockOnly = !parentInternalStockOnly
                          "
                      >
                        <v-checkbox
                            class="mt-1 ma-0 pa-0"
                            hide-details="auto"
                            v-model="parentInternalStockOnly"
                            :label="$t('scheduler.is_only_internal')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else-if="header.value == 'actions'">
                    <v-row justify="space-between">
                      <v-col class="d-flex dontbreakline" cols="12" sm="2">
                        {{ header.text }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    {{ header.text }}
                  </template>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:no-data>
              <span></span>
            </template>
            <template v-slot:body.prepend>
              <tr class="first_row">
                <td class="pl-4 ma-0 pa-0" colspan="2"  :class="['occupy_space']">
                  <v-row>
                    <!-- Search Article -->
                    <v-col class="ma-0 pa-0" cols="12" sm="0">
                      <search-article
                          :selectedFamilyClient="parent_familyClient"
                          :class="[article ? 'articleset' : 'articlenotset']"
                          @changeDialog="changeDialog()"
                          :compSelectedArticleId.sync="
                          parentSearchSelectedArticleId
                        "
                          :compSelectedArticleSupplierDetail.sync="
                          parentSearchSelectedArticleSupplierDetail
                        "
                          :internalStockOnly.sync="parentInternalStockOnly"
                          :selectedFamilyArticle.sync="selectedFamilyArticle"
                          :group-text.sync="parentGroupText"
                          :show-group-text="showGroupText"
                          :unit-price="unit_price"
                          @triggerAddArticleToDataTable="triggerAddArticleToDataTable()"
                          ref="searchArticleComp"
                      ></search-article>
                    </v-col>
                    <!-- Article Group -->
                    <v-col
                        v-if="showGroupText && article && typeof article == 'object'"
                        class="ma-0 pa-0 pr-1 pl-1 ml-0"
                        cols="12"
                        sm="2"
                        align="left"
                    >
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="ma-0 pa-0 selectedArticleTextBox to-upper"
                            :placeholder="$t('group')"
                            v-bind:clearable="true"
                            clear-icon="mdi-close"
                            v-model="parentGroupText"
                            v-on:keydown.enter.prevent="addArticleToDataTable()"
                            outlined
                            dense
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <!-- Article Description -->
                    <v-col
                        v-if="article && typeof article == 'object'"
                        class="ma-0 pa-0"
                        align="left"
                    >
                      <v-text-field
                          :autocomplete="autocompleteValueLocal"
                          class="ma-0 pa-0 selectedArticleTextBox to-upper"
                          append-icon="mdi-close"
                          @click:append="resetSelectedArticle()"
                          v-model="article.title_description[0].description"
                          hide-details="auto"
                          v-on:keydown.enter.prevent="addArticleToDataTable()"
                          v-on:keydown.prevent="
                          jumpToField($event, 'tableQuantity')
                        "
                          v-on:keydown.tab.prevent="
                          jumpToField($event, 'tableQuantity')
                        "
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    <!-- Article Supplier -->
                    <v-col
                        v-if="article && typeof article == 'object'"
                        sm="auto"
                        class="ma-0 pa-0 pl-0 pr-0 ml-1"
                        align="right"
                    >
                        <v-select
                            v-if=" article &&
                            typeof article == 'object' &&
                            article.supplier &&
                            article.supplier.length > 0 &&
                            article.supplier[0] &&
                            article.supplier[0].id >= 0 &&
                            article.family &&
                            article.family.is_stock_managed
                        "
                            :readonly="isEditModeForDataTableItem"
                            v-model="article.supplier[0]"
                            :items="article.supplier"
                            item-value="id"
                            item-text="identifier"
                            class="selectedSupplierTextBox"
                            style="max-width: 80px"
                            outlined
                            dense
                            return-object
                            hide-details="auto"
                        >
                        <template slot="item" slot-scope="{ item }">
                            <v-list-item-content @click="updatePricingBasedOnSupplier(item)">
                            {{ item.name }}
                            </v-list-item-content>
                        </template>
                        <v-icon slot="append" v-text="''"></v-icon>
                        </v-select>
                        <v-select
                            v-if="
                            !addArticlesAutomatically &&
                            article &&
                            article.supplier &&
                            article.supplier.length > 0 &&
                            article.supplier[0] == null &&
                            article.family &&
                            article.family.is_stock_managed
                        "
                            :value="internalSupplier"
                            :items="[internalSupplier]"
                            item-value="id"
                            item-text="identifier"
                            class="ma-0 mr-0"
                            :class="'articleInStock'"
                            style="max-width: 70px"
                            outlined
                            dense
                            return-object

                            hide-details="auto"
                        >
                        <v-icon slot="append" v-text="''"></v-icon>
                        </v-select>
                    </v-col>
                </v-row>
                </td>
                <td
                    style="min-width: 150px; max-width: 160px;"
                    align="right"
                    class="pl-2 pr-0"
                    v-on:keydown.tab.prevent="jumpToField('', 'tableUnitPrice')"
                    v-on:keydown.enter.prevent=""
                    v-on:keydown.enter="addArticleToDataTable()"
                    v-on:keydown="
                    handleInput(
                      $event,
                      selectedFamilyArticle
                        ? selectedFamilyArticle.quantity_decimals > 0
                          ? 'float'
                          : 'int'
                        : 'int',
                      10
                    )
                  "
                >
                  <v-row no-gutters align="center">
                    <v-col class="pa-0 ma-0 mr-n4" style="width:10px">
                      <v-combobox
                          style="width: 55px;background-color: gainsboro;z-index:4;"
                          append-icon=""
                          class="mt-0 shrink"
                          :autocomplete="autocompleteValueLocal"
                          v-model="currentMu"
                          :items="mus"
                          item-text="value"
                          v-on:keydown.enter.prevent=""
                          outlined
                          dense
                          hide-details
                      ></v-combobox>
                    </v-col>
                    <v-col class="pa-0 ma-0 mr-4">
                      <custom-money-input
                          :autocomplete="autocompleteValueLocal"
                          :disabled="isRecalculatingTableData"
                          class="pt-0 to-upper right-aligned-input shrink"
                          hide-details="auto"
                          outlined
                          dense
                          tabindex="2"
                          ref="tableQuantity"
                          v-model="quantity"
                          v-bind:properties="{
                          readonly: false,
                          disabled: false,
                          outlined: true,
                          placeholder: selectedFamilyArticle
                            ? selectedFamilyArticle.quantity_decimals > 0
                              ? makeZeros(selectedFamilyArticle.quantity_decimals)
                              : '0'
                            : '0',
                          dense: true,
                          hideDetails: 'auto',
                        }"
                          v-bind:options="{
                          locale: userLanguageId,
                          length: 12,
                          precision: selectedFamilyArticle
                            ? selectedFamilyArticle.quantity_decimals > 0
                              ? selectedFamilyArticle.quantity_decimals
                              : 0
                            : 0,
                          empty: selectedFamilyArticle
                            ? selectedFamilyArticle.quantity_decimals > 0
                              ? makeZeros(selectedFamilyArticle.quantity_decimals)
                              : '0'
                            : '0',
                        }"
                      />
                    </v-col>
                  </v-row>
                </td>
                <td
                    align="right"
                    class="pl-2 pr-0"
                    style="text-align:right; width:100px"
                    v-on:keydown.enter="addArticleToDataTable()"
                    v-on:keydown.exact="checkIfMinusPressed($event)"
                    v-on:keydown.tab.prevent="
                    jumpToField($event, 'tableRedPercentage')
                  "
                >
                  <div style="display: flex">
                    <v-tooltip color="error" top v-model="isInValidUnitPrice"
                                @click="unit_price = first_row_minimum_sales_price">
                      <template v-slot:activator="{on}">
                        <custom-money-input
                            v-on="on"
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            class="pt-0 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="3"
                            style="width:100px"
                            ref="tableUnitPrice"
                            v-model="unit_price"
                            :class="unit_price < first_row_minimum_sales_price ? 'red-input' : ''"
                            v-bind:properties="{
                            prefix: currencySymbol,
                            readonly: false,
                            disabled: false,
                            outlined: true,
                            placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                            dense: true,
                            hideDetails: 'auto',
                          }"
                            v-bind:options="{
                            locale: userLanguageId,
                            length: 12,
                            precision: getDecimalNumber(),
                            empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                          }"
                        />
                      </template>
                      <span>{{
                          $t('billings.text_messages.minimum_sales_price') + " : " + currencySymbol + (first_row_minimum_sales_price).toFixed(getDecimalNumber())
                        }}</span>
                    </v-tooltip>
                  </div>
                </td>
                <td
                    align="right"
                    class="pl-2 pr-0"
                    style="text-align:right; min-width: 100px; max-width: 100px;"
                    v-on:keydown.enter="addArticleToDataTable()"
                    v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                      :autocomplete="autocompleteValueLocal"
                      :disabled="isRecalculatingTableData"
                      v-model="red_percentage"
                      :background-color="
                      !$v.red_percentage.maxValue
                        ? '#ffc2b7'
                        : !$v.red_percentage.minValue
                        ? '#ffc2b7'
                        : 'white'
                    "
                      v-bind:properties="{
                      suffix: '%',
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      clearable: false,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                      v-bind:options="{
                      locale: userLanguageId,
                      length: 3,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                      class="pt-0 to-upper right-aligned-input shrink"
                      hide-details="auto"
                      outlined
                      dense
                      tabindex="4"
                      style="width:90px"
                      v-bind:focus="true"
                      ref="tableRedPercentage"
                  >
                  </custom-money-input>
                </td>
                <!-- <td style="text-align:right">{{ first_row_tariff }}</td> -->
                <!-- <td style="text-align:right">{{ first_row_tva }}</td> -->
                <td align="right" class="pl-2 pr-0" style="text-align:right; min-width: 100px; max-width: 100px;">
                  <v-text-field
                      :autocomplete="autocompleteValueLocal"
                      v-model="first_row_tva"
                      class="pt-0 to-upper right-aligned-input shrink"

                      hide-details="auto"
                      dense
                      outlined
                      required
                      v-on:keydown.enter.prevent=""
                      suffix="%"
                  ></v-text-field>
                </td>
                <td
                    align="right"
                    class="pl-2 pr-0"
                    style="text-align:right; min-width: 100px; max-width: 100px;"
                    v-on:keyup="reverseCalculate(null, first_row_thtva)"
                    v-on:keydown.enter="addArticleToDataTable()"
                    v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                      align="right"
                      :autocomplete="autocompleteValueLocal"
                      :disabled="isRecalculatingTableData"
                      class="pt-0 to-upper right-aligned-input shrink"
                      hide-details="auto"
                      outlined
                      dense
                      tabindex="3"
                      style="width:90px"
                      ref="tableFirstRowThtva"
                      v-model="first_row_thtva"
                      v-bind:properties="{
                      prefix: currencySymbol,
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                      v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                  />
                </td>
                <td
                    class="pl-2 pr-0"
                    style="text-align:right; min-width: 100px; max-width: 100px;"
                    align="right"
                    v-on:keyup="reverseCalculate(first_row_total, null)"
                    v-on:keydown.enter="addArticleToDataTable()"
                    v-on:keydown.tab.prevent="addArticleToDataTable()"
                >
                  <custom-money-input
                      :autocomplete="autocompleteValueLocal"
                      :disabled="isRecalculatingTableData"
                      class="pt-0 to-upper right-aligned-input shrink"
                      hide-details="auto"
                      outlined
                      dense
                      tabindex="3"
                      style="width:100px"
                      ref="tableFirstRowTotal"
                      v-model="first_row_total"
                      v-bind:properties="{
                      prefix: currencySymbol,
                      readonly: false,
                      disabled: false,
                      outlined: true,
                      placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                      dense: true,
                      hideDetails: 'auto',
                    }"
                      v-bind:options="{
                      locale: userLanguageId,
                      length: 12,
                      precision: getDecimalNumber(),
                      empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                    }"
                  />
                </td>
                <td>
                  <v-btn
                      color="primary"
                      dark
                      class="outlinedbuttonheight"
                      @click="addArticleToDataTable()"
                  >
                    <v-icon dark v-if="! isEditModeForDataTableItem">mdi-plus</v-icon>
                    <v-icon dark v-else>mdi-check</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:item.information="{ item }">
              <div class="d-flex flex-row information-class"
                    @mouseover="toggleElementById(item.srno, 'visible')"
                    @mouseleave="toggleElementById(item.srno, 'hidden')">
                <div>
                  <v-icon
                      :id="generateElementId(item.srno)"
                      class="mdi mdi-drag"
                      :style="{
                                visibility: 'hidden',
                                cursor: 'move',
                                marginLeft: 0,
                                paddingLeft: 0
                            }"
                  ></v-icon>
                </div>

                <div
                    v-if="
                  item.supplier &&
                    item.supplier.length > 0 &&
                    item.supplier[0] &&
                    item.supplier[0].id >= 0 &&
                    item.article_original_name && 
                    item.article_original_name.family && 
                    item.article_original_name.family.is_stock_managed
                "
                    :class="
                  typeof item.article != 'object'
                    ? item.article.internal_company_article &&
                      item.article.internal_company_article.length > 0 &&
                      item.article.internal_company_article[0].stock <
                        item.quantity
                      ? 'articleNotInStock'
                      : item.supplier &&
                        item.supplier.length > 0 &&
                        item.supplier[0].id != 0 &&
                        item.saved_supplier_order_detail &&
                        item.saved_supplier_order_detail.supplier_order &&
                        [10091000, 10091001, 10091004].includes(
                          item.saved_supplier_order_detail.supplier_order.status_id
                        )
                      ? (item.saved_supplier_order ? new Date(
                          item.saved_supplier_order.delivery_date
                        ) >=
                        new Date(formatDate(getCurrentDate(), 'yyyy-mm-dd')) : false)
                        ? 'articleWaitingForSupplierOrderOrange'
                        : 'articleWaitingForSupplierOrder'
                      : 'articleInStock'
                    : ''
                "
                    style="max-width: 100px; border:0px; width: 60px;"
                    dense
                    return-object
                    readonly
                    flat
                    solo
                    hide-details="auto"
                >
                  <v-icon
                      v-if="
                    item.supplier &&
                    item.supplier.length > 0 &&
                    item.supplier[0].id != 0 &&
                    item.saved_supplier_order_detail &&
                    item.saved_supplier_order_detail.supplier_order &&
                      [10091000, 10091001, 10091004].includes(
                        item.saved_supplier_order_detail.supplier_order.status_id
                      )
                  "
                      slot="prepend"
                      :color="
                    item.saved_supplier_order_detail &&
                    item.saved_supplier_order_detail.delivery_date &&
                    new Date(
                      item.saved_supplier_order_detail
                    ) >= new Date(formatDate(getCurrentDate(), 'yyyy-mm-dd'))
                      ? 'orange'
                      : 'red'
                  "
                  >
                    mdi-timer-sand
                  </v-icon>
                  <v-icon
                      v-if="
                    item.saved_supplier_order_detail &&
                    item.saved_supplier_order_detail.supplier_order &&
                      [10091005].includes(item.saved_supplier_order_detail.supplier_order.status_id)
                  "
                      slot="prepend"
                      :color="'green'"
                  >
                    mdi-check
                  </v-icon>
                  <template v-if="item.supplier.length > 0">
                    {{ item.supplier[0].identifier }}
                  </template>
                </div>
                <div style="max-width: 100px; border:0px; width: 60px; float:right"
                      v-else-if="item.article_original_name && item.article_original_name.family && item.article_original_name.family.is_stock_managed">
                  INT
                </div>
                <v-tooltip
                    bottom
                    z-index="9999"
                    v-if="
                  item.article.family &&
                    item.article.family.is_stock_managed == 1 &&
                    !(
                      item.supplier &&
                      item.supplier.length > 0 &&
                      item.supplier[0] &&
                      item.supplier[0].id
                    )
                "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-if="
                      item.article.internal_company_article &&
                        item.article.internal_company_article.length > 0 &&
                        item.article.internal_company_article[0].stock <
                          item.quantity
                    "
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{
                      $t("billings.text_messages.article_does_not_have_stock")
                    }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.article_original_name="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12" style="display:flex">
                  <div class="" style="width: 100%">
                    <template
                        v-if="
                        item.article_original_name &&
                          item.article_original_name.title_description.length >
                            0
                      "
                    >
                      <div class="cursor-pointer-trans" @click="dataTableItemClicked(item)">
                        <span>
                        {{
                            item.article_original_name.title_description[0]
                                .description
                          }}<br/>
                        <span class="article_identifier"> {{ item.article_original_name.identifier }} </span>
                        </span>
                      </div>
                      <v-icon
                          @click="addTextAndGroupToArticle(item)"
                          :id="'editArticle' + item.srno"
                          color="blue"
                          :class="
                          item.text
                            ? 'mdi mdi-pencil'
                            : 'mdi mdi-pencil-outline'
                        "
                          style="position:relative; float:right; top:-30%; visibility: hidden"
                      ></v-icon>
                    </template>
                    <template v-else>
                      <div class="article_identifier" v-html="item.text"></div>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.quantity="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <template v-if="item.quantity">
                    <!-- {{ item.article_original_name }} -->
                    <span>
                      {{
                        fixDecimal(
                            item.quantity,
                            item
                                ? item.article_original_name
                                    ? item.article_original_name.family
                                        .quantity_decimals
                                    : 0
                                : 0
                        )
                      }}
                      {{
                        item.selectedmu.value
                      }}
                      <!-- Here we have hardcoded if nothing is their then family mu -->
                    </span>
                    <!-- <span> {{ item.quantity }} </span> -->
                  </template>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.rounded_unit_price="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                    <!-- {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                    {{ currencySymbol }}
                    {{
                      parseFloat(item.rounded_unit_price).toFixed(
                          getDecimalNumber()
                      )
                    }}
                  </span>
                  <span v-else class="text-no-wrap">
                    {{ currencySymbol }}
                    {{
                      item.rounded_unit_price
                          ? parseFloat(item.rounded_unit_price).toFixed(
                              getDecimalNumber()
                          )
                          : (0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.red_percentage="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <span>
                    {{
                      (item.red_percentage ? item.red_percentage : 0).toFixed(
                          getDecimalNumber()
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.tva="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <span>
                    {{ (item.tva ? item.tva : 0).toFixed(getDecimalNumber()) }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.thtva="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <span v-if="billType && billType.key == 'credit_note'">
                    <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                    {{ item.thtva.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else>
                    {{
                      (item.thtva ? item.thtva : 0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.total="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="12" sm="12">
                  <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                    <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                    {{ currencySymbol }}
                    {{ item.total.toFixed(getDecimalNumber()) }}
                  </span>
                  <span v-else class="text-no-wrap">
                    {{ currencySymbol }}
                    {{
                      (item.total ? item.total : 0).toFixed(getDecimalNumber())
                    }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-row
                  @mouseover="toggleElementById(item.srno, 'visible')"
                  @mouseleave="toggleElementById(item.srno, 'hidden')"
              >
                <v-col cols="9" sm="9">
                  <!-- {{ selectedArticles[item.srno ? item.srno  : 0].dots }} -->
                  <template
                      v-if="
                      selectedArticles[item.srno ? item.srno : 0] &&
                        Array.isArray(
                          selectedArticles[item.srno ? item.srno : 0].dots
                        ) &&
                        selectedArticles[item.srno ? item.srno : 0].dots
                          .length >= 0
                    "
                  >
                    <v-autocomplete
                        v-if="
                        !selectedArticles[item.srno ? item.srno : 0]
                          .supplier_stock_detail &&
                          item.article_original_name &&
                          item.article_original_name.family &&
                          (item.article_original_name.family.id == 1 ||
                            item.article_original_name.family
                              .parent_family_id == 1) &&
                          selectedArticles[item.srno ? item.srno : 0]
                            .depot_out_id == null &&
                          selectedArticles[item.srno ? item.srno : 0]
                            .depot_in_id == null
                          && (! (selectedArticles[item.srno ? item.srno : 0]
                            .supplier && selectedArticles[item.srno ? item.srno : 0]
                            .supplier.length > 0 && selectedArticles[item.srno ? item.srno : 0]
                            .supplier[0] && selectedArticles[item.srno ? item.srno : 0]
                            .supplier[0].identifier != 'INT'))
                      "
                        v-model="
                        selectedArticles[item.srno ? item.srno : 0]
                          .stock_property
                      "
                        :items="
                        selectedArticles[item.srno ? item.srno : 0].dots
                          ? selectedArticles[item.srno ? item.srno : 0].dots
                          : []
                      "
                        item-text="property"
                        item-value="property"
                        hide-details="auto"
                        single-line
                        :placeholder="$t('billings.dot')"
                        class="pt-0 to-upper "
                        dense
                        tabindex="2"
                        style="width:90px"
                        @focus="$event.target.select()"
                        ref="dot"
                        append-icon=""
                        v-on:keydown.enter.prevent=""
                        v-on:keydown="
                        handleInput($event, 'int', 4);
                        fineDataForSending();
                      "
                        :outlined="is_boxes"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <template>
                          {{ item.property }} X {{ item.counter }}
                        </template>
                      </template>
                    </v-autocomplete>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-progress-circular
                            v-bind="attrs"
                            v-on="on"
                            indeterminate
                            color="primary"
                            :size="20"
                            :width="3"
                        ></v-progress-circular>
                      </template>
                      <span>
                        {{ $t("please_wait_while_we_are_loading_dots") }}
                      </span>
                    </v-tooltip>
                  </template>
                </v-col>
                <v-col
                    v-if="!(selectedBill && selectedBill.status_id == 10251003)"
                    cols="3"
                    sm="3"
                    class="pa-0 pt-4"
                >
                  <v-icon small color="red" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </v-col>
              </v-row>
            </template>

            <template v-slot:body.append>
                <!-- Total -->
                <tr
                    class="last_row"
                    v-if="selectedArticles.length > 0"
                    style="background-color:rgba(142, 142, 142, 0.07)"
                >
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right ma-0 pa-0 mt-1 mb-1">
                        <custom-money-input
                            :autocomplete="autocompleteValueLocal"
                            :disabled="isRecalculatingTableData"
                            @input="calculateTotals()"
                            class="pt-0 to-upper right-aligned-input shrink float-right pr-2"
                            hide-details="auto"
                            dense
                            tabindex="3"
                            style="width:80px"
                            ref="reduction_on_order"
                            v-model="reduction_on_order"
                            v-on:keydown="handleInput($event, 'float', 5);"
                            prefix="%"
                            v-bind:properties="{
                                prefix: '%',
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                placeholder: getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails: 'auto',
                            }"
                            v-bind:options="{
                                locale: 'en',
                                length: 12,
                                precision: getDecimalNumber(),
                                empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                            }"
                        />
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right">
                    <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                        {{ currencySymbol }}
                        {{ articleTHTVATotalBeforeReduction.toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else class="text-no-wrap">
                        {{ currencySymbol }}
                        {{
                        (articleTHTVATotalBeforeReduction ? articleTHTVATotalBeforeReduction : 0).toFixed(
                            getDecimalNumber()
                        )
                        }}
                    </span>
                    </th>
                    <th class="text-sm-right">
                    <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                        {{ currencySymbol }}
                        {{ articleTotalBeforeReduction.toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else class="text-no-wrap">
                        {{ currencySymbol }}
                        {{
                        (articleTotalBeforeReduction ? articleTotalBeforeReduction : 0).toFixed(
                            getDecimalNumber()
                        )
                        }}
                    </span>
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                </tr>
                <!-- Total After Reduction -->
                <tr
                    class="last_row"
                    v-if="selectedArticles.length > 0 && total_reduction > 0"
                    style="background-color:rgba(142, 142, 142, 0.07)"
                >
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right">
                        {{ currencySymbol }}
                        - {{ total_reduction }}
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right">
                    <!-- <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                        {{ currencySymbol }}
                        {{ articleTHTVATotal.toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else class="text-no-wrap">
                        {{ currencySymbol }}
                        {{
                        (articleTHTVATotal ? articleTHTVATotal : 0).toFixed(
                            getDecimalNumber()
                        )
                        }}
                    </span> -->
                    </th>
                    <th class="text-sm-right">
                    <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                        {{ currencySymbol }}
                        {{ articleTotal.toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else class="text-no-wrap">
                        {{ currencySymbol }}
                        {{
                        (articleTotal ? articleTotal : 0).toFixed(
                            getDecimalNumber()
                        )
                        }}
                    </span>
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                </tr>
                <!-- Total Paid -->
                <tr
                    class="last_row"
                    v-if="selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBillData &&
                                        selectedBillData.type &&
                                        ['bill', 'credit_note'].includes(selectedBillData.type.key)"
                    style="background-color:rgba(142, 142, 142, 0.07)"
                >
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <!-- <th class="text-sm-right" v-text="''" /> -->
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right">
                        <span class="mr-0">
                                {{ $t('billings.paid') }}
                        </span>
                    </th>
                    <th class="text-sm-right text-no-wrap" align="right">
                            <span>
                        {{ currencySymbol }}
                        ({{ totalPaid }})
                    </span>
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                </tr>
                <!-- Total Balance -->
                <tr
                    class="last_row"
                    v-if="selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBillData &&
                                        selectedBillData.type &&
                                        ['bill', 'credit_note'].includes(selectedBillData.type.key)"
                    style="background-color:rgba(142, 142, 142, 0.07)"
                >
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-left" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right" v-text="''"/>
                    <!-- <th class="text-sm-right" v-text="''" /> -->
                    <th class="text-sm-right" v-text="''"/>
                    <th class="text-sm-right">
                    {{ $t('billings.balance') }}
                    </th>
                    <th class="text-sm-right">
                            <span class="text-no-wrap">
                        {{ currencySymbol }}
                        {{ paymentBalance }}
                    </span>
                    </th>
                    <th class="text-sm-right" v-text="''"/>
                </tr>
            </template>
          </v-data-table>
        </v-row>
        <v-row v-if="ifIsExpeditionType && appointmentdata">
          <ExpeditionDetailInAppointment :expeditionId="appointmentdata.expedition_id"></ExpeditionDetailInAppointment>
        </v-row>
      </v-container>
    </v-form>

    <depot-add-modify-tyre
        v-if="depotInMod == true"
        :depotDetails="null"
        :vehicleDriverDetails.sync="vehicleDriverDetails"
        @modifyClose="modifyDepotInClose"
    ></depot-add-modify-tyre>
    <depot-out
        v-if="depotOutMod == true"
        :vehicleDriverDetails.sync="vehicleDriverDetails"
        :alreadySelectedDepot="selectedArticles"
        @modifyClose="modifyDepotOutClose"
    ></depot-out>
    <loading-model v-if="showLoadingModel && !forceHideLoadingModel" :showLoadingModel.sync="showLoadingModel"></loading-model>
    <loading-model v-if="showPendingArticleLoadingModel" :showLoadingModel.sync="showPendingArticleLoadingModel"></loading-model>

    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>

    <v-btn @click="submit()" class="d-none" id="submit-scheduler"></v-btn>
    <v-btn @click="submit(true)" class="d-none" id="submit-scheduler-without-close"></v-btn>
    <v-btn @click="onDelete()" class="d-none" id="delete-scheduler"></v-btn>
    <v-btn @click="depotIn()" class="d-none" id="depot-in"></v-btn>
    <v-btn @click="depotOut()" class="d-none" id="depot-out"></v-btn>
    <v-btn @click="printRequestedOut" class="d-none" id="print-depot-out"></v-btn>

    <v-btn @click="onResetPageData(); goBackToListing();" class="d-none" id="reset-page-data"></v-btn>
    <v-btn
        @click="confirmPurchaseOrderPopUp()"
        class="d-none"
        id="make-purchase-order"
    ></v-btn>
    <v-btn
        @click="confirmMakeBillPopUp()"
        class="d-none"
        id="make-bill"
    ></v-btn>
    <v-btn
        @click="confirmCreditNotePopUp()"
        class="d-none"
        id="make-credit-note"
    ></v-btn>
    <v-btn @click="printBill()" class="d-none" id="print-bill"></v-btn>
    <v-btn @click="emailBill()" class="d-none" id="email-bill"></v-btn>
    <v-btn @click="worksheetBill()" class="d-none" id="worksheet-bill"></v-btn>
    <v-btn @click="workReportBill()" class="d-none" id="workreport-bill"></v-btn>
    <v-btn @click="addBillNote()" class="d-none" id="add-bill-note"></v-btn>
    <v-btn @click="duplicateDocument()" class="d-none" id="duplicate-document"></v-btn>
    <v-btn
        @click="printReportFromTopButton()"
        class="d-none"
        id="print-report"
    ></v-btn>
    <template>
      <v-row justify="center">
        <v-dialog v-model="showTextDialog" scrollable max-width="650px">
          <v-card>
            <v-card-title>{{
                showGroupText
                    ? $t("edit_article_text_and_group")
                    : $t("edit_article_text")
              }}
            </v-card-title>
            <v-card-text style="height: 200px;">
              <v-text-field
                  v-if="showGroupText"
                  v-model="tempItemGroup"
                  :label="$t('group')"
                  v-on:keydown.enter.prevent=""
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  class="to-upper"
              ></v-text-field>
              <v-textarea
                  v-model="tempItemText"
                  :label="$t('text')"
                  :outlined="is_boxes"
                  :dense="is_boxes"
                  class="to-upper"
              ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="px-2">
              <v-spacer></v-spacer>
              <v-btn
                  color="grey lighten-2"
                  @click="cancelTextAndGroupToArticle()"
              >
                {{ $t("cancel") }}
                <v-icon dark right> mdi-keyboard-return</v-icon>
              </v-btn>
              <v-btn color="success" @click="saveTextAndGroupToArticle()">
                {{ $t("save") }}
                <v-icon dark right> mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-dialog
        persistent
        v-model="appointmentStatusDialog"
        min-width="400"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-title">
          {{ $t("confirmation") }}
        </v-card-title>

        <v-card-text class="dialog-text text-content" color="black">
          {{ $t("scheduler.appointment_status_update_warning_message") }} ?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pr-2">
          <v-spacer></v-spacer>
          <v-btn
              color="grey lighten-2"
              class="px-4"
              @click="
              () => {
                appointmentStatus = oldAppointmentStatus;
                appointmentStatusDialog = false;
              }
            "
              :disabled="updatingAppointmentStatus"
          >
            {{ $t("no") }}
            <v-icon dark right> mdi-keyboard-return</v-icon>
          </v-btn>
          <v-btn
              class="px-4"
              color="success"
              :loading="updatingAppointmentStatus"
              @click="
              () => {
                $store.state.isLoading = true;
                updatingAppointmentStatus = true;
                updateAppointmentStatus();
              }
            "
          >
            {{ $t("yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuppliersList
        :toggle="suppliersListDialog"
        @closeDialog="suppliersListDialog = false "
        @selectSupplier="(item) => {
                suppliersListDialog = false;
                onSupplierSelected(item);
              }"></SuppliersList>
    <send-pdf-via-email
        v-if="isset(selectedBill)"
        v-model="sendPdfDialog"
        target="billing"
        :model="selectedBill"
        :parent-model="appointmentdata"
        :depot="availableDepots"
        @emailSent="() => setPageData()"
    ></send-pdf-via-email>
    <order-history v-model="orderHistoryDialog" @updateBillData="(billId) => editOrder(billId)"  :order="selectedBill" />
    <history-table v-model="historyTableDialog" :model_id="historyTableModelId" :model="historyTableModel" />
  </v-card>
</template>
<style scoped>
.occupy_space {
  width: 100%;
}

.first_row:hover:not(.v-table__expanded__content) {
  background: white !important;
}

.supplier_name {
  font-size: 12px;
  margin: 0px;
}

.article_name {
  font-size: 14px;
  margin-bottom: 2px;
}

.bill_number {
  font-size: 14px;
  margin: 0px;
}

.identifier {
  font-size: 12px;
  margin-bottom: 2px;
}

.client_name {
  font-size: 11px;
  margin-bottom: 2px;
}

.bill_type {
  font-size: 11px;
  margin-bottom: 2px;
}
</style>
<script>
import axios from "@/plugins/axios";
import Sortable from "sortablejs";
import _ from "lodash";
import {API_BASE_URL} from "@/config";
import {DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import CreateEditClient from "@/components/Client/CreateEditClient";
import CreateEditProject from "@/components/Projects/CreateEditProject";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";
import DateTimePicker from "@/components/DateTimePicker";
import {SCHEDULER_MODE} from "@/config";
import {SCHEDULER_EDIT_MODE} from "@/config";
import {validationMixin} from "vuelidate";
import AddressInput from "@/components/AddressInput";

// import NoteEditor from "../../components/NoteEditor.vue";
import NoteEditorModal from "../../components/NoteEditorModal.vue";
import AttachmentDialog from "@/components/AttachmentDialog";

import ExpeditionDetailInAppointment from "@/components/Calendar/ExpeditionDetailInAppointment";

import OrderHistory from "@/components/Billing/OrderHistory.vue";

import {
  required,
  email,
  requiredIf,
  phoneNum,
  numeric,
  minLength,
  maxLength,
  alpha,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import SearchArticle from "@/components/Billing/SearchArticle";
import {DateTime} from "luxon";

import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
import DepotOut from "@/components/TyreHotel/DepotOut";
import LoadingModel from "@/components/LoadingModel";
import ConfirmationModel from "@/components/ConfirmationModel";
import {mapState} from "vuex";
import peppolMixin from "@/mixins/peppolMixin";
import PeppolStatusField from "@/components/Billing/PeppolStatusField.vue";
import billMixin from "@/mixins/billMixin";
import SuppliersList from "@/components/Billing/SuppliersList.vue";
import splitPaymentMixin from "@/mixins/splitPaymentMixin";
import OrderReminders from "../../components/Billing/OrderReminders.vue";
import PaymentMethod from "../../components/Billing/PaymentMethod.vue";
import moment from "moment";
import SendPdfViaEmail from "@/components/SendPdfViaEmail.vue";
import historyTableMixin from "@/mixins/historyTableMixin";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";
import ClientReclamation from "@/components/Reclamation.vue";

export default {
  mixins: [validationMixin, peppolMixin, billMixin, splitPaymentMixin, historyTableMixin],
  refs: ['dateDialog', 'fromTimeDialog', 'toTimeDialog', 'fromDateDialog', 'toDateDialog'],
  validations: {
    // Client Validation
    // Vehicle Validation
    parent_familyClient: {
      required,
    },
    red_percentage: {
      maxValue: maxValue(100),
      minValue: minValue(0),
    },
    // free_text: {
    //     required: requiredIf(function (model) {
    //         return this.selectedArticles.length <= 0;
    //     }),
    // },
    work_resource: {
      required: required,
    },
    work_type: {
      required: required,
    },
  },
  name: "Scheduler",
  props: {
    iCompany: Boolean,
  },
  components: {
    ClientReclamation,
    HistoryTable,
    SendPdfViaEmail,
    SuppliersList,
    PeppolStatusField,
    "create-edit-client": CreateEditClient,
    "create-edit-vehicle": CreateEditVehicle,
    "search-article": SearchArticle,
    // "t-date-time-field": DateTimePicker,
    "depot-add-modify-tyre": DepotAddModifyTyre,
    "depot-out": DepotOut,
    "loading-model": LoadingModel,
    "confirmation-model": ConfirmationModel,
    "custom-money-input": CutsomMoneyInput,
    "create-edit-project": CreateEditProject,
    "attachment-dialog": AttachmentDialog,
    // NoteEditor,
    NoteEditorModal,
    PaymentMethod,
    OrderReminders,
    ExpeditionDetailInAppointment,
    AddressInput,
    OrderHistory

  },
  data() {
    return {
      driverAddresses: [],
      forceHideLoadingModel: false,
      showPendingArticleLoadingModel: false,
      defaultQuantity: 4,
      selectQuantityFromPendingArticle: false,
      quantityFromPendingArticle: 0,
      readyToAcceptNewArticleFromStock: true,
      remount: true,
      isVehicleModuleEnabled: this.getCompanyPropertyFromLocalStorage('LINK_VEHICLE_TO_BILL') ? this.getCompanyPropertyFromLocalStorage('LINK_VEHICLE_TO_BILL', 1) : true,
      orderHistoryDialog: false,
      noteDialog: false,
      clientStatuses: [],
      sendPdfDialog: false,
      operationalTimes: null,
      is_full_day: false,
      modal: false,
      order_reminders: [],
      order_reminder_type: null,
      order_reminder_type_counts: 0,
      resetted_data_table: false,
      attachmentDialog: false,
      showBillNote: false,
      appointmentStatusDialog: false,
      appointmentStatus: null,
      oldAppointmentStatus: null,
      updatingAppointmentStatus: false,
      internalSupplier: {
        id: 9,
        name: "INTERNAL",
        contact: "",
        identifier: "INT",
        family_article_id: null,
        parent_supplier_id: null,
        parent_supplier: null,
        status_id: 10181000,
        type_id: 10191001,
        created_at: "2018-12-27",
        updated_at: "2019-06-14",
      },
      selectedItemForGroupAndText: false,
      tempItemText: null,
      tempItemGroup: null,
      showTextDialog: false,
      addArticlesAutomatically: this.getCompanyPropertyFromLocalStorage(
          "ADD_AUTOMATICALLY_ARTICLES"
      )
          ? this.convertToBoolean(
              this.getCompanyPropertyFromLocalStorage(
                  "ADD_AUTOMATICALLY_ARTICLES"
              ).value
          )
          : null,
      showGroupText: this.getCompanyPropertyFromLocalStorage(
          "group_articles",
          1
      )
          ? true
          : false,
      parentGroupText: null,
      selectedFamilyArticle: null,
      resourceType: null,
      bill_note_temp: null,
      bill_note: null,
      note_dialog: false,
      appointment_is_executed: false,
      billstatuses: [],
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      isEditingAllowedOfIdentifier: this.checkTag("allow_edit_bill_indentifier"),
      creditNoteMultiplicationValue: null,
      storedDepotIn: [],
      depotInMod: false,
      depotOutMod: false,
      parentSearchSelectedArticleId: null,
      parentSearchSelectedArticleSupplierDetail: null,
      parentInternalStockOnly: true,
      newValidation: false,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      page: 1,
      lastPage: null,
      totalItems: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      sortBy: "description",
      sortDesc: false,
      options: {},
      pagination: {},
      DeleteConfirm: false,
      denominations: [],
      allStatuses: [],
      order_status: "",
      isVisibleOrderStatus: false,
      isRecalculatingTableData: false,
      showPaymentReminder: false,
      showPaymentModel: false,
      payment_methods: [],
      payment_method: null,
      ispayment_method: false,
      operation: "edit",
      bill_amount: "",
      creditors: [],
      showLoadingModel: false,

      rdv_date: new Date().toISOString().substr(0, 10),
      due_date: null,
      created_date: null,
      menu_rdv_date: false,
      menu_due_date: false,
      menu_created_date: false,
      menu_pickup_start_datetime: false,
      menu_pickup_end_datetime: false,

      note: "",
      tvas: [],
      reference_client: "",
      isPageLoaded: false,
      unit_price: 0.0,
      isInValidUnitPrice: false,
      quantity: this.defaultQuantity,
      first_row_tariff: 0,
      first_row_tva: "",
      cached_first_row_tva: "",
      first_row_thtva: "",
      first_row_total: "",
      first_row_minimum_sales_price: 0,
      red_percentage: 0.0,
      next_event_codes: [],
      next_event_code: "",
      search: "",
      isLoadingArticle: false,
      isLoading: false,
      rowNumber: 0,
      articleTHTVATotal: 0.0,
      articleTHTVATotalBeforeReduction: 0.0,
      articleTotal: 0.0,
      articleTotalBeforeReduction: 0.0,
      total_reduction: 0.0,
      reduction_on_order: 0,
      selectedArticles: [],
      finedSelectedArticles: [],
      articles: [],
      article: "",
      search_article: "",
      search_bill: "",
      allowContinueSearch: true,
      bills: [],
      selectedBill: "",
      isLoadingSearchRegiClient: false,
      familyArticle: "",
      customer_balance: 0,

      // Vehicle Component Sync Values
      parent_vehicle_type: null,
      parent_registration_no: null,
      parent_brand: null,
      parent_model: null,
      parent_km: null,
      parent_reg_date: null,
      parent_creditor: null,
      parent_vin: null,
      parent_is_leasing: false,

      // Client Component Sync Values
      parent_entityType: null,
      parent_client_id: null,
      parent_company: null,
      parent_company_client_name: null,
      parent_company_client_first_name: null,
      parent_company_client_names: [],
      parent_second_driver: null,
      parent_addedRegistrationNumber: false,
      parent_driver: null,
      parent_search_client: null,
      parent_company_client_phone: null,
      parent_company_client_gsm: null,
      parent_company_client_fax: null,
      parent_company_client_email: null,
      parent_company_client_language: null,
      parent_web: null,
      parent_currency: null,
      parent_representative_company_id: null,
      parent_user: null,
      parent_note: null,
      parent_familyClient: null,
      parent_taxation_reason_id: null,
      parent_denomination: null,
      parent_registration_code: null,
      parent_tva: null,

      parent_address: null,
      parent_address_same_as: false,

      parent_shipping_address: null,

      dialog: false,

      isEditModeForDataTableItem: false,
      selectedFamilyArticlBeforeEditMode: null,
      selectedItemIndexInDataTable: null,
      currentMu: '',
      mus: [],

      // Parent Project Name
      parent_project_name: null,
      parent_project: null,
      parent_project_location: null,
      parent_project_locations: null,
      parent_project_note: null,
      parent_project_status: null,

      token: this.$store.state.AccessToken,
      header: {Authorization: "Bearer " + this.$store.state.AccessToken},
      autocompleteValueLocal: "off",
      dataHeader: [
        {
          text: "",
          align: "right",
          width: '10px',
          sortable: false,
          value: "information",
        },
        {
          text: this.$t("scheduler.article"),
          align: "start",
          width: 450,
          sortable: true,
          value: "article_original_name",
        },
        {
          text: this.$t("scheduler.quantity"),
          align: "end",
          width: 80,
          sortable: true,
          value: "quantity",
        },
        // { text: this.$t('scheduler.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
        {
          text: this.$t("billings.unit_price"),
          align: "end",
          width: 80,
          sortable: false,
          value: "rounded_unit_price",
        },
        {
          text: this.$t("scheduler.red_percentage"),
          align: "end",
          width: 80,
          sortable: false,
          value: "red_percentage",
        },
        // { text: this.$t('scheduler.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
        {
          text: this.$t("scheduler.tva"),
          align: "end",
          width: 100,
          sortable: false,
          value: "tva",
        },
        {
          text: this.$t("scheduler.thtva"),
          align: "end",
          width: 125,
          sortable: false,
          value: "thtva",
        },
        {
          text: this.$t("scheduler.total"),
          align: "end",
          width: 125,
          sortable: false,
          value: "total",
        },
        {
          text: this.$t("billings.actions") + "      .",
          align: "center",
          width: 200,
          sortable: false,
          value: "actions",
        },
      ],
      color: null,
      mask: "!#XXXXXXXX",
      menu: false,
      timepickermodal: false,
      time: null,
      fromTimePickerModal: false,
      fromTime: null,
      toTimePickerModal: false,
      toTime: null,
      date: null,
      fromDate: null,
      toDate: null,
      dateDialog: false,
      fromTimeDialog: false,
      toTimeDialog: false,
      fromDateDialog: false,
      toDateDialog: false,
      datetime: DateTime.local().toISO(),
      workresources: [],
      storedworkresources: [],
      work_resource: null,
      work_types: [],
      stored_work_types: [],
      work_type: null,
      warning_note: false,
      free_text: '',
      billType: null,
      appointmentdata: null,
      reminder: null,
      starttime: null,
      endtime: null,
      notification_types: [],
      notification_type: null,

      confirmationDialog: false,
      confirmationDialogConfirmationText: null,
      confirmationDialogReCheckBoxConfirmationText: null,
      confirmationDialogFalseText: null,
      confirmationDialogTrueText: null,
      confirmationDialogButtonClickedIs: null,
      confirmationDialogOperation: null,
      selectedDepot: null,
      pauseWatchForUnitPrice: false,

      stock_module_data_for_order: null,
      lastSupplierOrderCreated: null,

      changeSelectedBillIdentifierForTemp: null,
      editIdentifier: null,
      taxationAllowed: true,

      setDefaultObjectForDirtyChecking: null,
      expandedGroups: [],
      pickup_start_datetime: null,
      pickup_end_datetime: null,
      pickup_note: null,
      pickup_address: null,
      is_pickup_appointment: false,

    };
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          filter: "", // Selectors that do not lead to dragging (String or Function)
          animation: 350,
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);

            // vnode.context.saveOrder().then(() => {
            //     vnode.context.updateGroupText(event);
            // });
          },
          onMove: function (/**Event*/ evt, /**Event*/ originalEvent) {
            if (
                evt.dragged.className.includes("first_row") ||
                evt.dragged.className.includes("last_row") ||
                evt.related.className == "last_row" ||
                evt.related.className == "first_row"
            ) {
              return false;
            }
            // vnode.context.saveOrder().then(() => {
            //     vnode.context.updateGroupText(evt);
            // });

          }
        };
        console.log(
            "get element by tag name moving",
            el.getElementsByClassName("tbody")[0],
            options
        );
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
  async mounted() {
    this.clientStatuses = await this.getCodes('client_statuses', false);

    await this.getCalendarViewTypes();
    (this.header = {Authorization: "Bearer " + this.token}),
        (this.creditNoteMultiplicationValue =
            this.getCompanyPropertyFromLocalStorage(
                "CREDIT_NOTE_MULTIPLICATION_VALUE"
            ) ?? this.creditNoteMultiplicationDefaultValue);
    this.resetted_data_table = true;
    this.setBillType();
      this.getWorkTypes();
      this.getPaymentMethods();
    this.resetSrNo();
      this.getCreditors();
      this.getOrderStatuses();
      this.getMeasurementUnits();
      this.getNotificationTypes();

      this.getTvas().then(async () => {
        
      this.$store.commit("setShowPrintReportInPrintDropDown", false);
      this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", false);
      await this.fixWorkType();
      await this.checkForPendingArticle();

      this.$store.state.showApplySelectedArticleButton =
          this.article == "" ? true : false;

      if (this.selectedBill) {
        setTimeout(() => {
          this.$store.state.currentBillPeppolStatus = this.determineBillPeppolStatus(this.selectedBill);
          this.fetchBillRelatives(this.selectedBill);
        }, 3000);
      }
      });
    
  },
  activated() {
    this.isPageLoaded = false
    if (!this.appointmentSetupData) {
      this.$router.push({name: "Calendar"});
    }
    this.resetSrNo();
    this.setCurrentFormOperation();
    this.setPageData();
    this.$refs['clientComp'].onResetPageClient();
    this.setBillType();
    this.$store.commit("setShowPrintReportInPrintDropDown", false);
    this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", false);
    this.resetted_data_table = true;
    this.driverAddresses = [];
  },
  beforeDestroy() {
    this.resetSrNo();
    this.$store.commit("setBillCancelVisible", false);
    this.$store.commit("setEditingAllowed", false);
    this.$store.commit("setMakeBillVisible", false);
    this.$store.commit("setMakePurchaseOrderVisible", false);
    this.$store.commit("setMakeCreditNoteVisible", false);
    this.resetted_data_table = false;
    this.checkAndFixTaxation();
  },
  methods: {
    updateGroupText(evt){
        return new Promise((resolve, reject) => {
            let tempSelectedArticles = this.selectedArticles;
            var trs = document.querySelectorAll('tbody tr'), i;
            let previousGroupText = null;
            let processedSelectedArticles = [];
            this.selectedArticles = [];
            let alreadyAddedIdentifiers = [];
            let alreadyAddedExpeditions = [];
            trs.forEach((tr) => {
                let row = tr;
                // Identifier
                let article_identifier_row = row.getElementsByClassName("article_identifier");
                let uiIdentifier = article_identifier_row && article_identifier_row.length > 0 ? (article_identifier_row[0].textContent ?? "") : "";
                // Group
                let group = row.getElementsByClassName("group-header");
                // Update group if null
                group && group.length > 0 ? (previousGroupText = group[0].textContent ?? null) : null;
                console.log("event group tr",previousGroupText, uiIdentifier, group, row.getElementsByClassName("article_identifier"));
                // Update Selected Articles
                tempSelectedArticles.forEach((article) => {
                    let selectedArticleIdentifier = article && article.article_original_name ? (article.article_original_name.id == 0 ? article.text : article.article_original_name.identifier) : article.text;
                    if(!article.expedition_id && uiIdentifier.toLowerCase().toString().trim() == selectedArticleIdentifier.toLowerCase().toString().trim() && ! alreadyAddedIdentifiers.includes(selectedArticleIdentifier.toLowerCase().toString().trim())){
                        article.group = previousGroupText;
                        processedSelectedArticles.push(article);
                        alreadyAddedIdentifiers.push(selectedArticleIdentifier.toLowerCase().toString().trim());
                    }
                    else if((article.expedition_id) && uiIdentifier.toLowerCase().toString().trim() == selectedArticleIdentifier.toLowerCase().toString().trim() && ! alreadyAddedExpeditions.includes(article.expedition_id)){
                        article.group = previousGroupText;
                        processedSelectedArticles.push(article);
                        alreadyAddedExpeditions.push(article.expedition_id);
                    }
                });
            });
            this.selectedArticles = processedSelectedArticles;
            resolve();
        });
    },
    toggleGroup(group) {
        console.log("group toggle", group);
        const index = this.expandedGroups.indexOf(group);
        if (index >= 0) {
            this.expandedGroups.splice(index, 1);
        } else {
            this.expandedGroups.push(group);
        }
        this.fixHiddenItemsInGroup();
    },
    fixHiddenItemsInGroup(){
        return new Promise((resolve, reject) => {
            var trs = document.querySelectorAll('tbody tr'), i;
            let previousGroupText = null;
            trs.forEach((tr) => {
                let row = tr;
                // Identifier
                let article_identifier_row = row.getElementsByClassName("article_identifier");
                let uiIdentifier = article_identifier_row && article_identifier_row.length > 0 ? (article_identifier_row[0].textContent ?? "") : "";
                // Group
                let group = row.getElementsByClassName("group-header");
                // Update group if null
                group && group.length > 0 ? (previousGroupText = group[0].textContent ?? null) : null;
                if(group && group.length == 0 && uiIdentifier){
                    if(previousGroupText && this.expandedGroups.includes(previousGroupText)){
                        tr.style.display = 'none';
                    }
                    else{
                        tr.style.display = ''
                    }
                }
            });
            resolve();
        });
    },
    isGroupExpanded(group) {
        if(this.expandedGroups.length == 0){
            return true;
        }
        return this.expandedGroups.includes(group);
    },
    setPageLoadedVar(val){
        setTimeout(() => {
            this.isPageLoaded = val;
        }, 500);
    },
    printRequestedOut() {
      let depotOutArticle = this.selectedArticles.find(a => this.isset(a.depot_out_id))
      if(!depotOutArticle) return false;
      let id = depotOutArticle.depot_out_id;
      let url = API_BASE_URL + "/depot/" + id + "/print?document=depot_out&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = 'depot_out_report'
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    isDirty(){
        if(this.setDefaultObjectForDirtyChecking == null){ // In case of add this variable will be null
            return true;
        }
        // console.log("dirty object", this.setDefaultObjectForDirtyChecking, this.makeSubmitData(), this.areObjectsEqual(this.setDefaultObjectForDirtyChecking,  this.makeSubmitData(), ['stock_property']));
        return ! this.areObjectsEqual(this.setDefaultObjectForDirtyChecking,  this.makeSubmitData(), ['stock_property']);
    },
    goBackToListing(){
        this.$router.push("/scheduler").catch(() => {
        });
    },
    setDueDate(){
        this.due_date = this.generateDueDate(this.current_date , this.paymentTermsDays);
    },
    entitytypeChangedToCompany(val){
        if(val){ // if company
            this.checkAndFixTaxation();
        }
        else{ // If Private
            this.taxationAllowed = true;
        }
    },
    editOrder(item) {
      this.$store.commit("seteditId", item);
      this.$router.push("/billing/edit")
    },
    openOrderHistoryModal(){
      this.orderHistoryDialog = false;

      setTimeout(() => this.orderHistoryDialog = true, 20)
    },
    showNoteEditor() {
      this.noteDialog = true;
    },
    getTimeSlotsForWorkPoint(workPointId) {
      return new Promise((resolve, reject) => {
        axios.get(API_BASE_URL + "/work_points/timings?work_point_id=" + workPointId, {headers: this.header})
            .then(({data}) => {
              resolve(data.data);
            })
            .catch(function (error) {
              reject(error);
            })
      });
    },
    async setupNewAppointment() {
      if (!this.$route.path.includes("add") || !this.appointmentSetupData) {
        return false;
      }

      if (!this.appointmentSetupData.new_start_date_time) {
        let todaysDate = moment();
        this.date = todaysDate.format("YYYY-MM-DD");
        this.fromTime = this.operationalTimes?.minTime || null;
        this.fromDate = todaysDate.format("YYYY-MM-DD");
        this.toDate = todaysDate.format("YYYY-MM-DD");
      }

      let data = this.appointmentSetupData;

      if(!this.isset(data?.new_start_date_time)) {
        return false;
      }

      let [newDate, newTime] = data.new_start_date_time.split(" ");

      this.date = newDate;
      this.fromTime = newTime;
      this.fromDate = newDate;
      this.toDate = newDate;
      if (this.work_type) {
        this.toTime = moment(newTime, "HH:mm").add(this.work_type.work_duration, "minutes").format("HH:mm");
      }
      this.work_resource = this.workresources.find(wr => wr.id === data.work_resource_id);
      let calendarViewTypes = this.calendarViewTypes.length > 0 ? this.calendarViewTypes : await this.getCalendarViewTypes();

      let calendarView = calendarViewTypes.find(cvt => cvt.id === this.work_resource?.calendar_type_id)

      if (calendarView) {
        this.is_full_day = calendarView.key !== 'day';
      }

      if (this.appointmentSetupData.calendarViewType !== undefined) {
        this.is_full_day = this.appointmentSetupData.calendarViewType.key !== 'day';
      }

      // this.$store.commit("setAppointmentRedirectData", null);

    },
    getOrderReminder() {
      console.log("selected Bill reminder", this.selectedBill.type);
      if (this.selectedBill && this.selectedBill.type && (this.selectedBill.type.key == 'credit_note' || this.selectedBill.type.key == 'bill')) {
        axios
            .get(
                API_BASE_URL + "/billings/" + this.selectedBill.id + "/reminders",
                {headers: this.header}
            )
            .then(({data}) => {
              this.order_reminders = data;
              this.order_reminder_type_counts = data.length;
              // this.$toast.success( this.$t('billings.text_messages.identifier_updated_successfully'));
            })
            .catch((error) => {
              // this.$toast.error( this.$t('stocks.something_went_wrong'));
            })
            .finally(() => {
              // 
            });
      }
    },
    saveBillIdentifier() {
      let billingsSaveBillData = {
        "identifier": this.editIdentifier
      }

      
      axios
          .put(
              API_BASE_URL + "/billings/" + this.selectedBill.id + '/identifier',
              billingsSaveBillData,
              {headers: this.header}
          )
          .then(({data}) => {
            this.selectedBill.bill_number = this.editIdentifier;
            this.disallowEditIdentifier();
            this.$toast.success(this.$t('billings.text_messages.identifier_updated_successfully'));
          })
          .catch((error) => {
            this.$toast.error(this.$t('stocks.something_went_wrong'));
          })
          .finally(() => {
            
          });
    },
    changeSelectedBillIdentifier() {
      this.confirmChangeIdentifierPopUp();
    },
    allowEditIdentifier() {
      this.changeSelectedBillIdentifierForTemp = this.selectedBill;
      this.editIdentifier = this.selectedBill.bill_number;
    },
    disallowEditIdentifier() {
      this.changeSelectedBillIdentifierForTemp = null;
      this.editIdentifier = null;
    },
    updatePricingBasedOnSupplier(selectedSupplier) {
      console.log("update pricing for the supplier", selectedSupplier);
      console.log("update pricing for the parent supplier", this.parentSearchSelectedArticleSupplierDetail);
      this.parentSearchSelectedArticleSupplierDetail = selectedSupplier;
      this.parentSearchSelectedArticleSupplierDetail = {
        "text": "",
        "value": this.article.supplier[0].identifier.toLowerCase() + "_stock",
        "align": "start",
        "width": 300,
        "sortable": false,
        "field_stock": this.article.supplier[0].identifier.toLowerCase() + "_stock",
        "field_delivery_date": this.article.supplier[0].identifier.toLowerCase() + "_delivery_date",
        "field_price": this.article.supplier[0].identifier.toLowerCase() + "_price",
        "field_client_price": this.article.supplier[0].identifier.toLowerCase() + "_client_price",
        "header_text": this.article.supplier[0].name,
        "supplier_detail": selectedSupplier,
        "order": 2,
        "index": 2,
        "visible": true
      }
      this.updatePricingForSelectedArticle(this.article.id);
      this.article.supplier[0].random = Math.random();

      console.log("update pricing for the parent supplier ---- next", this.parentSearchSelectedArticleSupplierDetail);
    },
    showAttachmentDialog() {
      this.attachmentDialog = true;
    },
    async getMeasurementUnits() {
      await this.getCodes('measurement_units', 'measurement_units')
          .then((data) => {
            this.mus = data
            this.mus = this.mus.sort(function (a, b) {
              if (a.text < b.text) {
                return -1;
              }
              if (a.text > b.text) {
                return 1;
              }
              return 0;
            });
          });
    },
    selectGivenFamilyArticle(familyArticle) {
      return this.familyArticles.find((element) => {
        if (element.id == familyArticle.id || element.parent_family_id == familyArticle.id) {
          return element;
        }
      });
    },
    dataTableItemClicked(item) {
      // Enable Edit Mode for DataTable Item
      this.isEditModeForDataTableItem = true;
      this.selectedItemIndexInDataTable = item.srno;

      console.log("selecting item", item);
      if (item.article_original_name) {
        this.articles = [item.article_original_name];
        this.article = item.article_original_name;
        // console.log(this.selectGivenFamilyArticle(item.article_original_name.family));
        this.selectedFamilyArticlBeforeEditMode = this.selectedFamilyArticle;
        this.selectedFamilyArticle = this.selectGivenFamilyArticle(item.article_original_name.family);
        console.log("select item", item.article_original_name.family_id);
        this.$nextTick(() => {
          let internalSupplier = this.cachedData["suppliers"][this.selectedFamilyArticle.id].find((element) => {
            if (element.id == 0) {
              return element;
            }
          })
          this.article.supplier = (item.supplier ?? [internalSupplier]).concat(this.cachedData["suppliers"][this.selectedFamilyArticle.id]);
          this.quantity = parseFloat(item.quantity);
          this.currentMu = item.selectedmu;
          this.first_row_tariff = parseFloat(item.tarrif ?? 0);
          this.unit_price = parseFloat(item.unit_price);
          this.red_percentage = parseFloat(item.red_percentage ?? 0)
          // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
          this.first_row_thtva = parseFloat(item.thtva);
          this.first_row_tva = parseFloat(item.tva)
          this.first_row_total = parseFloat(item.total);
          this.first_row_minimum_sales_price = parseFloat(item.minimum_sales_price);
          this.parentGroupText = item.group ?? null;
          console.log("minimum sales price", item);

          // Now call the pricing api again to get minimum sales price and updated price
          let articleId = (typeof item.article == 'number' ? item.article : (typeof item.article.id == 'number' ? item.article.id : false))
          if ((!item.minimum_sales_price) && articleId) {
            if (item.supplier && item.supplier.length > 0) {
              this.parentSearchSelectedArticleSupplierDetail = item.supplier[0];
              this.parentSearchSelectedArticleSupplierDetail.supplier_detail = item.supplier[0];
            }
            this.updatePricingForSelectedArticle(articleId);
            return false;
          }
        });
      }
      // this.article.supplier = [];
      // this.article.supplier_stock_detail = [];
    },
    showTableSupplierIcon(item) {
      return (
          item.supplier &&
          item.supplier.length > 0 &&
          item.supplier[0] &&
          item.supplier[0].id
      );
    },
    selectedAppointmentStatusIs(status, appointmentStatusId = null) {
      let appointmentStatus = this.appointmentStatuses.find(
          (appointmentStatus) => appointmentStatus.key == status
      );

      if (!appointmentStatus) {
        return false;
      }

      return (
          appointmentStatus.id == (appointmentStatusId ?? this.appointmentStatus)
      );
    },
    updateAppointmentStatus() {
      return new Promise((resolve, reject) => {
        axios
            .put(
                API_BASE_URL + "/appointments-status/" + this.appointmentdata.id,
                {
                  appointment_status_id: this.appointmentStatus,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.token,
                  },
                }
            )
            .finally(() => {
              
              this.updatingAppointmentStatus = false;
            })
            .then(({data}) => {
              this.$toast.success(
                  this.$t("scheduler.appointment_status_update_success_message"));
              this.appointmentStatusDialog = false;
              resolve(data);
            })
            .catch((error) => {
              this.$toast.error(
                  this.$t("scheduler.appointment_status_update_error_message"));
              reject(error);
            });
      });
    },
    async checkForPendingArticle() {
        // get route query parameter called article
        let pendingArticles = this.selectedArticlePendingAppointment;

        if (!pendingArticles || pendingArticles.length == 0) {
            return false;
        }

        this.resetSelectedArticle();
        this.parentSearchSelectedArticleId = null;
        this.parentSearchSelectedArticleSupplierDetail = null;

        for (let index = 0; index < pendingArticles.length; index++) {
            this.setForceHideLoadingModel();
            let pendingArticle = pendingArticles[index];
            this.setQuantityOfPendingArticle(pendingArticle.quantity ?? this.defaultQuantity);
            console.log("check Reached to next   ******* ", pendingArticle.article.description);
            this.resetSelectedArticle();
            this.parentSearchSelectedArticleId = null;
            this.parentSearchSelectedArticleSupplierDetail = null;

            this.parentSearchSelectedArticleSupplierDetail = pendingArticle.slot;
            this.parentSearchSelectedArticleId = pendingArticle.article;

            // Auto Select Quantity Text Box
            // this.$nextTick(() => this.$refs["tableQuantity"].select());

            this.readyToAcceptNewArticleFromStock = false;
            // At this time pricing will be calculated automatically by updatePricingForSelectedArticle()
            await this.checkIfArticleIsAddedYetOrNot();
            this.resetQuantityOfPendingArticle();
            console.log("check Jump to next   ******* ", this.selectedArticles);
            if(index == (pendingArticles.length - 1)){
                this.$store.commit("setSelectedArticlePendingAppointment", null);
                this.resetForceHideLoadingModel();
            }
        }
    },
    setForceHideLoadingModel(){
        this.forceHideLoadingModel = true;
        this.showPendingArticleLoadingModel = true;
    },
    resetForceHideLoadingModel(){
        this.forceHideLoadingModel = false;
        this.showPendingArticleLoadingModel = false;
        this.showLoadingModel = false;
    },
    resetQuantityOfPendingArticle(){
        this.quantityFromPendingArticle = null;
        this.selectQuantityFromPendingArticle = false;
        this.quantity = this.selectQuantityFromPendingArticle ? this.quantityFromPendingArticle : (this.selectedFamilyArticle ? this.selectedFamilyArticle.default_quantity : this.defaultQuantity);
    },
    setQuantityOfPendingArticle(quantity){
        console.log("pending article quantity", quantity);
        this.selectQuantityFromPendingArticle = true;
        this.quantityFromPendingArticle = quantity;
    },
    sleepForMs(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    async checkAndWaitForArticleAdded(countForWait = 10) {
        console.log("check recursion *** ",countForWait,  this.readyToAcceptNewArticleFromStockComputed);
        this.showLoadingModel = true;
        if(countForWait < 0){
            return true;
        }
        if (this.readyToAcceptNewArticleFromStockComputed) {
            return true;
        } else {
            // Wait for 500ms and then recursively call the function
            await this.sleepForMs(500);
            return this.checkAndWaitForArticleAdded(--countForWait);
        }
    },
    async checkIfArticleIsAddedYetOrNot() {
            console.log("check is safe to proceed", this.readyToAcceptNewArticleFromStock);
            if (this.readyToAcceptNewArticleFromStock == false) {
                // Keep checking if it's ready to be inserted into datatable
                await this.checkAndWaitForArticleAdded(5)
                console.log("check is safe to proceed -----------", this.readyToAcceptNewArticleFromStock);
                // Once ready, insert into datatable
                await this.addArticleToDataTable().then(async () => {
                    // After inserting, wait for the proposed article to be added 
                    // and then resolve the main promise
                    this.readyToAcceptNewArticleFromStock = false;
                    await this.checkAndWaitForArticleAdded(5)
                        console.log("check FINISHED ******* ", this.selectedArticles);
                        console.log("-------------- check finished --------------------------- ******* ", this.selectedArticles);
                });
            } else {
                // If already ready, resolve immediately
                console.log("-------------- check finished --------------------------- ******* ", this.selectedArticles);
            }
    },
    async fixWorkType() {
      var workInterval = await setInterval(() => {
        if (this.stored_work_types.length == 0 && this.work_type == null) {
          this.getWorkTypes();
        } else {
          this.setWorkType();
          clearWorkInterval();
        }
      }, 1000);
      let clearWorkInterval = () => {
        clearInterval(workInterval);
      };
    },
    checkIfSelectedBillHasArticleNotDeliveredYet() {
      let hasNotDelivered = false;
      if (
          this.selectedBill &&
          this.selectedBill.details &&
          this.selectedBill.details.length > 0
      ) {
        this.selectedBill.details.forEach((selectedBillOrderDetailItem) => {
          if (selectedBillOrderDetailItem && selectedBillOrderDetailItem.supplier_order_details_id && selectedBillOrderDetailItem.supplier_order_detail && selectedBillOrderDetailItem.supplier_order_detail.supplier_order)
            if (
                [10091000, 10091001, 10091004].includes(selectedBillOrderDetailItem.supplier_order_detail.supplier_order.status_id)
            ) {
              hasNotDelivered = true;
            }
        });
      }
      return hasNotDelivered;
    },
    retryPopUp(){
        this.confirmationDialogConfirmationText = this.$t("stocks.retry_order_confirmation");
        this.confirmationDialogFalseText = this.$t("stocks.cancel");
        this.confirmationDialogTrueText = this.$t("stocks.keep_trying");
        this.confirmationDialogOperation = 'keep_trying';
        this.confirmationDialog = true;
    },
    makeOrderToSupplier(supplierOrderId, supplier_stock_detail) {
      console.log("supplier order", supplier_stock_detail.supplier_id, parseInt(supplier_stock_detail.supplier_id), (parseInt(supplier_stock_detail.supplier_id) != 0), (parseInt(supplier_stock_detail.supplier_id) == 0));
      if (supplier_stock_detail.supplier_id && (!parseInt(supplier_stock_detail.supplier_id) == 0)) {
        axios
            .post(
                API_BASE_URL + "/suppliers/order/" + supplierOrderId,
                supplier_stock_detail,
                {headers: this.header}
            )
            .then(({data}) => {
              if (typeof data.data[Object.keys(data.data)[0]] == "string") {
                this.$toast.warning(data.data[Object.keys(data.data)[0]]);
              } else {
                let responseLength = Object.keys(data.data[Object.keys(data.data)[0]]).length;
                console.log("response length order", responseLength);
                for (let index = 0; index < responseLength; index++) {
                  let arrayItemKey = Object.keys(data.data[Object.keys(data.data)[0]])[index] + "";
                  let arrayItemValue = data.data[Object.keys(data.data)[0]][arrayItemKey];
                  if (arrayItemValue) {
                    let response = arrayItemValue;
                    if (response.error_code == 0) {
                      if (response.availability > -1) {
                        this.$toast.success(
                            this.$t("stocks.order_placed_successfully")
                        );
                      } else {
                        this.retryPopUp();
                      }
                    } else {
                      this.retryPopUp();
                      this.$toast.error(response.error_message);
                    }
                  }
                }
              }
            })
            .catch((error) => {
              this.retryPopUp();
              this.$toast.error(this.$t("stocks.something_went_wrong"));
            })
            .finally(() => {
              
              this.ordering = false;
            });
      }
    },
    // submitOrderDetails(supplier_stock_detail) {
    //   if(supplier_stock_detail.supplier_id && (! parseInt(supplier_stock_detail.supplier_id) == 0)){
    //   
    //   axios
    //     .post(API_BASE_URL + "/orders", supplier_stock_detail, {
    //       headers: this.header,
    //     })
    //     .then((response) => {
    //       console.log("submit order details", response, response.status);
    //       if (response.status === 201) {
    //         console.log("supplier order", response.data.data);
    //         this.lastSupplierOrderCreated = response.data.data;
    //         this.makeOrderToSupplier(
    //           response.data.data.id,
    //           supplier_stock_detail
    //         );
    //       } else {
    //         // alert('ERROR on saving. Please try again later');
    //         this.$toast.error(this.$t("stocks.something_went_wrong"));
    //       }
    //     })
    //     .catch((error) => {
    //       this.$toast.error(this.$t("stocks.something_went_wrong"));
    //     })
    //     .finally(() => {
    //       
    //     });
    //   }
    // },
    updateItemInSelectedArticles(item) {
      this.selectedArticles.forEach((element) => {
        console.log("element", element, item);
      });
    },
    addTextAndGroupToArticle(item) {
      this.tempItemGroup = item.group;
      this.tempItemText = item.text;
      this.selectedItemForGroupAndText = item;
      this.showTextDialog = true;
    },
    saveTextAndGroupToArticle() {
      this.selectedItemForGroupAndText.group = this.tempItemGroup;
      this.selectedItemForGroupAndText.text = this.tempItemText;
      this.updateItemInSelectedArticles(this.selectedItemForGroupAndText);
      this.showTextDialog = false;
      this.selectedItemForGroupAndText = null;
    },
    cancelTextAndGroupToArticle(item) {
      this.tempItemGroup = null;
      this.tempItemGroup = null;
      this.showTextDialog = false;
      this.selectedItemForGroupAndText = null;
    },
    saveOrder(event) {
      return new Promise((resolve, reject) => {
        let tempSelectedArticles = this.selectedArticles;
        let processedSelectedArticles = [];
        // this.selectedArticles = [];
        let count = 0;
        let identifiers = document.getElementsByClassName("article_identifier");
        identifiers.forEach(element => {
          let uiIdentifier = element.textContent;
          tempSelectedArticles.forEach((article) => {
            let selectedArticleIdentifier = article && article.article_original_name ? (article.article_original_name.id == 0 ? article.text : article.article_original_name.identifier) : article.text;
            if (uiIdentifier.toLowerCase().toString().trim() == selectedArticleIdentifier.toLowerCase().toString().trim()) {
              article.srno = count++;
              article.row = count;
              processedSelectedArticles.push(article);
            }
          });
        });
        this.selectedArticles = processedSelectedArticles;
        resolve();
      });


      // this.$nextTick(() => {
      //   this.selectedArticles = processedSelectedArticles;
      // })
    },
    row_classes(item) {
      // if(item.)
      return "articles_row_item";
    },
    closeAddNoteModel() {
      this.bill_note_temp = this.bill_note;
      this.note_dialog = false;
    },
    saveAddNoteModel() {
      this.bill_note = this.bill_note_temp;
      if (this.appointmentdata) {
        // Only Call API if appointment is already selected
        let data = {
          note: this.bill_note ?? "",
          appointment: {
            id: this.appointmentdata ? this.appointmentdata.id : null,
          },
        };
        axios
            .put(API_BASE_URL + "/appointments/" + data.appointment.id, data, {
              headers: this.header,
            })
            .then((response) => {
              if (response.status === 200) {
                this.$toast.success(
                    this.$t("scheduler.text_messages.schedule_updated")
                );
              } else {
                this.$toast.error(response);
              }
            })
            .catch((error) => {
              if (error.response.data.errors[0]) {
                this.$toast.error(
                    this.$t(
                        "scheduler.text_messages." + error.response.data.errors[0]
                    )
                );
              } else {
                this.$toast.error(
                    this.$t("scheduler.text_messages.something_went_wrong")
                );
              }
            })
            .finally(() => {
              
              this.note_dialog = false;
            });
      } else {
        this.note_dialog = false;
      }
    },
    checkIfPastDateAppointment() {
      if (this.appointmentdata) {
        let today = new Date();
        let appointmetDate = new Date(this.appointmentdata.start_time);
        return !(
            appointmetDate.getDate() == today.getDate() &&
            appointmetDate.getMonth() == today.getMonth() &&
            appointmetDate.getFullYear() == today.getFullYear()
        );
      } else if (this.$route.name == "AddScheduler") {
        return false;
      }
      return true;
    },
    reverseCalculate(total, thtva) {
      this.pauseWatchForUnitPrice = true;
      if (total) {
        // x = total/quantity(1+ tva/100)
        // (unit price * quantity) * ( 1 -  red_percentage /100)( 1 + tva /100)
        console.log("total", total, this.quantity);
        this.unit_price = parseFloat(
            total /
            (this.quantity *
                (1 - this.red_percentage / 100) *
                (1 + this.first_row_tva / 100))
        );
        this.rounded_unit_price = parseFloat(
            total / (this.quantity * (1 + this.first_row_tva / 100))
        ).toFixed(this.getDecimalNumber());
        this.calculateFields(true, true, true, false);
      }
      if (thtva) {
        //  thtva = unit_price * quantity (1 - r/100 )
        this.unit_price = parseFloat(
            thtva / (this.quantity * (1 - this.red_percentage / 100))
        );
        this.rounded_unit_price = parseFloat(thtva / this.quantity).toFixed(
            this.getDecimalNumber()
        );
        console.log("THTVA", this.thtva / this.quantity);
        this.calculateFields(true, true, false, true);
      }
    },
    getInterventions(interventions) {
      let shortInterventions = "";
      for (let index = 0; index < interventions.length; index++) {
        if (
            interventions[index] &&
            interventions[index].user &&
            interventions[index].user.short_name
        ) {
          shortInterventions += interventions[index].user.short_name + " / ";
        }
      }
      return shortInterventions.slice(0, -2);
    },
    getProposedArticles(
        article_id,
        family_article_id,
        family_client_id,
        quantity
    ) {
        return new Promise((resolve, reject) => {
                let isArticleProposed = false;
                axios
                .get(
                    API_BASE_URL +
                    "/articles/proposed/" +
                    article_id +
                    "?family_article_id=" +
                    family_article_id +
                    (family_client_id ? "&family_client_id=" + (typeof family_client_id == 'object' ? family_article_id.id : family_client_id) : ""),
                    {headers: this.header}
                )
                .then(({data}) => {
                    data.data.forEach((proposedArticle) => {
                    console.log("article to add", proposedArticle);
                    let unit_price = proposedArticle.price ?? 0.0;
                    let rounded_unit_price = proposedArticle.price ?? 0.0;
                    let first_row_tariff = parseFloat(
                        proposedArticle.article.tariff_price ?? 0
                    );
                    let first_row_thtva = 0;
                    let first_row_total = 0;
                    // let first_row_minimum_sales_price = proposedArticle.minimum_sales_price;
                    let first_row_minimum_sales_price = 0; // as this is proposed article and we haven't set the proposed article price
                    let red_percentage = 0;
                    let thtva = parseFloat(
                        unit_price * quantity - (unit_price * quantity * 0.0) / 100
                    );
                    this.resetMeasurementUnit();

                    console.log("proposed article after", proposedArticle.article);

                    // check if article already exists in the datatable
                    let alreadyExistsProposedArticle = this.selectedArticles.find(
                        (element, index) => {
                            if (
                                typeof element.article == "object" &&
                                element.article.id == proposedArticle.article.id
                            ) {
                            this.selectedArticles.splice(index, 1);
                            return element;
                            }
                            if (
                                typeof element.article == "number" &&
                                element.article == proposedArticle.article.id
                            ) {
                            this.selectedArticles.splice(index, 1);
                            return element;
                            }
                        }
                    );
                    if (alreadyExistsProposedArticle) {
                        // recalculate the row total
                        quantity =
                            parseFloat(quantity) +
                            parseFloat(alreadyExistsProposedArticle.quantity);
                        unit_price = parseFloat(unit_price);
                        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                        first_row_thtva = parseFloat(
                            unit_price * quantity -
                            (unit_price * quantity * red_percentage) / 100
                        );
                        first_row_total = parseFloat(
                            parseFloat(first_row_thtva) +
                            parseFloat((this.first_row_tva * first_row_thtva) / 100)
                        );

                        thtva = parseFloat(
                            unit_price * quantity - (unit_price * quantity * 0.0) / 100
                        );
                    }

                    let isToMultiplyWithCredNoteMulValue = false;
                    if (this.billType && this.billType.key == "credit_note") {
                        isToMultiplyWithCredNoteMulValue = true;
                    }

                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: proposedArticle.article,
                        article_original_name: proposedArticle.article,
                        quantity: quantity,
                        unit_price: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (unit_price ?? 0)
                                : unit_price ?? 0
                        ),
                        minimum_sales_price: parseFloat(first_row_minimum_sales_price),
                        rounded_unit_price: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (rounded_unit_price ?? 0)
                                : rounded_unit_price ?? 0
                        ).toFixed(this.getDecimalNumber()),
                        red_percentage: red_percentage,
                        tarrif: proposedArticle.article.tariff_price ?? 0,
                        tva: parseFloat(this.first_row_tva),
                        thtva: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (thtva ?? 0)
                                : thtva ?? 0
                        ),
                        stock_property: null,
                        total: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (parseFloat(
                                    parseFloat(thtva) +
                                    parseFloat((this.first_row_tva * thtva) / 100)
                                ) ?? 0)
                                : parseFloat(
                                parseFloat(thtva) +
                                parseFloat((this.first_row_tva * thtva) / 100)
                            ) ?? 0
                        ),
                        text: null,
                        row: 0,
                        text_position: 0,
                        depot_out_id: null,
                        depot_in_id: null,
                        dots: null,
                        group: null,
                        selectedmu: '',
                        currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : (this.defaultBankAccount ? this.defaultBankAccount.currency_id : this.userLanguageId),
                        order_counter_id: null
                    });
                    isArticleProposed = true;
                    this.resetSrNo();
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.readyToAcceptNewArticleFromStock = true;
                    resolve(isArticleProposed);
                });
        })
    },
    showOrHidePrintReport() {
      if (this.selectedBill) {
        let found = false;
        if (
            this.selectedBill &&
            this.selectedBill.details &&
            this.selectedBill.details.length > 0
        ) {
          this.selectedBill.details.forEach((order_detail) => {
            // if (order_detail.depot_out_id || order_detail.depot_in_id) {
            //   found = true;
            // }
            if (order_detail.depot_in_id) {
              found = true;
            }
          });
        }
        console.log("show or hide print report", found);
        if (found) {
          this.$store.commit("setShowPrintReportInPrintDropDown", true);
        } else {
          this.$store.commit("setShowPrintReportInPrintDropDown", false);
        }
      } else {
        this.$store.commit("setShowPrintReportInPrintDropDown", false);
      }
    },
    showOrHidePrintDepotOutReport() {
      if (this.selectedBill) {
        let found = false;
        if (
            this.selectedBill &&
            this.selectedBill.details &&
            this.selectedBill.details.length > 0
        ) {
          this.selectedBill.details.forEach((order_detail) => {
            // if (order_detail.depot_out_id || order_detail.depot_in_id) {
            //   found = true;
            // }
            if (order_detail.depot_out_id) {
              found = true;
            }
          });
        }
        console.log("show or hide print report", found);
        if (found) {
          this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", true);
        } else {
          this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", false);
        }
      } else {
        this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", false);
      }
    },
    confirmChangeIdentifierPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.identifier_confirmation_message"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "identifier_confirmation";
      this.confirmationDialog = true;
    },
    confirmPrintReportPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.print_report_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "print_report";
      this.confirmationDialog = true;
    },
    clientEmailErrorPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.can_not_send_email_beca_email_not_present"
      );
      this.confirmationDialogFalseText = null;
      this.confirmationDialogTrueText = this.$t("close");
      this.confirmationDialogOperation = "message";
      this.confirmationDialog = true;
    },
    confirmPrintLabelPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.print_label_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "print_label";
      this.confirmationDialog = true;
    },
    confirmMakeBillPopUp() {
      if (this.checkIfForCurrentOrderSupplierOrderNotReceived()) {
        this.$nextTick(() => {
          this.confirmationDialogConfirmationText = this.$t(
              "billings.convert_to_bill_confirmation_and_supplier_not_received"
          );
          this.confirmationDialogFalseText = this.$t("no");
          this.confirmationDialogTrueText = this.$t("yes");
          this.confirmationDialogOperation = "make_bill";
          this.confirmationDialog = true;
        });
      } else {
        this.$nextTick(() => {
          this.confirmationDialogConfirmationText = this.$t(
              "billings.convert_to_bill_confirmation"
          );
          this.confirmationDialogFalseText = this.$t("no");
          this.confirmationDialogTrueText = this.$t("yes");
          this.confirmationDialogOperation = "make_bill";
          this.confirmationDialog = true;
        });
      }
    },
    confirmPurchaseOrderPopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.convert_to_purchase_order_confirmation"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_purchase_order";
      this.confirmationDialog = true;
    },
    confirmCreditNotePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "billings.convert_to_credit_note_confirmation"
      );
      this.confirmationDialogReCheckBoxConfirmationText = this.$t(
          "billings.convert_to_credit_note_confirmation_reconfirm_text"
      );
      this.confirmationDialogFalseText = this.$t("no");
      this.confirmationDialogTrueText = this.$t("yes");
      this.confirmationDialogOperation = "make_credit_note";
      this.confirmationDialog = true;
    },
    printReport() {
      let url = API_BASE_URL + "/depot/" + this.selectedDepot.md5_id + "/print?document=report&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    printLabel() {
      let url = API_BASE_URL + "/depot/" + this.selectedDepot.md5_id + "/print?document=labels&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
      let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
      this.setPreviewData(url, fileName, true, 'application/pdf');
    },
    modifyDepotOutClose(modifyDialog, data) {
      console.log("after closing dialog", modifyDialog, data);
      if (data) {
        this.addDepotArticleToDataTable(data, "out");
      }

      this.depotOutMod = false;
    },
    modifyDepotInClose(modifyDialog, data) {
      this.depotInMod = false;
      if (data) {
        this.selectedDepot = data;
        this.storedDepotIn.push(this.selectedDepot.id);
        this.parent_registration_no.depot.push(data);
        this.confirmPrintLabelPopUp();
        this.addDepotArticleToDataTable(data, "in");
      }
    },
    depotIn() {
      this.selectedDepot = null;
      const clientData = this.makeNewClientData();
      const vehicleData = this.makeNewVehicleData();
      this.addClient(clientData).then(() => {
        this.createVehicle(vehicleData).then(() => {
          this.depotInMod = true;
        });
      });
    },
    depotOut() {
      const clientData = this.makeNewClientData();
      const vehicleData = this.makeNewVehicleData();
      this.addClient(clientData).then(() => {
        this.createVehicle(vehicleData).then(() => {
          this.depotOutMod = true;
        });
      });
    },
    getNoteValue(){
        return this.parent_note ? this.parent_note : (
            this.parent_company_client_name && typeof this.parent_company_client_name == "object" 
                ? this.parent_company_client_name.note : null
        );
    },
    makeNewClientData() {
      return {
        name: this.parent_company_client_name
            ? typeof this.parent_company_client_name == "object"
                ? this.parent_company_client_name.name
                : this.parent_company_client_name
            : null,
        first_name: this.parent_company_client_first_name
            ? typeof this.parent_company_client_first_name == "object"
                ? this.parent_company_client_first_name.first_name
                : this.parent_company_client_first_name
            : null,
        family_client_id: this.parent_familyClient
            ? typeof this.parent_familyClient == "object"
                ? parseInt(this.parent_familyClient.id)
                : this.parent_familyClient
            : null,
        denomination_id: this.parent_denomination ? (typeof this.parent_denomination == 'object' ? this.parent_denomination.id : this.parent_denomination) : null,
        taxation_reason_id: this.parent_taxation_reason_id ? (typeof this.parent_taxation_reason_id == 'object' ? this.parent_taxation_reason_id.id : this.parent_taxation_reason_id) : null,
        email: this.parent_company_client_email ?? null,
        language: this.parent_company_client_language ?? this.userLanguageId,
        language_id: this.parent_company_client_language ?? this.userLanguageId,
        phone: this.parent_company_client_phone ?? null,
        gsm: this.parent_company_client_gsm ?? null,
        fax: this.parent_company_client_fax ?? null,
        web: this.parent_web ?? null,
        tva: this.parent_tva ?? null,
        note: this.getNoteValue(),
      };
    },
    addClient(data) {
      return new Promise((resolve, reject) => {
        if (
            !(this.parent_company_client_name
                ? typeof this.parent_company_client_name == "object"
                    ? this.parent_company_client_name.id
                    : null
                : null)
        ) {
          this.showLoadingModel = true;
          axios
              .post(API_BASE_URL + "/clients", data, {headers: this.header})
              .then((response) => {
                console.log("add client data", response.data.data);
                this.parent_company_client_name = response.data.data;
                resolve();
              })
              .catch((error) => {
                reject();
              })
              .finally(() => {
                this.showLoadingModel = false;
              });
        } else {
          resolve();
        }
      });
    },
    makeNewVehicleData() {
      return {
        client_id: this.parent_company_client_name
            ? typeof this.parent_company_client_name == "object"
                ? this.parent_company_client_name.id
                : null
            : null,
        vehicle_type_id: this.parent_vehicle_type
            ? typeof this.parent_vehicle_type == "object"
                ? this.parent_vehicle_type.id
                : null
            : null,
        registration_number:
            this.parent_registration_no &&
            typeof this.parent_registration_no == "object"
                ? this.parent_registration_no.registration_number
                : this.parent_registration_no,
        brand_id: this.parent_brand && typeof this.parent_brand == "object" ? this.parent_brand.id : (this.parent_brand ?? null),
        model: this.parent_model ?? null,
        km: this.parent_km ?? null,
        registration_date: this.parent_reg_date ?? null,
        vin: this.parent_vin ?? null,
        is_leasing: this.parent_creditor ? true : false,
        driver: this.parent_driver ? this.parent_driver : null,
        second_driver: this.parent_second_driver ? this.parent_second_driver : null,
        creditor_id: this.parent_creditor
            ? typeof this.parent_creditor == "object"
                ? this.parent_creditor.id
                : this.parent_creditor
            : null,
      };
    },
    createVehicle(data) {
      data.client_id = this.parent_company_client_name
          ? typeof this.parent_company_client_name == "object"
              ? this.parent_company_client_name.id
              : null
          : null;
      return new Promise((resolve, reject) => {
        if (
            !(this.parent_registration_no &&
            typeof this.parent_registration_no == "object"
                ? this.parent_registration_no.id
                : null)
        ) {
          this.showLoadingModel = true;
          axios
              .post(API_BASE_URL + "/client_vehicles", data, {
                headers: this.header,
              })
              .then((response) => {
                this.parent_registration_no = response.data;
                this.parent_addedRegistrationNumber = true;
                resolve();
              })
              .catch((error) => {
                console.log("client vehicle", error.response, error.response.data.errors[0]);
                if(error.response && error.response.data.errors[0]){
                  this.$toast.error(this.$t("vehicles.text_messages." + error.response.data.errors[0]));
                }
                reject();
              })
              .finally(() => {
                this.showLoadingModel = false;
              });
        } else {
          resolve();
        }
      });
    },
    debounceInput: _.debounce(function (val) {
    }, 500),

    setPageData() {
      this.$store.commit("setValidation", false); // enable validation
      this.autocompleteValueLocal = this.autocompleteValue();
      this.$store.commit("setFormMode", SCHEDULER_MODE);
      this.onResetPageData();
      // this.notification_type = this.notification_types[0];
      this.getWorkResources().then(() => {
        if (this.$route.name == "EditScheduler") {
          let appointment = this.$store.state.appointment;
          if (appointment.resourceType) {
            this.resourceType = appointment.resourceType;
            this.setWorkResources();
          }

          if (appointment.id) {
            this.setHistoryModalData('Appointment', appointment.id)
            this.fetchAppointmentByID(appointment.id);
            this.$store.commit("setBillCancelVisible", true);
            this.$store.commit("setEditingAllowed", true);
            this.$store.commit("setFormMode", SCHEDULER_EDIT_MODE);
          } else {
            this.$router.push("/scheduler/add").catch(() => {
            });
            this.setPageLoadedVar(true);
            this.onResetPageData();
          }
        } else if (this.$route.name == "AddScheduler") {
          if (this.$store.state.appointment) {
            let appointment = this.$store.state.appointment;
            if (appointment.resourceType) {
              this.resourceType = appointment.resourceType;
              this.setWorkResources(false, true);
            }
            if (appointment.new_start_date_time) {
              this.datetime = new Date(
                  appointment.new_start_date_time
              ).toISOString();
            }

            this.setupNewAppointment();

          } else {
            this.setWorkResources(true, true);
          }
          this.setPageLoadedVar(true);
        } else {
          this.setPageLoadedVar(true);
          this.onResetPageData();
        }
      });
    },
    confirmationButtonClicked(buttonClicked) {
      this.confirmationDialogButtonClickedIs = buttonClicked;
      this.confirmationDialog = false;
    },
    deletePopUp() {
      this.confirmationDialogConfirmationText = this.$t(
          "scheduler.delete_confirmation_for_appointment"
      );
      this.confirmationDialogFalseText = this.$t("scheduler.cancel");
      this.confirmationDialogTrueText = this.$t("scheduler.delete");
      this.confirmationDialogOperation = "delete";
      this.confirmationDialog = true;
    },
    async getWorkTypes() {
      return new Promise((resolve, reject) => {
        
        axios
            .get(API_BASE_URL + "/work_types", {headers: this.header})
            .finally(() => {})
            .then(({data}) => {
              // this.work_types = data.data;
              this.stored_work_types = data.data;
              resolve(this.stored_work_types);
              // this.work_type = this.work_types[0];
            })
            .catch(function (error) {
              reject(error);
            });
      });
    },
    /**
     * If setAllWorkResources means : Page is refreshed
     */
    setWorkResources(setAllWorkResources, setFirst) {
      this.workresources = this.storedworkresources.filter((element) => {
        if (!setAllWorkResources) {
          if (
              this.resourceType &&
              element.resource_type_id == this.resourceType.id
          ) {
            return true;
          }
        } else {
          return true;
        }

        return false;
      });
      if (setFirst && this.workresources.length > 0) {
        this.work_resource = this.workresources[0];
      }
    },
    getWorkResources() {
      return new Promise((resolve, reject) => {
        // if (
        //   this.cachedData["work_resources"] &&
        //   this.cachedData["work_resources"].length > 0
        // ) {
        //   this.storedworkresources = this.cachedData["work_resources"];
        //   this.setWorkResources();
        //   resolve();
        // } else {
        axios
            .get(API_BASE_URL + "/work_resources", {headers: this.header})
            .then(({data}) => {
              this.storedworkresources = data.data;
              this.cachedData["work_resources"] = data.data;
              this.setWorkResources(true, false);
              resolve();
            })
            .catch(function (error) {
              console.log("an error occured " + error);
              reject();
            });
        // }
      });
    },
    changeDialog() {
      this.dialog = false;
      this.$nextTick(() => this.$refs["tableQuantity"].focus());
    },
    resetSelectedArticle() {
      this.article = "";
      this.unit_price = 0.0;
      this.red_percentage = 0.0;
      this.first_row_thtva = 0.0;
      this.first_row_total = 0.0;
      this.first_row_minimum_sales_price = 0.0;
      this.quantity = this.selectQuantityFromPendingArticle ? this.quantityFromPendingArticle : (this.selectedFamilyArticle ? this.selectedFamilyArticle.default_quantity : this.defaultQuantity);
      this.resetMeasurementUnit();
      this.parentGroupText = null;
      this.isEditModeForDataTableItem = false;
      this.selectedItemIndexInDataTable = null;
    },
    showDialog() {
      this.parentSearchSelectedArticleId = null;
      this.parentSearchSelectedArticleSupplierDetail = null;
      this.dialog = true;
    },
    async getTvas() {
      return new Promise((resolve, reject) => {
        this.showLoadingModel = true;
        
        axios
            .get(API_BASE_URL + "/tvas?by_auth=1", {headers: this.header})
            .finally(() => {         this.showLoadingModel = false;})
            .then(({data}) => {
              this.first_row_tva = this.fixDecimal(data.tax);
              this.cached_first_row_tva = this.fixDecimal(data.tax);
              resolve(this.first_row_thtva);
            })
            .catch((err) => {
              reject(err);
            });
      });
    },
    fetchAppointmentByID(id, updateBill = true) {
      if (id) {
        axios
            .get(API_BASE_URL + "/appointments?id=" + id, {
              headers: this.header,
            })
            .then(({data}) => {
              this.appointmentdata = data.data;
              if (data.data.order) {
                this.bills = [data.data.order];
                if (updateBill) {
                  this.selectedBill = this.bills[0];
                }
              }
              // else{
              //     this.selectedBill = null;
              // }
              this.$nextTick(() => this.$store.commit("setEditingAllowed", true));

              this.setPageLoadedVar(true);
            })
            .catch((err) => {
              console.log(err);
              this.isLoadingArticle = false;
              this.setPageLoadedVar(true);
              this.$router.push("/scheduler/add").catch(() => {
              });
            })
            .finally(
                () => {
                    this.isLoadingArticle = false;
                    this.setPageLoadedVar(true);
                    setTimeout(() => {
                        this.$nextTick(() => {
                            this.setDefaultObjectForDirtyChecking = this.makeSubmitData();
                        })
                    }, 400);
                }
            );
      } else {
        this.$router.push("/billing/add").catch(() => {
        });
      }
    },
    checkIfFamilyClientEmpty(event) {
      if (!this.parent_familyClient) {
        // this.$refs['clientComp'].$refs['family_client'].focus();
        this.$refs["clientComp"].$refs["family_client"].$touch;
        event.preventDefault();
      }
    },
    reCalculateSelectedArticlesPricing() {
      if (this.selectedArticles.length > 0) {
        let selectedIds = this.selectedArticles.map((element) => {
          if (typeof element.article == "number") {
            // saved bill case, on load
            return element.article;
          }
          return element.article.article_id;
        });

        this.isRecalculatingTableData = true;
        
        axios
            .get(
                API_BASE_URL +
                "/billings/articles/search?page=1&items_per_page=10" +
                (this.parent_familyClient
                    ? "&family_client_for_pricing=" + this.parent_familyClient
                    : "") +
                (selectedIds.length > 0 ? "&article_ids=" + selectedIds : ""),
                {headers: this.header}
            )
            .then(({data}) => {
              if (data.data.length == 0) {
                this.$toast.error(
                    this.$t(
                        "scheduler.appointment.no_pricing_found_for_this_article"
                    )
                );
              }
              this.rowNumber = 0;
              var tempSelectedArticles = [];

              // mapping current selected articles with new articles and then updating the temporaray selected articles with new ... and rest of the things will be done by the watchers
              this.selectedArticles.map((articleOldData) => {
                let newMappedArticle = data.data.find((articleNewData) => {
                  if (typeof articleOldData.article == "number") {
                    // saved bill case, on load
                    return articleNewData.id == articleOldData.article;
                  } else {
                    return articleNewData.id == articleOldData.article.article_id;
                  }
                });

                if (newMappedArticle) {
                  let tariff = parseFloat(newMappedArticle.tariff_price ?? 0);
                  // let unit_price = parseFloat(newMappedArticle.internal_company_article ? newMappedArticle.internal_company_article[0].price : 0); // test it again
                  let unit_price = parseFloat(
                      articleOldData.internal_company_article
                          ? articleOldData.unit_price
                          : 0
                  ); // test it again
                  let rounded_unit_price = parseFloat(
                      articleOldData.internal_company_article
                          ? articleOldData.rounded_unit_price
                          : 0
                  ); // test it again
                  let tva = parseFloat(
                      newMappedArticle.internal_company_article.length > 0
                          ? newMappedArticle.internal_company_article[0].tva.tax
                          : 17
                  );
                  let thtva = parseFloat(
                      unit_price * articleOldData.quantity -
                      (unit_price *
                          articleOldData.quantity *
                          articleOldData.red_percentage) /
                      100
                  );
                  let total = parseFloat(
                      parseFloat(thtva) + parseFloat((tva * thtva) / 100)
                  );
                  tempSelectedArticles.push({
                    srno: this.rowNumber++,
                    article: newMappedArticle,
                    article_original_name: newMappedArticle,
                    quantity: articleOldData.quantity,
                    unit_price: unit_price ?? 0,
                    minimum_sales_price: newMappedArticle.minimum_sales_price,
                    rounded_unit_price: rounded_unit_price ?? 0,
                    red_percentage: articleOldData.red_percentage ?? 0,
                    tarrif: tariff,
                    tva: tva,
                    stock_property: newMappedArticle.property ?? null,
                    thtva: thtva,
                    total: total,
                    family_article: newMappedArticle.family,
                    text: newMappedArticle.text,
                    row: newMappedArticle.row,
                    text_position: newMappedArticle.text_position ?? 0,
                    depot_out_id: newMappedArticle.depot_out_id,
                    depot_in_id: newMappedArticle.depot_in_id,
                    selectedmu: newMappedArticle.mu_id,
                    currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : (this.defaultBankAccount ? this.defaultBankAccount.currency_id : this.defaultCurrencyId),
                    order_counter_id: newMappedArticle.order_counter_id
                  });
                }
              });
              // this.selectedArticles = tempSelectedArticles;
            })
            .catch((err) => {
              
              this.isRecalculatingTableData = false;
            })
            .finally(() => {
                this.isRecalculatingTableData = false;
            });
      }
    },
    checkEmptinessOfForm() {
      let isBillFieldsEmpty =
          !this.order_status &&
          !this.payment_method &&
          !this.note &&
          !this.reference_client &&
          !this.red_percentage &&
          !this.next_event_code &&
          this.selectedArticles.length == 0;

      let isClientFieldsEmpty =
          !this.parent_company_client_name &&
          !this.parent_company_client_first_name &&
          !this.parent_search_client &&
          !this.parent_company_client_phone &&
          !this.parent_company_client_gsm &&
          !this.parent_company_client_email &&
          !this.parent_note &&
          !this.parent_tva &&
          !this.parent_address;

      let isVehicleFieldsEmpty =
          !this.parent_vehicle_type &&
          !this.parent_registration_no &&
          !this.parent_brand &&
          !this.parent_model &&
          !this.parent_km &&
          !this.parent_driver &&
          !this.parent_second_driver &&
          !this.parent_reg_date &&
          !this.parent_creditor &&
          !this.parent_vin;

      if (isBillFieldsEmpty && isClientFieldsEmpty && isVehicleFieldsEmpty) {
        this.$store.commit("setShowNewButton", true);
      } else {
        if (this.selectedBill) {
          this.$store.commit("setShowNewButton", true);
        } else {
          this.$store.commit("setShowNewButton", false);
        }
      }

      if (this.selectedBill) {
        this.$store.commit("setIsBillSelected", true);
        this.$store.commit("setSelectedBillData", this.selectedBill);
      } else {
        this.$store.commit("setIsBillSelected", false);
        this.$store.commit("setSelectedBillData", null);
      }

      // show save button if client is selected and at least one article is selected
      if (this.parent_company_client_name) {
        this.$store.commit("setShowSaveButton", true);
      } else {
        this.$store.commit("setShowSaveButton", false);
      }
    },
    jumpToField: function (event, ref) {
      console.log("focus on", ref);
      this.$refs[ref].focus();
    },
    checkIfMinusPressed(event) {
      if (/^[/-]$/i.test(event.key)) {
        this.unit_price =
            this.unit_price * parseInt(this.creditNoteMultiplicationValue.value);
      }
    },
    makePurchaseOrder() {
      if (this.selectedBill.bill_number) {
        
        this.$store.commit("setMakePurchaseOrderVisible", false);
        axios
            .post(
                API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
                {id: this.selectedBill.id},
                {headers: this.header}
            )
            .then((response) => {
              if (response.status === 200) {
                // this.onResetPageData();
                this.selectedBill = response.data.data;
                this.$toast.success(
                    this.$t(
                        "billings.text_messages.converted_to_purchase_order_successfully"
                    )
                );
                this.$store.commit("setMakeBillVisible", true);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", false);
              } else {
                this.$store.commit("setMakeBillVisible", false);
                this.$store.commit("setMakePurchaseOrderVisible", true);
                this.$store.commit("setMakeCreditNoteVisible", false);
                this.$toast.error(
                    this.$t(
                        "billings.text_messages.converted_to_purchase_order_failed"
                    )
                );
              }
            })
            .catch((error) => {
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", true);
              this.$store.commit("setMakeCreditNoteVisible", false);
              this.$toast.error(
                  this.$t(
                      "billings.text_messages.converted_to_purchase_order_failed"
                  )
              );
            })
            .finally(() => {
              
            });
      }
    },
    checkIfForCurrentOrderSupplierOrderNotReceived() {
      let ifAnySupplierOrderNotFullFilled = false;
      console.log("supplier order", this.selectedBill.supplier_order);
      if (
          this.selectedBill &&
          this.selectedBill.details &&
          this.selectedBill.details.length > 0
      ) {
        this.selectedBill.details.forEach((selectedBillOrderDetailItem) => {
          if (selectedBillOrderDetailItem && selectedBillOrderDetailItem.supplier_order_details_id && selectedBillOrderDetailItem.supplier_order_detail && selectedBillOrderDetailItem.supplier_order_detail.supplier_order)
            if (selectedBillOrderDetailItem.supplier_order_detail.supplier_order != 10091005) {
              // Fully Received
              ifAnySupplierOrderNotFullFilled = true;
            }
        });
      }
      return ifAnySupplierOrderNotFullFilled;
    },
    makeBill() {
      if (this.selectedBill.bill_number) {
        
        this.$store.commit("setMakeBillVisible", false);
        axios
            .post(
                API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
                {id: this.selectedBill.id},
                {headers: this.header}
            )
            .then((response) => {
              if (response.status === 200) {
                // this.onResetPageData();
                this.selectedBill = response.data.data;
                this.$toast.success(
                    this.$t(
                        "billings.text_messages.converted_to_bill_successfully"
                    )
                );
                this.$store.commit("setMakeBillVisible", false);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", true);
              } else {
                this.$store.commit("setMakeBillVisible", true);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", false);
                this.$toast.error(
                    this.$t("billings.text_messages.converted_to_bill_failed")
                );
              }
            })
            .catch((error) => {
              this.$store.commit("setMakeBillVisible", true);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", false);
              this.$toast.error(
                  this.$t("billings.text_messages.converted_to_bill_failed")
              );
            })
            .finally(() => {
              
            });
      }
    },
    makeCreditNote() {
      if (this.selectedBill.bill_number) {
        
        this.$store.commit("setMakeCreditNoteVisible", false);
        axios
            .post(
                API_BASE_URL + "/billings/convert/" + this.selectedBill.id,
                {id: this.selectedBill.id},
                {headers: this.header}
            )
            .then((response) => {
              if (response.status === 200) {
                // this.onResetPageData();
                this.selectedBill = response.data.data;
                this.$toast.success(
                    this.$t(
                        "billings.text_messages.converted_to_credit_note_successfully"
                    )
                );
                this.$store.commit("setMakeBillVisible", false);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", false);
              } else {
                this.$store.commit("setMakeBillVisible", false);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", true);
                this.$toast.error(
                    this.$t(
                        "billings.text_messages.converted_to_credit_note_failed"
                    )
                );
              }
            })
            .catch((error) => {
              this.$store.commit("setMakeBillVisible", false);
              this.$store.commit("setMakePurchaseOrderVisible", false);
              this.$store.commit("setMakeCreditNoteVisible", true);
              this.$toast.error(
                  this.$t("billings.text_messages.converted_to_credit_note_failed")
              );
            })
            .finally(() => {
              
            });
      }
    },
    printBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          const data = this.makeSubmitData();
          if (!data.appointment.id) {
            this.addSchedule(data, false).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=order&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          } else {
            this.updateSchedule(data, false, null, true).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=order&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          }
        }));
        // this.$toast.success( this.$t('billings.text_messages.credit_note_updated_successfully'));
      }
    },
    emailBill() {
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          const data = this.makeSubmitData();
          if (!data.appointment.id) {
            this.addSchedule(data, false).then(() => {
              return this.sendPdfDialog = true;
              // this.sendEmail();
            });
          } else {
            this.updateSchedule(data, false, null, true).then(() => {
              // this.sendEmail();
              return this.sendPdfDialog = true;
            });
          }
        }));
      }

      // if (this.validateSubmit()) {
      //   this.saveOrder().then(() => {
      //     const data = this.makeSubmitData();
      //     if (!data.appointment.id) {
      //       this.addSchedule(data, false).then(() => {
      //         return this.sendPdfDialog = true;
      //         // this.sendEmail();
      //       });
      //     } else {
      //       this.updateSchedule(data, false, null, true).then(() => {
      //         // this.sendEmail();
      //         return this.sendPdfDialog = true;
      //       });
      //     }
      //   });
      //
      // }
    },
    duplicateDocument(){
        // Create a new document with all the existing documents
        let tempArticlesForDuplicate = this.selectedArticles;
        this.onResetPageData();
        setTimeout(() => {
            this.selectedArticles = tempArticlesForDuplicate;
        }, 200)
    },
    worksheetBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          const data = this.makeSubmitData();
          if (!data.appointment.id) {
            this.addSchedule(data, false).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=worksheet&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          } else {
            this.updateSchedule(data, false, null, true).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=worksheet&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          }
        }));
      }
    },
    workReportBill() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          const data = this.makeSubmitData();
          if (!data.appointment.id) {
            this.addSchedule(data, false).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=workreport&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          } else {
            this.updateSchedule(data, false, null, true).then(() => {
              let url = API_BASE_URL + "/billings/" + this.selectedBill.md5_id + "/print?document=workreport&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
              let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
              this.setPreviewData(url, fileName, true, 'application/pdf');
            });
          }
        }));
      }
    },
    sendEmail() {
      
      if (this.parent_company_client_name.email) {
        axios
            .get(API_BASE_URL + "/billings/" + this.selectedBill.id + "/email", {
              headers: this.header,
            })
            .then((response) => {
              if (response.status === 200) {
                this.$toast.success(
                    this.$t("billings.text_messages.email_sent_successfully")
                );
              } else {
                this.$toast.error(
                    this.$t("billings.text_messages.failed_while_sending")
                );
              }
            })
            .catch((error) => {
              this.$toast.error(
                  this.$t("billings.text_messages.failed_while_sending")
              );
            })
            .finally(() => {
              
            });
      } else {
        this.clientEmailErrorPopUp();
      }
    },
    addBillNote() {
      this.showBillNote = true;
    },
    printReportFromTopButton() {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          const data = this.makeSubmitData();
          if (!data.bill.order_id) {
            this.addSchedule(data, false).then(() => {
              let depot_ids = new Set();
              this.selectedArticles.forEach((article) => {
                if (article.depot_in_id) {
                  depot_ids.add(article.depot_in_id);
                }
                // else if (article.depot_out_id) {
                //   depot_ids.add(article.depot_out_id);
                // }
              });
              Array.from(depot_ids).forEach((element) => {
                if (element) {
                  let url = API_BASE_URL + "/depot/" + this.encryptToMD5(element.toString()) + "/print?document=report&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
                  let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
                  this.setPreviewData(url, fileName, true, 'application/pdf');
                }
              });
            });
          } else {
            this.updateSchedule(data, false, null, true).then(() => {
              let depot_ids = new Set();
              this.selectedArticles.forEach((article) => {
                if (article.depot_in_id) {
                  depot_ids.add(article.depot_in_id);
                }
                // else if (article.depot_out_id) {
                //   depot_ids.add(article.depot_out_id);
                // }
              });
              Array.from(depot_ids).forEach((element) => {
                if (element) {
                  let url = API_BASE_URL + "/depot/" + this.encryptToMD5(element.toString()) + "/print?document=report&lang=" + (localStorage.getItem("languageId") || "en") + "&user=" + localStorage.getItem("loginUserId")
                  let fileName = this.selectedBill ? this.selectedBill?.bill_number : "";
                  this.setPreviewData(url, fileName, true, 'application/pdf');
                }
              });
            });
          }
        }));
      }
    },
    onResetPageData() {
      this.$store.commit("setFormMode", SCHEDULER_MODE);
      this.driverAddresses = [];
      this.pickup_address = null;
      this.pickup_start_datetime = null;
      this.pickup_end_datetime = null;
      this.pickup_note = null;
      this.is_pickup_appointment = false;
      this.resetted_data_table = false;
      this.resetBillType();
      this.isEditModeForDataTableItem = false;
      this.$store.commit("setValidation", false);
      this.appointmentdata = null;
      // this.$refs['clientComp'].$refs['clientName'].focus();
      this.order_status = null;
      this.payment_method = null;
      this.selectedBill = null;
      this.$store.commit("setBillCancelVisible", false);
      this.$store.commit("setEditingAllowed", false);
      this.selectedArticles = [];
      this.bill_amount = "";
      this.bills = [];
      this.rdv_date = null;
      this.due_date = null;
      this.created_date = this.currentDate;
      this.parent_reg_date = null;
      this.note = null;
      this.reference_client = null;
      this.unit_price = null;
      this.parentGroupText = null;
      this.quantity = this.selectQuantityFromPendingArticle ? this.quantityFromPendingArticle : (this.selectedFamilyArticle ? this.selectedFamilyArticle.default_quantity : this.defaultQuantity);
      this.first_row_tariff = null;
      this.first_row_thtva = null;
      this.first_row_tva = this.cached_first_row_tva; // Reset TVA to company's Default TVA
      this.first_row_total = null;
      this.first_row_minimum_sales_price = 0.0;
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.reduction_on_order = 0;
      this.isInValidUnitPrice = false;
      this.next_event_code = null;
      this.finedSelectedArticles = [];
      this.articles = [];
      this.search_article = null;

      // Vehicle Component Sync Values
      this.parent_vehicle_type = null;
      this.parent_registration_no = null;
      this.parent_brand = null;
      this.parent_driver = null;
      this.parent_second_driver = null
      this.parent_model = null;
      (this.parent_km = null), (this.parent_reg_date = null);
      this.parent_creditor = null;
      this.parent_vin = null;
      this.parent_is_leasing = null;

      // Client Component Sync Values
      this.parent_client_id = null;
      this.parent_company = null;
      this.parent_client_id = null;
      this.parent_company_client_name = null;
      this.parent_company_client_first_name = null;
      this.parent_company_client_names = [];
      this.parent_search_client = null;
      this.parent_company_client_phone = null;
      this.parent_company_client_gsm = null;
      this.parent_company_client_fax = null;
      this.parent_company_client_email = null;
      this.parent_company_client_language = null;
      this.parent_web = null;
      this.parent_currency = null;
      this.parent_representative_company_id = null;
      this.parent_user = null;
      this.parent_note = null;
      this.parent_familyClient = null;
      this.parent_denomination = null;
      this.parent_taxation_reason_id = null;
      this.parent_registration_code = null;
      this.parent_tva = null;
      this.parent_address = null;
      this.parent_shipping_address = null;
      this.parent_address_same_as = false;

      // Parent Project Name
      this.parent_project_name = null;
      this.parent_project = null;
      this.parent_project_location = null;
      this.parent_project_locations = null;
      this.parent_project_note = null;
      this.parent_project_status = null;

      this.free_text = null;
      this.appointment_is_executed = false;
      this.storedDepotIn = [];

      this.bill_note_temp = null;
      this.bill_note = null;

      this.$store.commit("setShowNewButton", true);
      this.article = null;
      this.$nextTick(() => {
        this.resetted_data_table = true;
      })
      this.setTopCurrencyBasedOnDefaultBank();
      this.pickup_datetime = null;
    },
    convertCommaToDecimalSeparator(str) {
      console.log(str, "comma seperated");
      return parseFloat((str + "").replace(",", ".").replace(" ", ""));
    },
    addDepotArticleToDataTable(depot, depotType) {
      this.resetSrNo();
      console.log("depot data", depot)
      console.error("row number", this.rowNumber);
      depot.details.forEach((depotDetail) => {
        let article_mu = '';
        this.familyArticles.forEach((element) => {
            if (depotDetail.article_id && depotDetail.article && (element.id == depotDetail.article.family_id || element.parent_family_id == depotDetail.article.family_id)) {
                console.log("depot detail article family", depotDetail.article.family_id, element.id, element.mu)
                article_mu = element.mu;
            }
        })
        if(!article_mu){
            article_mu  = this.defaultMuToSelect;
        }
        let text = "";
        text +=
            (depotType == "out"
                ? this.$t("billings.text.depot_out")
                : this.$t("billings.text.depot_in")) +
            ": " +
            (depotDetail.article_id ? depotDetail.article.title_description[0].description : depotDetail.article_name) +
            (depotDetail.position
                ? " - " +
                this.$t("billings.position.sh_" + depotDetail.position.key)
                : "") +
            (depotDetail.rimtype
                ? " - " +
                this.$t("billings.wheel_materials.sh_" + depotDetail.rimtype.key)
                : "") +
            (depotDetail.is_with_hubcaps == 1
                ? " - " + this.$t("billings.text.with_hub_cups")
                : "") +
            (depotDetail.mm
                ? " - " + this.$t("billings.text.mm") + ": " + depotDetail.mm
                : "") +
            (depotDetail.couple
                ? " - " +
                this.$t("billings.text.couple") +
                ": " +
                depotDetail.couple
                : "") +
            (depotDetail.pressure
                ? " - " +
                this.$t("billings.text.pressure") +
                ": " +
                depotDetail.pressure
                : "") +
            (depotDetail.article_status
                ? " - " +
                this.$t("billings.text.status") +
                ": " +
                this.$t(
                    "billings.article_status.sh_" + depotDetail.article_status.key
                )
                : "");

        if (depotType == "out" && depotDetail.checked) {
          this.selectedArticles.push({
            srno: this.rowNumber++,
            article: 0,
            article_original_name: null,
            quantity: 1,
            unit_price: 0.0,
            minimum_sales_price: 0.0,
            rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
            red_percentage: 0.0,
            tarrif: 0.0,
            tva: 0.0,
            thtva: 0.0,
            stock_property: null,
            total: 0.0,
            text: text,
            row: 0,
            text_position: 0,
            depot_out_id: depot.id,
            depot_in_id: null,
            dots: null,
            group: null,
            selectedmu: article_mu,
            currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : (this.defaultBankAccount ? this.defaultBankAccount.currency_id : this.defaultCurrencyId),
            order_counter_id: null
          });
        } else if (depotType == "in") {
          this.selectedArticles.push({
            srno: this.rowNumber++,
            article: 0,
            article_original_name: null,
            quantity: 1,
            unit_price: 0.0,
            minimum_sales_price: 0.0,
            rounded_unit_price: (0).toFixed(this.getDecimalNumber()),
            red_percentage: 0.0,
            tarrif: 0.0,
            tva: 0.0,
            thtva: 0.0,
            stock_property: null,
            total: 0.0,
            text: text,
            row: 0,
            text_position: 0,
            depot_out_id: null,
            depot_in_id: depot.id,
            dots: null,
            group: null,
            selectedmu: article_mu,
            currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : (this.defaultBankAccount ? this.defaultBankAccount.currency_id : this.defaultCurrencyId),
            order_counter_id: null
          });
        }
      });
    },
    addArticleToDataTable: async function (e) {
        if (e) {
            e.preventDefault();
        }
        // console.log("adding articles automatically", this.$refs, this.$refs["searchArticleComp"]);
        if (this.addArticlesAutomatically) {
            this.$refs["searchArticleComp"].saveArticle(true)
                .then(async () => {
                await this.addArticle();
                })
                .catch(async () => {
                await this.addArticle();
                })
        } else {
            await this.addArticle();
        }
    },
    triggerAddArticleToDataTable() {
        console.log("save item selected scheduler triggered");
        this.$nextTick(() => {
            // console.log("save item selected scheduler triggered inside");
            var counter = setInterval(() => {
            console.log("save item selected scheduler triggered inside ---", this.article, "sdf", this.quantity);
            if (this.article && this.quantity) {
                this.addArticle()
            }
            }, 500);

            setTimeout(() => {
            clearInterval(counter)
            }, 2000);
        })
    },
    async addArticle() {
        return new Promise((resolve, reject) => {
            console.log("article adding called", this.article, "sdf", this.quantity);
            if (!(this.article && this.quantity && this.red_percentage <= 100)) {
                resolve();
                return false;
            }
            if (this.unit_price < this.first_row_minimum_sales_price) {
                this.$toast.error(this.$t("billings.text_messages.price_cannot_be_less_then_min_sale_price"))
                resolve();
                return false;
            }
            // resetting the article id in local storage
            var articleFilters = JSON.parse(
                localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            if (articleFilters) {
                articleFilters["id"] = null;
            }
            localStorage.setItem(
                "ARTICLE_MODEL_FILTERS",
                JSON.stringify(articleFilters)
            );

            let isToMultiplyWithCredNoteMulValue = false;
            if (this.billType && this.billType.key == "credit_note") {
                isToMultiplyWithCredNoteMulValue = true;
            }

            console.log("Is Edit Mode for data table item", this.isEditModeForDataTableItem);
            if (this.isEditModeForDataTableItem) { // If article is being edited then modify that particular item quantity and other tdetails
                this.selectedArticles[this.selectedItemIndexInDataTable].quantity = this.quantity;
                this.selectedArticles[this.selectedItemIndexInDataTable].selectedmu = this.currentMu;
                this.selectedArticles[this.selectedItemIndexInDataTable].red_percentage = this.red_percentage;
                this.selectedArticles[this.selectedItemIndexInDataTable].tarrif = this.first_row_tariff;
                this.selectedArticles[this.selectedItemIndexInDataTable].supplier = this.article.supplier;
                this.selectedArticles[this.selectedItemIndexInDataTable].supplier_stock_detail = this.article.supplier_stock_detail;
                this.selectedArticles[this.selectedItemIndexInDataTable].unit_price = this.unit_price;
                this.selectedArticles[this.selectedItemIndexInDataTable].rounded_unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                ).toFixed(this.getDecimalNumber());
                this.selectedArticles[this.selectedItemIndexInDataTable].tva = parseFloat(this.first_row_tva);
                this.selectedArticles[this.selectedItemIndexInDataTable].thtva = parseFloat(this.first_row_thtva);
                this.selectedArticles[this.selectedItemIndexInDataTable].total = parseFloat(this.first_row_total);
                console.log("minimum sales price after add", this.article, this.article.internal_company_article);
                if (this.article && this.article.internal_company_article && this.article.internal_company_article.length > 0) {
                    this.first_row_minimum_sales_price = parseFloat(this.article.internal_company_article[0].minimum_sales_price ?? 0);
                    this.selectedArticles[this.selectedItemIndexInDataTable].minimum_sales_price = parseFloat(this.first_row_minimum_sales_price);
                } else {
                    this.first_row_minimum_sales_price = 0;
                    this.selectedArticles[this.selectedItemIndexInDataTable].minimum_sales_price = parseFloat(this.first_row_minimum_sales_price);
                }

                this.selectedArticles[this.selectedItemIndexInDataTable].group = this.parentGroupText ?? null;

                console.log("selected article", this.selectedArticles[this.selectedItemIndexInDataTable], this.unit_price);
                this.calculateTotals();
                // Reset Edit Mode for DataTable
                this.resetSelectedArticle();
                this.fineDataForSending();
                resolve();
            } else { // If New Article is added
                console.log("else entered")
                this.unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                );
                this.first_row_minimum_sales_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.first_row_minimum_sales_price)
                );
                this.rounded_unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                ).toFixed(this.getDecimalNumber());
                this.red_percentage = parseFloat(this.red_percentage);

                // Add Related Articles
                console.log("resetting reseted measurement unit", this.currentMu);
                this.$nextTick(() => {
                    this.resetMeasurementUnit();
                })
                this.getProposedArticles(
                    this.article.id,
                    this.article.family_id,
                    this.parent_familyClient,
                    parseFloat(this.quantity)
                ).then((wasArticleProposed) => {
                    // check if article already exists in the datatable
                    let alreadyExistsArticle = this.selectedArticles.find(
                        (element, index) => {
                        if (
                            typeof element.article == "object" &&
                            element.article.id == this.article.id &&
                            (element.group ?? "").toUpperCase() ===
                            (this.parentGroupText ?? "").toUpperCase()
                        ) {
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                        if (
                            typeof element.article == "number" &&
                            element.article == this.article.id &&
                            (element.group ?? "").toUpperCase() ===
                            (this.parentGroupText ?? "").toUpperCase()
                        ) {
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                        }
                    );
                    if (alreadyExistsArticle) {
                    // recalculate the row total
                        this.quantity =
                            parseFloat(this.quantity) + parseFloat(alreadyExistsArticle.quantity);
                        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
                        this.unit_price = parseFloat(this.unit_price);
                        console.log("article in use : add article to datatable | already existing :", this.article, this.first_row_minimum_sales_price);
                        this.first_row_minimum_sales_price = parseFloat(this.first_row_minimum_sales_price);
                        // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                        this.first_row_thtva = parseFloat(
                            this.unit_price * this.quantity -
                            (this.unit_price * this.quantity * this.red_percentage) / 100
                        );
                        this.first_row_total = parseFloat(
                            parseFloat(this.first_row_thtva) +
                            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
                        );
                    }

                    let supplierStockDetail = this.article.supplier_stock_detail;

                    if (typeof supplierStockDetail == "object") {
                        supplierStockDetail.tva = parseFloat(this.first_row_tva);
                    }
                    console.log("article in use : add article to datatable ", this.article);
                    if(this.showGroupText){
                        this.parentGroupText = this.parentGroupText ? this.parentGroupText : (this.selectedFamilyArticle ? this.selectedFamilyArticle.name : null); 
                    }
                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: this.article,
                        article_original_name: this.article,
                        quantity: this.quantity,
                        unit_price: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (this.unit_price ?? 0)
                                : this.unit_price ?? 0
                        ),
                        minimum_sales_price: this.first_row_minimum_sales_price,
                        rounded_unit_price: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (this.unit_price ?? 0)
                                : this.unit_price ?? 0
                        ).toFixed(this.getDecimalNumber()),
                        red_percentage: parseFloat(this.red_percentage ?? 0.0),
                        tarrif: this.first_row_tariff,
                        tva: parseFloat(this.first_row_tva),
                        thtva: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (this.first_row_thtva ?? 0)
                                : this.first_row_thtva ?? 0
                        ),
                        stock_property: null,
                        total: parseFloat(
                            isToMultiplyWithCredNoteMulValue
                                ? parseInt(this.creditNoteMultiplicationValue.value) *
                                (this.first_row_total ?? 0)
                                : this.first_row_total ?? 0
                        ),
                        text: null,
                        row: 0,
                        text_position: 0,
                        depot_out_id: null,
                        depot_in_id: null,
                        dots: null,
                        supplier: this.article.supplier,
                        supplier_stock_detail: supplierStockDetail,
                        group: this.parentGroupText ?? null,
                        selectedmu: this.currentMu,
                        currency_id: this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : (this.defaultBankAccount ? this.defaultBankAccount.currency_id : this.userLanguageId),
                        order_counter_id: null
                    });
                    if(wasArticleProposed){
                        this.moveLastItemOnePositionBack(this.selectedArticles)
                    }
                    console.log("was article proposed", wasArticleProposed);
                    this.resetFirstRowValues();
                    this.resetSrNo();
                    resolve();
                });
            }
        })
    },
    resetSrNo() {
      let sno = 0;
      if (this.selectedArticles && this.selectedArticles.length > 0) {
        this.selectedArticles.forEach((element) => {
          element.srno = sno;
          sno++;
        });
      }
      this.rowNumber = sno;
    },
    resetFirstRowValues() {
      this.article = "";
      this.quantity = this.selectQuantityFromPendingArticle ? this.quantityFromPendingArticle : (this.selectedFamilyArticle ? this.selectedFamilyArticle.default_quantity : this.defaultQuantity);
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
      this.first_row_minimum_sales_price = 0.0;
      this.parentGroupText = null;
    },
    deleteItem(item) {
      this.selectedArticles.find((element, index) => {
        if (element.srno == item.srno) {
          this.selectedArticles.splice(index, 1);
          this.resetSrNo();
          let indexInStore = this.$store.state.selectedBillData.details.findIndex( i => i.article_id === item.article);
          this.$store.state.selectedBillData.details.splice(indexInStore, 1);
          return true;
        }
      });
    },
    resetMeasurementUnit() {
      this.currentmu = this.selectedFamilyArticle.mu;
      console.log("resetting measurement unit", this.currentmu);
    },
    onDelete() {
      if (this.appointmentdata.id) {
        this.deletePopUp();
      }
    },
    makeSupplierOrderData(item, supplier_stock_detail) {
      console.log("making supplier order", item, typeof item, this.article);
      if (typeof item == "number") {
        return {
          quantity_to_order: 0,
          price: this.article.internal_company_article[0].price,
          unit_client_price: this.article.internal_company_article[0].price,
          delivery_date: this.article.internal_company_article[0].delivery_date,
          total_price: this.article.internal_company_article[0].price,
          tva: this.article.internal_company_article[0].tva.tax,
          price_with_tva: this.article.internal_company_article[0].price + (this.article.internal_company_article[0].price * this.article.internal_company_article[0].tva.tax) / 100,
          client: "",
          dispo: 0,
          new_dispo: 0,
          current_stock_price: 0,
          new_stock_price: 0,
          article_id: this.article.id,
          supplier_id: this.article.supplier[0].id,
          message_for_order: "",
          order_reference: "",
        };
      }
      console.log("Supplier Order Data", item, supplier_stock_detail, this.article);
      return {
        quantity_to_order: 0,
        price: item[`${supplier_stock_detail.field_price}`],
        unit_client_price: item[`${supplier_stock_detail.field_client_price}`],
        delivery_date: item[`${supplier_stock_detail.field_delivery_date}`],
        total_price: 0,
        tva: item.tax,
        price_with_tva: 0,
        client: "",
        dispo: item.stock - item.reserve,
        new_dispo: 0,
        current_stock_price: item.price,
        new_stock_price: item.price,
        article_id: item.article_id,
        supplier_id:
            supplier_stock_detail.supplier_detail.id == 0
                ? item.brand_supplier_id
                : supplier_stock_detail.supplier_detail.id,
        message_for_order: "",
        order_reference: "",
      };
    },
    makeClientData() {
      // this.parent_company_client_language = this.parent_company_client_name ? (typeof this.parent_company_client_name  == "object" ? this.parent_company_client_name.language_id : null) : null;

      return {
        client_id: this.parent_company_client_name
            ? typeof this.parent_company_client_name == "object"
                ? this.parent_company_client_name.id
                : null
            : null,
        entityType: this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
        denomination: this.parent_denomination ? (typeof this.parent_denomination == 'object' ? this.parent_denomination.id : this.parent_denomination) : null,
        taxation_reason_id: this.parent_taxation_reason_id ? (typeof this.parent_taxation_reason_id == 'object' ? this.parent_taxation_reason_id.id : this.parent_taxation_reason_id) : null,
        familyClient: this.parent_familyClient
            ? typeof this.parent_familyClient == "object"
                ? parseInt(this.parent_familyClient.id)
                : this.parent_familyClient
            : null,

        name: this.parent_company_client_name
            ? typeof this.parent_company_client_name == "object"
                ? this.parent_company_client_name.name
                : this.parent_company_client_name
            : null,
        first_name: this.parent_company_client_first_name
            ? typeof this.parent_company_client_first_name == "object"
                ? this.parent_company_client_first_name.name
                : this.parent_company_client_first_name
            : null,
        email: this.parent_company_client_email ?? null,
        language: this.parent_company_client_language
            ? typeof this.parent_company_client_language == "object"
                ? this.parent_company_client_language.id
                : this.parent_company_client_language
            : null,
        fax: this.parent_company_client_fax ?? null,
        gsm: this.parent_company_client_gsm ?? null,
        phone: this.parent_company_client_phone ?? null,
        note: this.getNoteValue(),
        tva: this.parent_tva ?? null,
        web: this.parent_web ?? null,
      };
    },
    makeVehicleData() {
      return {
        vehicle_id:
            this.parent_registration_no &&
            typeof this.parent_registration_no == "object"
                ? this.parent_registration_no.id
                : null,
        vehicle_type_id:
            this.parent_vehicle_type &&
            typeof this.parent_vehicle_type == "object"
                ? this.parent_vehicle_type.id
                : null,
        registration_no:
            this.parent_registration_no &&
            typeof this.parent_registration_no == "object"
                ? this.parent_registration_no.registration_number
                : this.parent_registration_no,
        brand: this.parent_brand
            ? typeof this.parent_brand == "number"
                ? this.parent_brand
                : typeof this.parent_brand == "object"
                    ? this.parent_brand.id
                    : null
            : null,
        model: this.parent_model ?? null,
        km: this.parent_km ?? null,
        reg_date: this.parent_reg_date ?? null,
        driver: this.parent_driver ? this.parent_driver : null,
        second_driver: this.parent_second_driver ? this.parent_second_driver : null,
        vin: this.parent_vin ?? null,
        is_leasing: this.parent_creditor ? true : false,
        creditor_id: this.parent_creditor
            ? typeof this.parent_creditor == "object"
                ? this.parent_creditor.id
                : this.parent_creditor
            : null,
      };
    },
    onConfirmationDeleteAppointment() {
      
      axios
          .delete(API_BASE_URL + "/appointments/" + this.appointmentdata.id, {
            headers: this.header,
          })
          .then(({data}) => {
            this.onResetPageData();
            this.$toast.success(
                this.$t("scheduler.appointment.deleted_successfully")
            );
            this.$store.commit("setRefreshList", true);
            this.$router.push("/scheduler").catch(() => {
            });
          })
          .catch((error) => {
            this.$toast.error(this.$t("scheduler.appointment.delete_failed"));
          })
          .finally(() => {
            
          });
    },
    makeSubmitData() {
      this.fineDataForSending();
      let topBankAccountSelected = this.$store.state.topBankAccount;
      return {
        type: this.billType ? this.billType.id : null,
        bank_id: topBankAccountSelected ? topBankAccountSelected.id : (this.defaultBankAccount ? this.defaultBankAccount.id : null),
        bill: !this.selectedBill
            ? {}
            : {
              order_id: this.selectedBill.bill_number
                  ? this.selectedBill.id
                  : null,
              client_id: this.selectedBill ? this.selectedBill.client_id : null,
              company_id: this.selectedBill
                  ? this.selectedBill.company_id
                  : null,
              vehicle_id: this.selectedBill
                  ? this.selectedBill.vehicle_id
                  : null,
            },
        reduction_on_order: this.reduction_on_order ? this.reduction_on_order : null, 
        next_event_code: this.next_event_code ? this.next_event_code.id : null,
        status: this.order_status ? this.order_status.id : null,
        payment_method: this.payment_method ? this.payment_method.id : null,
        rdv_date: this.rdv_date,
        due_date: this.due_date,
        created_date: this.created_date,
        reference_client: this.reference_client,
        note: this.bill_note ?? null,
        client: {
          client_id:
              typeof this.parent_company_client_name == "object"
                  ? this.parent_company_client_name.id
                  : null,
          entityType: this.parent_entityType ? this.parent_entityType.id : 0, // By Default Private Client
          denomination: this.parent_denomination ? (typeof this.parent_denomination == 'object' ? this.parent_denomination.id : this.parent_denomination) : null,
          taxation_reason_id: this.parent_taxation_reason_id ? (typeof this.parent_taxation_reason_id == 'object' ? this.parent_taxation_reason_id.id : this.parent_taxation_reason_id) : null,
          familyClient: this.parent_familyClient
              ? typeof this.parent_familyClient == "object"
                  ? parseInt(this.parent_familyClient.id)
                  : this.parent_familyClient
              : null,

          name:
              typeof this.parent_company_client_name == "object"
                  ? this.parent_company_client_name.name
                  : this.parent_company_client_name,
          first_name: this.parent_company_client_first_name
              ? typeof this.parent_company_client_first_name == "object"
                  ? this.parent_company_client_first_name.first_name
                  : this.parent_company_client_first_name
              : null,
          language: this.parent_company_client_language
              ? typeof this.parent_company_client_language == "object"
                  ? this.parent_company_client_language.id
                  : this.parent_company_client_language
              : null,
          email: this.parent_company_client_email ?? null,
          fax: this.parent_company_client_fax ?? null,
          gsm: this.parent_company_client_gsm ?? null,
          phone: this.parent_company_client_phone ?? null,
          note: this.getNoteValue(),
          tva: this.parent_tva ?? null,
          web: this.parent_web ?? null,
          notification_type_id: this.notification_type
              ? this.notification_type.id
              : null,
        },
        billing_address: this.parent_address,
        shipping_address: this.parent_shipping_address,
        client_projects: {
          project_id: this.parent_project
              ? typeof this.parent_project == "object"
                  ? parseInt(this.parent_project.id)
                  : null
              : null,
          parent_project_name: this.parent_project_name
              ? typeof this.parent_project_name == "object"
                  ? parseInt(this.parent_project_name.name)
                  : this.parent_project_name
              : null,
          parent_project_location: this.parent_project_location
              ? typeof this.parent_project_location == "object"
                  ? parseInt(this.parent_project_location.id)
                  : this.parent_project_location
              : null,
          parent_project_note: this.parent_project_note
              ? typeof this.parent_project_note == "object"
                  ? parseInt(this.parent_project_note.id)
                  : this.parent_project_note
              : null,
          parent_project_status: this.parent_project_status
              ? typeof this.parent_project_status == "object"
                  ? this.parent_project_status.id
                  : this.parent_project_status
              : null,
        },
        order_reminder_type: this.order_reminder_type ? this.order_reminder_type.id : null,
        vehicle: this.makeVehicleData(),
        article: this.finedSelectedArticles,
        appointment: {
          id: this.appointmentdata ? this.appointmentdata.id : null,
          start_time: this.makeStartTimeFromDateTime(),
          end_time: this.makeEndTimeFromDateTime(),
          work_type_id: this.work_type.id,
          color: this.color ? this.color.replace("#", "") : null,
          work_resource_id: this.work_resource.id,
          note: this.free_text ?? null,
          is_full_day: this.is_full_day,
          pickup_address: this.pickup_address ? this.makePickupAddress() : null,
          appointment_is_executed: this.checkIfPastDateAppointment()
              ? null
              : this.appointment_is_executed
                  ? 1
                  : 0, // If past date appointment then dont send appointment_is_executed to the backend
        },
        depot_ins: this.storedDepotIn,
      };
    },
    makePickupAddress(){
        return {
            address:        this.pickup_address,
            start_datetime: this.pickup_start_datetime ? this.date + ' ' + this.pickup_start_datetime : '',
            end_datetime:   this.pickup_end_datetime   ? this.date + ' ' + this.pickup_end_datetime : '',
            note:           this.pickup_note
        }
    },
    validateSubmit() {
      // let quantityValidation = false;
      // this.selectedArticles.forEach((element) => {
      //   if(element.article.stock < element.quantity){
      //       quantityValidation = true;
      //   }
      // })

      if (!this.billType || !this.parent_company_client_name) {
        !this.billType
            ? this.$toast.error(
                this.$t("scheduler.text_messages.please_select_bill_type")
            )
            : "";
        !this.parent_company_client_name
            ? this.$toast.error(
                this.$t("scheduler.text_messages.please_select_client_name")
            )
            : "";
        return false;
      }
      if (
          this.selectedBill &&
          (this.selectedBill.bill_number ? this.selectedBill.id : null) &&
          this.selectedArticles.length <= 0 &&
          this.selectedBill.type.key == "bill"
      ) {
        this.selectedArticles.length <= 0
            ? this.$toast.error(
                this.$t(
                    "billings.text_messages.please_select_at_least_one_article"
                )
            )
            : "";
        return false;
      }
      // if (quantityValidation || this.$v.invalid ||  this.$refs['clientComp'].$v.invalid) {
      //   quantityValidation ? this.$toast.warning("Please verify the stock of the articles selected!")  : '';
      //   this.$v.invalid || this.$refs['clientComp'].$v.invalid ? this.$toast.warning(this.$t('please_fill_form_correctly'))  : '';

      // }
      let anyArticleUnitPriceLessThenMin = false

      if (anyArticleUnitPriceLessThenMin) {
        return false;
      }
      
    //   console.log("driver comp----",this.$refs["vehicleComp"].$v.$invalid  && this.$refs["vehicleComp"].$refs["drivercomp"].$v.$invalid );
      if ( this.$v.$invalid || (this.checkTag("clients") ? this.$refs["clientComp"].$v.$invalid : false) ||
            (this.checkTag("vehicles") && this.isVehicleModuleEnabled ? (this.$refs["vehicleComp"].$v.$invalid || (this.$refs["vehicleComp"].$refs["drivercomp"] && this.$refs["vehicleComp"].$refs["drivercomp"].$v.$invalid)) : false)
      ) {
        this.$v.$invalid || (this.checkTag("clients") ? this.$refs["clientComp"].$v.$invalid : false) ||
        (this.checkTag("vehicles") && this.isVehicleModuleEnabled ? (this.$refs["vehicleComp"].$v.$invalid  || (this.$refs["vehicleComp"].$refs["drivercomp"] && this.$refs["vehicleComp"].$refs["drivercomp"].$v.$invalid)) : false)
            ? this.$toast.warning(this.$t("please_fill_form_correctly"))
            : "";
        return false;
      }
      return true;
    },
    resetAppointmentData(appointmentId, moveToEditPage = false){
        console.log("appointment data--***", appointmentId);
        this.$store.commit("setRemount", false);
        this.$store.commit("setAppointmentRedirectData", {
            id: appointmentId,
        });
        if(moveToEditPage){
            this.$router.push("/scheduler/edit");
        }
        setTimeout(() => {
            this.$nextTick(() => {
                this.$store.commit("setRemount", true);
            })
        }, 50)
    },
    submit(withoutClose = false) {
      this.$store.commit("setValidation", true); // enable validation
      if (this.validateSubmit()) {
        this.saveOrder().then(this.updateGroupText().then(() => {
          let data = this.makeSubmitData();
          console.log("debugging supplier order", data);
          console.log("validating submit", data);
          if (!data.appointment.id) {
            if(this.parent_company_client_name && typeof this.parent_company_client_name === 'object'){
              let clientStatus = this.clientStatuses.find( i => i.id === this.parent_company_client_name.status_id)
              if(clientStatus && clientStatus.key === 'client_blocked'){
                return this.$toast.error(this.$t('billings.text_messages.client_blocked'))
              }
            }

            this.addSchedule(data, true).then((appointmentData) => {
                let appointment = appointmentData.data.data;
                this.onResetPageData();
                this.$store.commit("setRefreshList", true);
                if(!withoutClose){
                    this.$router.push("/scheduler").catch(() => {
                    });
                }
                else{
                    console.log("appointment data ---", appointment);
                    this.resetAppointmentData(appointment.id, true);
                }
            });
          } else {
            this.updateSchedule(data, true).then(() => {
                this.onResetPageData();
                this.$store.commit("setRefreshList", true);
                    if(!withoutClose){
                        this.$router.push("/scheduler").catch(() => {
                        });
                    }
                    else{
                        this.resetAppointmentData(data.appointment.id);
                    }
            });
          }
        }));
      }
    },
    async getPaymentMethods() {
      return new Promise((resolve, reject) => {
        this.getCodes('payment_types', 'billings.payment_methods')
            .then((data) => {
              this.payment_methods = data;
            });
      });
    },
    async getCreditors() {
      return new Promise((resolve, reject) => {
        
        axios
            .get(API_BASE_URL + "/creditors", {headers: this.header})
            .finally(() => {})
            .then(({data}) => {
              this.creditors = data.data.map((element) => {
                if (element.creditor_company) {
                  element.creditor_company["is_leaser"] = element.is_leaser;
                  return element.creditor_company;
                }
              });

              resolve(this.creditors);
            })
            .catch(function (error) {
              reject(error);
            });
      });
    },
    async getOrderStatuses() {
      await this.getCodes('order_status', 'billings.statuses')
          .then((data) => {
            this.allStatuses = data;
          });
    },
    getNotificationTypes() {
      return new Promise((resolve, reject) => {
        this.getCodes('client_notification_types', 'scheduler.notification_types')
            .then((data) => {
              this.notification_types = data;
              resolve(this.notification_types);
            });
      });
    },
    filterOutStatuses() {
        let filteredStatuses = [];
        if (this.billType) {
            switch (this.billType.key) {
            case "estimation":
                filteredStatuses = this.filterAndSortStatuses(this.statusFilters.estimation);
                break;
            case "purchase_order":
                filteredStatuses = this.filterAndSortStatuses(this.statusFilters.purchase_order);
                break;
            case "bill":
                filteredStatuses = this.filterAndSortStatuses(this.statusFilters.bill);
                break;
            case "credit_note":
                filteredStatuses = this.filterAndSortStatuses(this.statusFilters.credit_note);
                break;
            default:
                filteredStatuses = this.allStatuses;
            }
        } else {
            filteredStatuses = this.allStatuses;
        }
        this.billstatuses = filteredStatuses;
    },
    filterAndSortStatuses(statusFilter) {
        return this.allStatuses
            .filter((element) => statusFilter.includes(element.key))
            .sort((a, b) => statusFilter.indexOf(a.key) - statusFilter.indexOf(b.key));
    },
    setCurrentFormOperation() {
      this.operation = this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
    },
    setBillType() {
      console.log("setting bill type", this.billTypes);
      this.billType = this.selectDefaultBillType(this.billTypes);
      // this.billTypes.forEach((element, index) => {
      //   if (element.key == "purchase_order") {
      //     // setting bill type with bill
      //     this.billType = element;
      //   }
      // });
    },
    addSchedule(data, showMessage = true) {
      return new Promise((resolve, reject) => {
        
        axios
            .post(API_BASE_URL + "/appointments", data, {headers: this.header})
            .then((response) => {
              this.selectedBill = response.data.data;
              this.$store.state.appointmentUpdated = response.data.data
              // Make order to Supplier on success of bill creation
              data.article.forEach((finedArticlesForMakingStockOrder) => {

                if (
                    finedArticlesForMakingStockOrder.supplier_stock_detail &&
                    finedArticlesForMakingStockOrder.supplier_stock_detail
                        .article_id
                ) {
                  response.data.data.order.details.forEach((detailArticle) => {
                    if (finedArticlesForMakingStockOrder.supplier_stock_detail.article_id == detailArticle.article_id) {
                      if (detailArticle.supplier_order_detail && detailArticle.supplier_order_detail.supplier_order) {
                        finedArticlesForMakingStockOrder.supplier_stock_detail.order_id = response.data.data.order_id;
                        this.makeOrderToSupplier(
                            detailArticle.supplier_order_detail.supplier_order.id,
                            finedArticlesForMakingStockOrder.supplier_stock_detail
                        );
                      }
                    }
                  });


                }
              });
              if (showMessage) {
                this.$toast.success(
                    this.$t("scheduler.text_messages.schedule_added")
                );

                this.$store.state.selectedArticlePendingAppointment = null;
              }
              resolve(response);
              // this.onResetPageData();
            })
            .catch((error) => {
              console.log("add schedule error", error, error.response);
              if (error.response.data.errors[0]) {
                this.$toast.error(
                    this.$t(
                        "scheduler.text_messages." + error.response.data.errors[0]
                    )
                );
              } else {
                this.$toast.error(
                    this.$t("scheduler.text_messages.something_went_wrong")
                );
              }
              reject();
            })
            .finally(() => {
              
            });
      });
    },
    updateSchedule(data, showMessage = true, customData = null, checkDirty = false) {
        if(checkDirty && !this.isDirty()){
            console.log("dirty");
            return new Promise((resolve, reject) => {
                resolve();
            })
        }
        // Add Notification Type Id to appointment object
        data.appointment.notification_type_id = this.notification_type
            ? this.notification_type.id
            : null;
        // let newdata = data.appointment;
        // newdata.client = data.client;
        // newdata.vehicle = data.vehicle;
        return new Promise((resolve, reject) => {
            
            axios
                .put(
                    API_BASE_URL + "/appointments/" + data.appointment.id,
                    customData ? customData : data,
                    {headers: this.header}
                )
                .then((response) => {
                this.selectedBill = response.data.data.order;
                this.$store.state.appointmentUpdated = response.data.data
                if (response.status === 200) {
                    // Make order to Supplier on success of bill creation
                    data.article.forEach((finedArticlesForMakingStockOrder) => {

                    if (
                        finedArticlesForMakingStockOrder.supplier_stock_detail &&
                        finedArticlesForMakingStockOrder.supplier_stock_detail
                            .article_id
                    ) {
                        response.data.data.order.details.forEach((detailArticle) => {
                        if (finedArticlesForMakingStockOrder.supplier_stock_detail.article_id == detailArticle.article_id) {
                            if (detailArticle.supplier_order_detail && detailArticle.supplier_order_detail.supplier_order) {
                            finedArticlesForMakingStockOrder.supplier_stock_detail.order_id = response.data.data.order_id;
                            this.makeOrderToSupplier(
                                detailArticle.supplier_order_detail.supplier_order.id,
                                finedArticlesForMakingStockOrder.supplier_stock_detail
                            );
                            }
                        }
                        });

                        // this.submitOrderDetails(
                        //   finedArticlesForMakingStockOrder.supplier_stock_detail
                        // );
                    }
                    });
                    if (showMessage) {
                    this.$toast.success(
                        this.$t("scheduler.text_messages.schedule_updated")
                    );
                    this.$store.state.selectedArticlePendingAppointment = null;
                    }
                    resolve();
                    // this.onResetPageData();
                } else {
                    this.$toast.error(response);
                }
                })
                .catch((error) => {
                if (error.response.data.errors[0]) {
                    this.$toast.error(
                        this.$t(
                            "scheduler.text_messages." + error.response.data.errors[0]
                        )
                    );
                } else {
                    this.$toast.error(
                        this.$t("scheduler.text_messages.something_went_wrong")
                    );
                }
                reject();
                })
                .finally(() => {
                
                });
        });
    },
    updatePagination(page) {
      // this.page = page;
      // this.fetchData(page);
    },
    updateItemsPerPage(itemsPerPage) {
      // console.log("----------",itemsPerPage == -1 ? this.totalItems : itemsPerPage);
      // this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
      // this.fetchData();
      // this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
    },
    clearArticleList() {
      this.articles = [];
    },
    fetchArticleListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchArticlesList();
      }, 500); /* 500ms throttle */
    },
    fetchArticlesList() {
      if (!this.search || typeof this.search == "object") {
        return;
      }
      this.clearArticleList();
      axios
          .get(
              API_BASE_URL +
              "/billings/articles/search?page=1&items_per_page=10" +
              (this.parent_familyClient
                  ? "&family_client_for_pricing=" + this.parent_familyClient
                  : "") +
              (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
              (this.parentInternalStockOnly ? "&internal_stock=true" : ""),
              {headers: this.header}
          )
          .then(({data}) => {
            if (data.data.length == 0) {
              this.$toast.error(
                  this.$t(
                      "scheduler.appointment.no_pricing_found_for_this_article"
                  )
              );
            }
            this.articles = data.data;
          })
          .catch((err) => {
            console.log(err);
            this.isLoadingArticle = false;
          })
          .finally(() => (this.isLoadingArticle = false));
    },
    fetchBillSuggesListDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchBillSuggesList();
      }, 500); /* 500ms throttle */
    },
    fetchBillSuggesList() {
      if (!this.search_bill || typeof this.search_bill == "object") {
        this.isLoadingSearchRegiClient = false;
        return;
      }
      let status_ids = ""
      this.allStatuses.forEach((element) => {
        if(!(element.key == 'canceled' || element.key == 'closed' || element.key == 'rejected')){ // We dont want to show the cancelled bills here
            status_ids += element.id + ",";
        }
      })
      // this.clearArticleList()
      axios
          .get(
              API_BASE_URL +
              "/billings/search?page=1&items_per_page=20" +
              (this.search_bill ? "&search=" + encodeURIComponent(this.search_bill) : "") +
              (status_ids ? "&status_ids=" + status_ids.slice(0, -1) : ""),
              {headers: this.header}
          )
          .then(({data}) => {
            this.bills = data.data;
          })
          .catch((err) => {
            console.log(err);
            this.isLoadingSearchRegiClient = false;
          })
          .finally(() => (this.isLoadingSearchRegiClient = false));
    },
    calculateFields(UpdateQuantity, updateUnitPrice, updateTHTVA, updateTotal) {
      // this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
      // this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
      // this.unit_price = "";
      if (UpdateQuantity) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateUnitPrice) {
        // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
      }
      if (updateTHTVA) {
        this.first_row_thtva = parseFloat(
            this.unit_price * this.quantity -
            (this.unit_price * this.quantity * this.red_percentage) / 100
        );
      }
      if (updateTotal) {
        this.first_row_total = parseFloat(
            parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
      }
    },
    calculateColumns(changeUnitPrice) {
      console.log("calculating columns");
      if (this.isEditModeForDataTableItem) { // do not update column values if an article is in edit mode
        // ------------ Update Price on Supplier Change ----------
        // if(this.article && this.article.internal_company_article){
        //   this.unit_price = parseFloat(
        //     changeUnitPrice
        //       ? this.unit_price
        //       : this.article.internal_company_article
        //       ? this.article.internal_company_article.length > 0
        //         ? this.article.internal_company_article[0].price
        //         : 0
        //       : 0.0
        //   );
        // }
        // else{

        // }

        console.log("changing unit price ----", changeUnitPrice, this.unit_price, this.article);
        // ------------ Updated Price on Supplier Change ----------
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        if (this.article && this.article.internal_company_article && this.article.internal_company_article.length > 0) {
          this.first_row_minimum_sales_price = parseFloat(this.article.internal_company_article[0].minimum_sales_price ?? 0);
        } else {
          this.first_row_minimum_sales_price = this.selectedArticles[this.selectedItemIndexInDataTable]?.minimum_sales_price;
        }
        this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
            this.getDecimalNumber()
        ),
            // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
            this.first_row_thtva = parseFloat(
                this.unit_price * this.quantity -
                (this.unit_price * this.quantity * this.red_percentage) / 100
            );
        this.first_row_total = parseFloat(
            parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
        this.calculateTotals();
        console.log("calculating columns end", this.currentMu);
        return
      }
      if (this.article) {
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        console.log(this.unit_price, "dot sep");
        this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
        if (this.article && this.article.internal_company_article && this.article.internal_company_article.length > 0) {
          this.first_row_minimum_sales_price = parseFloat(this.article.internal_company_article[0].minimum_sales_price ?? 0);
        } else {
          this.first_row_minimum_sales_price = this.selectedArticles[this.selectedItemIndexInDataTable]?.minimum_sales_price;
        }
        this.unit_price = parseFloat(
            changeUnitPrice
                ? this.unit_price
                : this.article.internal_company_article
                    ? this.article.internal_company_article.length > 0
                        ? this.article.internal_company_article[0].price
                        : 0
                    : 0.0
        );
        this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
            this.getDecimalNumber()
        ),
            // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
            this.first_row_thtva = parseFloat(
                this.unit_price * this.quantity -
                (this.unit_price * this.quantity * this.red_percentage) / 100
            );
        this.first_row_total = parseFloat(
            parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
      } else {
        this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
        (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
            this.getDecimalNumber()
        )),
            // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
            (this.first_row_thtva = parseFloat(
                this.unit_price * this.quantity -
                (this.unit_price * this.quantity * this.red_percentage) / 100
            ));
        this.first_row_total = parseFloat(
            parseFloat(this.first_row_thtva) +
            parseFloat((this.first_row_tva * this.first_row_thtva) / 100)
        );
        this.first_row_minimum_sales_price = parseFloat(this.first_row_minimum_sales_price);
      }
    },
    calculateTotals() {
      let thtva_total = 0;
      let total_total = 0;
      this.selectedArticles.forEach((element) => {
        thtva_total += parseFloat(element.thtva);
        total_total += parseFloat(element.total);
      });
      this.articleTHTVATotalBeforeReduction = parseFloat(thtva_total);
      this.articleTHTVATotal = this.reduction_on_order ? (parseFloat(this.articleTHTVATotalBeforeReduction  - (this.articleTHTVATotalBeforeReduction * this.reduction_on_order / 100))) : this.articleTHTVATotalBeforeReduction;
      this.articleTotalBeforeReduction = parseFloat(total_total);
      this.articleTotal = this.reduction_on_order ? (parseFloat(this.articleTotalBeforeReduction - (this.articleTotalBeforeReduction * this.reduction_on_order / 100))) : this.articleTotalBeforeReduction;
      this.total_reduction = parseFloat((this.articleTotalBeforeReduction ?? 0) * ((this.reduction_on_order ?? 0) /100)).toFixed(this.getDecimalNumber());
    },
    fineDataForSending() {
      this.finedSelectedArticles = [];
      this.selectedArticles.forEach((element) => {
        console.log("Fined**", element);
        if (
            element.supplier_stock_detail &&
            element.supplier_stock_detail.article_id
        ) {
          element.supplier_stock_detail.quantity_to_order = element.quantity; // Update Stock Order Quantity with selected article quantity
          element.supplier_stock_detail.client = {
            id: this.vehicleDriverDetails.client.client_id,
            name: this.vehicleDriverDetails.client.name,
          };
          console.log("Fined", element);
          if (element.supplier && element.supplier.length > 0) {
            console.log("Fined------", element.supplier[0]);
            element.supplier_stock_detail.supplier_id = element.supplier[0].id;
            element.supplier_id = element.supplier[0].id;
          }
        }
        // else{

        // }
        console.log("Fined selected articles", element.supplier_stock_detail);
        this.finedSelectedArticles.push({
          article_id:
              element.depot_in_id || element.depot_out_id
                  ? 0
                  : typeof element.article == "object"
                      ? element.article.id
                      : element.article, // If element has text property then we will send the article id as 0
          // company_id: element.article.company_id,
          // supplier_id: element.article.supplier_id,
          quantity: element.quantity,
          red_percentage: parseFloat(element.red_percentage ?? 0),
          tarrif: parseFloat(element.tarrif),
          thtva: parseFloat(element.thtva),
          total: parseFloat(element.total),
          tva: parseFloat(element.tva),
          stock_property: element.stock_property
              ? typeof element.stock_property == "object"
                  ? element.stock_property.property
                  : element.stock_property
              : null,
          unit_price: parseFloat(element.unit_price),
          minimum_sales_price: parseFloat(element.minimum_sales_price),
          rounded_unit_price: parseFloat(element.rounded_unit_price),
          text: element.text,
          row: element.row,
          text_position: element.text_position ?? 0,
          depot_out_id: element.depot_out_id,
          depot_in_id: element.depot_in_id,
          supplier_id:
              element.supplier &&
              element.supplier.length > 0 &&
              element.supplier[0] &&
              element.supplier[0].id
                  ? element.supplier[0].id
                  : null,
          supplier_stock_detail: element.supplier_stock_detail,
          group: element.group ?? null,
          selectedmu: element.selectedmu.id,
          currency_id: element.currency_id,
          order_counter_id: element.order_counter_id
        });
      });
    },
    fetchClientBalance(client_id) {
      
      axios
          .get(
              API_BASE_URL +
              "/clients/" +
              client_id +
              "/pending/bill?return_total=1",
              {headers: this.header}
          )
          .then(({data}) => {
            this.customer_balance = this.fixDecimal(data.data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            
          });
    },
    setDefaultFamilyArticle() {
      let defaultFamilyArticle = null;
      this.familyArticles.forEach((element) => {
        if (element.is_default) {
          defaultFamilyArticle = element;
        }
      });
      if (!defaultFamilyArticle) {
        this.familyArticle = this.familyArticles[0];
      } else {
        this.familyArticle = defaultFamilyArticle;
      }
    },
    updateToTime() {
      let datetime = new Date(this.datetime);
      let newdatetime = new Date(
          datetime.getTime() +
          (this.work_type !== null ? this.work_type.work_duration ?? 0 : 0) * 60000
      );
      let newtime =
          ("0" + newdatetime.getHours()).slice(-2) +
          ":" +
          ("0" + newdatetime.getMinutes()).slice(-2);
      this.time = newtime;
    },
    setStartTime() {
      let datetime = new Date(this.datetime);
      let newdatetime = new Date(datetime.getTime());
      this.starttime =
          ("0" + newdatetime.getHours()).slice(-2) +
          ":" +
          ("0" + newdatetime.getMinutes()).slice(-2);
    },
    makeEndTimeFromDateTime() {
      if (this.is_full_day) {

        return (this.toDate ?? this.fromDate) + " " + this.operationalTimes?.maxTime;
      }

      return this.date + " " + this.toTime;
    },
    makeStartTimeFromDateTime() {
      if (this.is_full_day) {
        return this.fromDate + " " + this.operationalTimes?.minTime;
      }

      return this.date + " " + this.fromTime;
    },
    getArticleDots(selectedArticleIndex, article_id, supplier_id) {
      console.log("=SelectedArticle Index", selectedArticleIndex);
      console.log("=SelectedArticle article id", article_id);
      console.log("=SelectedArticle article id", article_id);
      console.log("=SelectedArticle supplier id", supplier_id);
      if (typeof article_id == "object") {
        article_id = article_id.id;
      }
      if (supplier_id == 0) {
        // if Article added is of another supplier then we dont have to check for dot properties as those order will be directly made to the supplier
        axios
            .get(
                API_BASE_URL +
                "/stock_property/" +
                article_id +
                "?property_class_id=dot_property" +
                "&supplier_id=" +
                supplier_id,
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.AccessToken,
                  },
                }
            )
            .then((response) => {
              console.log(
                  response.data.data,
                  "selecte article dot items",
                  response.data.data
              );
              this.selectedArticles[selectedArticleIndex].dots =
                  response.data.data ?? [];
              if (!this.selectedArticles[selectedArticleIndex].stock_property) {
                this.selectedArticles[
                    selectedArticleIndex
                    ].stock_property = response.data.data
                    ? response.data.data[0]
                    : null;
              }
            })
            .catch((error) => {
              console.log("an error occured " + error);
              this.selectedArticles[selectedArticleIndex].dots = [];
            })
            .finally(() => {
            });
      } else {
        this.selectedArticles[selectedArticleIndex].dots = [];
      }
    },
    setWorkType() {
      if (this.stored_work_types.length > 0 && this.work_resource) {
        this.work_types = this.stored_work_types.filter((element) => {
          if (element.resource_work_types) {
            return element.resource_work_types.some((elementobject) => {
              return (
                  elementobject["work_resource_id"] === this.work_resource.id
              );
            });
          }
          return false;
        });
        if(this.appointmentdata && this.appointmentdata.work_type_id && this.appointmentdata.work_resource_id == this.work_resource.id){
            console.log("thisworktype -- check value", this.appointmentdata.work_type);
        }
        else{
            this.work_type = this.work_types[0];
            if (!this.toTime && this.fromTime) {
                this.toTime = moment(this.fromTime, "HH:mm").add(this.work_type.work_duration, "minutes").format("HH:mm");
            }
        }
      }
    },
    fixBillCurrencySymbol() {
      if (this.selectedArticles.length > 0) {
        this.selectedArticles.forEach((element) => {
          element.currency_id = this.$store.state.topCurrencyType ? this.$store.state.topCurrencyType.id : this.defaultCurrencyId;
        });
        this.fineDataForSending();
      }
    },
    deleteSupplierOrder() {
      
      console.log("supplier order", this.lastSupplierOrderCreated);
      axios
          .delete(
              API_BASE_URL + "/suppliers/order/" + this.lastSupplierOrderCreated.id,
              {headers: this.header}
          )
          .then(({data}) => {
          })
          .catch((error) => {
            console.log("an error occured " + error);
          })
          .finally(() => {
            
          });
    },
    updatePricingForSelectedArticle(val) {
        console.log(
            "parent search article ======",
            val,
            this.parentSearchSelectedArticleSupplierDetail
        );
        if (val) {
            let stockItem = val;
            val = typeof val == "object" ? val.id : val;
            console.log(
                "parent search article - inside",
                val,
                this.parentSearchSelectedArticleSupplierDetail
            );
            let supplier = this.parentSearchSelectedArticleSupplierDetail
                ? this.parentSearchSelectedArticleSupplierDetail.supplier_detail
                : null;
            console.log("supplier change triggered", this.parentSearchSelectedArticleSupplierDetail,);
            let supplier_stock_detail = this
                .parentSearchSelectedArticleSupplierDetail
                ? this.parentSearchSelectedArticleSupplierDetail
                : null;
            this.dialog = false;
            // 
            this.showLoadingModel = true;

            axios
                .get(
                    API_BASE_URL +
                    "/billings/articles/search?page=1&items_per_page=10" +
                    (this.parent_familyClient
                        ? "&family_client_for_pricing=" + (typeof this.parent_familyClient == 'object' ? this.parent_familyClient.id : this.parent_familyClient)
                        : "") +
                    ("&article_ids=" + val) +
                    (supplier && supplier.id ? "&supplier_id=" + supplier.id : ""),
                    {headers: this.header}
                )
                .then(({data}) => {
                console.log(data.data[0]);
                if (data.data.length == 0) {
                    this.$toast.error(
                        this.$t(
                            "scheduler.appointment.no_pricing_found_for_this_article"
                        )
                    );
                }
                this.articles = [data.data[0]];
                this.article = data.data[0];
                if (supplier == null) {
                    supplier = this.cachedData["suppliers"][this.selectedFamilyArticle.id].find((element) => {
                    if (element.id == 0) {
                        return element;
                    }
                    })
                }
                console.log("supplier updated in datatable", supplier);
                this.article.supplier = [supplier];
                this.article.supplier = this.article.supplier.concat(this.cachedData["suppliers"][this.selectedFamilyArticle.id]);
                this.article.supplier_stock_detail = this.makeSupplierOrderData(
                    stockItem,
                    supplier_stock_detail
                );

                this.isLoadingArticle = false;
                // 

                var articleFilters = JSON.parse(
                    localStorage.getItem("ARTICLE_MODEL_FILTERS")
                );
                articleFilters["id"] = val;
                localStorage.setItem(
                    "ARTICLE_MODEL_FILTERS",
                    JSON.stringify(articleFilters)
                );
                this.calculateColumns(true);
                })
                .catch(() => {
                // 
                })
                .finally(() => {
                    this.$refs["tableQuantity"].focus();
                    // this.parentSearchSelectedArticleId = null;
                    
                    this.showLoadingModel = false;
                    this.readyToAcceptNewArticleFromStock = true;
                });
        } else {
            this.articles = [];
            this.article = "";
            this.unit_price = 0.0;
            this.red_percentage = 0.0;
            this.first_row_minimum_sales_price = 0.0;
            this.parentGroupText = null;
            this.readyToAcceptNewArticleFromStock = true;
        }
    },
    checkAndFixTaxation(){
        let val = this.parent_taxation_reason_id;
        // No reason
        if(val && (typeof val != 'object' && val == 10771000) || (typeof val == 'object' && val && val.id == 10771000)){
            this.taxationAllowed = false;
            this.$nextTick(() => {
                this.taxationAllowed = true;
            })
        }
        // Intercommunity
        if(val && (typeof val != 'object' && val == 10771001) || (typeof val == 'object' && val && val.id == 10771001)){
            this.taxationAllowed = true;
            this.$nextTick(() => {
                this.taxationAllowed = false;
            })
        }
    },
    checkAndSetNewTaxValueForRecalculation(){
        let val = this.parent_taxation_reason_id;
        // No reason
        if(val && (typeof val != 'object' && val == 10771000) || (typeof val == 'object' && val && val.id == 10771000)){
            return true
        }
        // Intercommunity
        if(val && (typeof val != 'object' && val == 10771001) || (typeof val == 'object' && val && val.id == 10771001)){
            return false;
        }
        return true;
    },
    fixCalculationAfterTaxation(){
        // Do Not calculate because its payed/partially payed
        if(this.selectedBill && this.order_status && (this.order_status.key == 'payed' || this.order_status.key == 'partial_payed')){
            console.log("Not calculating because its payed/partially payed", this.order_status);
            return ;
        }
        let tva = parseFloat(this.first_row_tva);
        this.selectedArticles.forEach((element) => {
            if(this.checkAndSetNewTaxValueForRecalculation()){
                tva = element.tva;
            }
            let unit_price = element.unit_price;
            let quantity = element.quantity;

            let thtva = parseFloat( unit_price * quantity - (unit_price * quantity * element.red_percentage) / 100);
            let total = parseFloat(
                    parseFloat(thtva) +
                    parseFloat((tva * thtva) / 100)
                );
            // Update Element
            element.thtva = thtva;
            element.tva = tva ?? 0;
            element.total = total;
        });
        this.$nextTick(() => {
            console.log("fixing_calculation");
            this.calculateTotals();
        })
    },
    fetchAddress(id) {
        axios({
            method: "get",
            url: API_BASE_URL + "/client_addresses/" + id,
            headers: this.header,
        })
            .then((resAddresses) => {
                console.log("parent driver addressses", resAddresses.data.data);
                this.driverAddresses = resAddresses.data.data.map(a => a.address);
            })
            .catch((error) => {
                
            });
    },

    fetchDriverAddressDebounced(val) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
            this.fetchAddress(val.id);
        }, 500); /* 500ms throttle */
    },
  },
  computed: {
    ...mapState([
      "selectedArticlePendingAppointment",
      "applySelectedArticlePendingAppointmentKey",
      "appointmentStatuses",
      "colorCodes",
      "selectedBillData",
      "repeatedArticles",
      "selectedBillData",
        "orderCounter"
    ]),
    ...mapState({
      selectedAppointment: (state) => state.selectedBillData?.appointment,
      appointmentSetupData: (state) => state.appointment
    }),
    readyToAcceptNewArticleFromStockComputed(){
        return this.readyToAcceptNewArticleFromStock;
    },
    paymentTermsDays(){
        if(this.parent_company_client_name && typeof this.parent_company_client_name == 'object'){
            return parseInt(this.parent_company_client_name.payment_terms);
        }
        return parseInt(this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS') ? this.getCompanyPropertyFromLocalStorage('DEFAULT_DOCUMENT_DUE_DAYS').value : 30);
    },
    formattedFreeText() {
      if(this.free_text === null){
        return "";
      }
      // remove all html tag and special characters
      return this.free_text.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ");
    },
    locale() {
      return this.$i18n.locale
    },
    ifIsExpeditionType(){
      // Note: TODO: To Check on the basis of Expedition Type 
      return !(!this.appointmentdata || (this.appointmentdata && this.appointmentdata.expedition_id == null));
    },
    availableDepots() {
      let depot_ids = [];
      this.selectedArticles.forEach((article) => {
        if (article.depot_in_id) {
          depot_ids.push(article.depot_in_id);
        }
      });

      return depot_ids.filter((element) => this.isset(element));
    },
    showIsFullDayCheckbox() {
      if (!this.work_resource || this.calendarViewTypes.length === 0) {
        return false;
      }

      let defaultCalendar = this.calendarViewTypes.find(item => item.id === this.work_resource.calendar_type_id);

      if (!defaultCalendar) {
        return false;
      }

      return defaultCalendar?.key !== 'day';
    },
    currencySymbol() {
      let symbol = null;
      this.fixBillCurrencySymbol();
      if (this.$store.state.topCurrencyType) {
        symbol = this.parseSymbol(this.$store.state.topCurrencyType.symbol ?? '')
        return symbol;
      }
      return symbol ?? '';
    },
    selectedSupplier() {
      return this.article && this.article.supplier ? (this.article.supplier[0] ? this.article.supplier[0] : null) : null
    },
    parentClientIsBlocked() {
      if (!this.parent_company_client_name) {
        return false;
      }

      if (this.parent_company_client_name.status_id == 10041003) {
        return true;
      }

      return false;
    },
    startTimeIsMoreThanADay() {
      if (!this.selectedAppointment) {
        return false;
      }

      let startTime = new Date(this.selectedAppointment.start_time);
      let now = new Date();
      let diff = startTime.getTime() - now.getTime();
      let diffInHours = diff / (1000 * 3600);

      return diffInHours > 24;
    },
    appointmentStatusesItem() {
      return this.appointmentStatuses.map((item) => {
        let color = "";
        if (item.key == "appointment_active") {
          color = this.colorCodes.success;
        } else if (item.key == "appointment_canceled") {
          color = this.colorCodes.danger;
        } else if (item.key == "appointment_is_executed") {
          color = this.colorCodes.warning;
        } else if (item.key == "appointment_not_executed") {
          color = this.colorCodes.secondary;
        }

        return {
          label: this.$t("schedulestab.appointment_status." + item.key),
          value: item.id,
          color: color,
        };
      });
    },
    vehicleDriverDetails() {
      let data = {
        client: this.makeClientData(),
        vehicle: this.makeVehicleData(),
        depot: this.parent_registration_no
            ? typeof this.parent_registration_no == "object"
                ? this.parent_registration_no.depot
                : null
            : null,
      };

      return data;
    },
    companyNameLabel() {
      return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
    },
    articles_list() {
      return this.articles;
    },
    // billType(){
    //     return this.$store.state.billType;
    // },
    // isEditingAllowed() {
    //   return this.$store.state.isEditingAllowed;
    // },
    swatchStyle() {
      const {color, menu} = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "4px" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "17px",
      };
    },
    minTime() {
      if (!this.date) {
        return null;
      }
      let date = new Date(this.date);
      date.setHours(0, 0, 0, 1);
      // check if date is today
      let today = new Date();
      today.setHours(0, 0, 0, 1);

      if (date.getTime() === today.getTime()) {
        // return current hour and minute
        let now = new Date();
        return now.getHours() + ":" + now.getMinutes();
      }

      return "00:00";

    },
    minDate() {
      let date = new Date();
      return date.toISOString().split('T')[0];
    },
  },
  watch: {
    showPaymentReminder(val){
        if(val == false){
            this.getOrderReminder();
        }
    },
    parent_driver(val){
        if(val && val.id){
            this.fetchDriverAddressDebounced(val);
        }
    },
    setDefaultObjectForDirtyChecking(val){
        console.log("default object typeof changed", this.setDefaultObjectForDirtyChecking);
    },
    parent_taxation_reason_id:{
        handler: function(val){
            console.log("fixing taxation reason", val, this.taxationAllowed);
            this.checkAndFixTaxation();
            this.checkEmptinessOfForm();
            this.$nextTick(() => {
                this.fixCalculationAfterTaxation();
            })
        },
        immediate: true
    },
    taxationAllowed:{
        handler:function(val){
            if(val == true){
                this.first_row_tva = this.cached_first_row_tva;
            }
            else if(val == false){
                this.first_row_tva = 0;
            }
            console.log("calculation fixing taxation allowed###", val);
            this.$nextTick(() => {
                this.fixCalculationAfterTaxation();
            })
        },
    },
    'orderCounter.data': function (val) {
      if (val) {
        let orderCounter = val;
        let orderDetailKey = this.orderCounter.order_detail_key
        let action = this.orderCounter.action
        if(['pause', 'stop'].includes(action)) {
          return this.setPageData();
        }

        let orderDetailIndex = this.selectedArticles.findIndex( a => {
          return a.article === orderDetailKey.article_id
        })
        if (orderDetailIndex > -1) {
          let targetArticle = this.selectedArticles[orderDetailIndex];
          targetArticle.order_counter_id = orderCounter.id
          targetArticle.quantity = orderCounter.total_km == 0  ? 0.00 : orderCounter.total_km
        }
      }
    },
    showIsFullDayCheckbox(val) {
      this.is_full_day = val;
    },
    async work_resource(val) {
      if (!val) {
        return;
      }

      let timeSlots = await this.getTimeSlotsForWorkPoint(val.work_point_id);
      this.operationalTimes = this.processSlots(timeSlots);
    },
    fromTime() {
      if (!this.fromTime) {
        this.toTime = null;
      }

      let fromTime = moment(this.fromTime, "HH:mm");

      if (!this.toTime && this.work_type) {
        this.toTime = fromTime.add(this.work_type.work_duration, "minutes").format("HH:mm");
        return true;
      }

      // Using moment check if from time is greater than to time
      let toTime = moment(this.toTime, "HH:mm");

      if (fromTime.isAfter(toTime)) {
        this.toTime = fromTime.add(this.work_type.work_duration, "minutes").format("HH:mm");
      }

    },
    fromDate() {
      if (!this.fromDate) {
        return;
      }

      let fromDate = moment(this.fromDate, "YYYY-MM-DD");

      if (!this.toDate) {
        this.toDate = fromDate.add(this.work_type.work_duration, "minutes").format("YYYY-MM-DD");
        return true;
      }

      // Using moment check if from time is greater than to time
      let toDate = moment(this.toDate, "YYYY-MM-DD");

      if (fromDate.isAfter(toDate)) {
        this.toDate = fromDate.add(this.work_type.work_duration, "minutes").format("YYYY-MM-DD");
      }
    },
    isEditModeForDataTableItem(val) {
      if (!val) {
        this.selectedFamilyArticle = this.selectedFamilyArticlBeforeEditMode;

      }
    },
    currencySymbol() {
      return this.currentCurrencySymbol;
    },
    selectedSupplier(val) {
      console.log("selected Supplier changed in article supplier supplier change triggered", val);
      // this.updatePricingBasedOnSupplier();
    },
    repeatedArticles(val) {
      this.selectedArticles.push(...val);
    },
    async selectedBillData(bill) {
      this.fetchBillRelatives(bill)
    },
    appointmentStatusesItem() {
      this.showDeleteConfirmation = true;
    },
    applySelectedArticlePendingAppointmentKey() {
      this.checkForPendingArticle();
    },
    selectedFamilyArticle(val) {
      console.log("selected famly", val);
      this.quantity = this.selectQuantityFromPendingArticle ? this.quantityFromPendingArticle :  (this.selectedFamilyArticle ? this.selectedFamilyArticle.default_quantity : this.defaultQuantity);
      // set current mu value (measurement value)
      this.currentMu = val ? val.mu.value : '';
      this.red_percentage = 0.0;
      this.unit_price = 0.0;
      this.first_row_tariff = "";
      this.first_row_thtva = "";
      this.first_row_total = "";
      this.first_row_minimum_sales_price = 0.0;
      this.parentGroupText = null;
    },
    workresources() {
      this.setWorkType();
    },
    storedworkresources() {
      console.log("stored wok resource", this.resourceType);
      if (this.resourceType) {
        this.setWorkResources();
      }
    },
    note_dialog(val) {
      if (val) {
        this.bill_note_temp = this.bill_note;
      }
    },
    datetime(val) {
      this.updateToTime();
      this.setStartTime();
      this.$store.commit("setSchedulerDate", val.substr(0, 10));
    },
    billTypes() {
      this.setBillType();
    },
    vehicleDriverDetails(val) {
      this.$store.state.currentlySelectedClient = val.client;

      this.$store.commit("setVehicleDataForAppBar", val); // Enable Just Depot In
      if (val.client.client_id && val.vehicle.vehicle_id) {
        console.log("vehicle depot", val.vehicle);
        if (
            typeof this.parent_registration_no == "object" &&
            this.parent_registration_no.depot &&
            this.parent_registration_no.depot.length > 0
        ) {
          let depotOutAlreadyExists = false;
          this.selectedArticles.forEach((article) => {
            console.log(
                "Stored Depot In",
                this.storedDepotIn,
                article.depot_in_id
            );
            if (
                article.depot_out_id ||
                (article.depot_in_id &&
                    typeof article.depot_in_id == "string" &&
                    article.depot_in_id.includes(this.storedDepotIn) &&
                    this.storedDepotIn.length == 1)
            ) {
              depotOutAlreadyExists = true;
            }
          });
          if (depotOutAlreadyExists) {
            this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
          } else {
            this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
          }
        } else {
          this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
        }
      } else if (
          (val.client.name || val.client.client_id) &&
          val.vehicle.registration_no &&
          !val.vehicle.vehicle_id
      ) {
        this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
      } else {
        this.$store.commit("setShowDepotButtons", 0); // Dont show any button
      }
    },
    confirmationDialogButtonClickedIs(newVal) {
      if (newVal == true && this.confirmationDialogOperation != null) {
        if (
            this.confirmationDialogOperation == "delete" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          console.log("delete appointment", newVal);
          this.onConfirmationDeleteAppointment();
        }
        if (
            this.confirmationDialogOperation == "identifier_confirmation" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          console.log("allow identifier");
          this.allowEditIdentifier();
        }
        if (
            this.confirmationDialogOperation == "print_report" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.printReport();
        }
        if (
            this.confirmationDialogOperation == "print_label" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.printLabel();
        }
        if (
            (this.confirmationDialogOperation == "make_bill" ||
                this.confirmationDialogOperation == "make_bill_error") &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeBill();
        }
        if (
            this.confirmationDialogOperation == "make_purchase_order" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.makePurchaseOrder();
        }
        if (
            this.confirmationDialogOperation == "make_credit_note" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeCreditNote();
        }
        if (
            this.confirmationDialogOperation == "keep_trying" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          if (this.confirmationDialogButtonClickedIs == false) {
            this.deleteSupplierOrder();
          }
        }
        if (
            this.confirmationDialogOperation == "convert_to_bill" &&
            this.confirmationDialogButtonClickedIs == true
        ) {
          this.makeBill(true);
        }
        this.confirmationDialog = false;

        this.confirmationDialogButtonClickedIs = null;
      }
    },
    async appointmentdata(val) {
      if (!val) {
        return;
      }

      this.$nextTick(() => {
        this.datetime = new Date(val.start_time).toISOString();

        let startTime = moment(val.start_time);
        let endTime = moment(val.end_time);

        this.work_resource = val.work_resource;
        this.work_type = val.work_type;
        this.free_text = val.note;
        this.appointment_is_executed = val.appointment_status_id == 10481003; // If Executed then mark is work as checked
        setTimeout(() =>{
            this.color = "#" + val.color;
        }, 100)
        this.fromTime = startTime.format("HH:mm")
        this.toTime = endTime.format("HH:mm");
        this.date = startTime.format("YYYY-MM-DD");
        this.fromDate = startTime.format("YYYY-MM-DD");
        this.toDate = endTime.format("YYYY-MM-DD");
        if(val.location_id && val.location && typeof val.location == 'object'){
            this.pickup_address = val.location;
            this.is_pickup_appointment = true;
            if(val.pickup_address && val.pickup_address.address && typeof val.pickup_address.address == 'object'){
                if(!this.pickup_address){ // In case of new data
                    this.pickup_address = val.pickup_address.address;
                }

                if(val.pickup_address){
                    console.log("pick up address", val.pickup_address);
                    this.pickup_start_datetime = val.pickup_address.from_time ? val.pickup_address.from_time.substr(11, 5) : null;
                    this.pickup_end_datetime = val.pickup_address.to_time     ? val.pickup_address.to_time.substr(11, 5)   : null;
                    this.pickup_note = val.pickup_address.note;
                }
            }
        }
        else{
            this.pickup_address = null;
            this.is_pickup_appointment = false;
        }

        this.appointmentStatus = val.appointment_status_id;
        if (!val.order) {
          let isCompany = !!val.client.company_id;
          this.parent_entityType = isCompany
              ? this.entityTypeSelectItems[1]
              : this.entityTypeSelectItems[0];
          this.parent_company_client_name = val.client;
          this.parent_company_client_names = [val.client];
          this.parent_familyClient = val.client.family_client_id;

          this.parent_company_client_email = val.client.email;
          this.parent_company_client_gsm = val.client.gsm;
          this.parent_company_client_phone = val.client.phone;
          this.parent_representative_company_id =
              val.client.representative_company_id;

          if (val.client.company) {
            this.parent_denomination = val.client.company.denomination_id;
            this.parent_tva = val.client.company.tva;
          }
        }

        this.is_full_day = val.is_full_day
      });
    },
    work_type(val, prevValue) {
      if (val) {
        this.updateToTime();
          this.color = "#" + val.color;
        if (this.$route.path.includes('add') && !prevValue) {
          this.setupNewAppointment();
        }
      } else {
        this.fixWorkType();
      }
    },
    parentSearchSelectedArticleId(val) {
      this.updatePricingForSelectedArticle(val);
    },
    payment_method(val) {
      this.checkEmptinessOfForm();
    },
    note(val) {
      this.checkEmptinessOfForm();
    },
    reference_client(val) {
      this.checkEmptinessOfForm();
    },
    first_row_tariff(val) {
      this.checkEmptinessOfForm();
    },
    first_row_tva(val) {
      this.calculateColumns(true);
      this.checkEmptinessOfForm();
    },
    first_row_thtva(val) {
      this.checkEmptinessOfForm();
    },
    first_row_total(val) {
      this.checkEmptinessOfForm();
    },
    next_event_code(val) {
      this.checkEmptinessOfForm();
    },
    parent_search_client(val) {
      this.checkEmptinessOfForm();
    },
    parent_company_client_phone(val) {
      this.checkEmptinessOfForm();
    },
    parent_company_client_gsm(val) {
      this.checkEmptinessOfForm();
    },
    parent_company_client_email(val) {
      this.checkEmptinessOfForm();
    },
    parent_company_client_language(val) {
      this.checkEmptinessOfForm();
    },
    parent_note(val) {
      this.checkEmptinessOfForm();
    },
    parent_denomination(val) {
      this.checkEmptinessOfForm();
    },
    parent_tva(val) {
      this.checkEmptinessOfForm();
    },
    parent_address(val) {
      this.checkEmptinessOfForm();
    },
    parent_creditor(val) {
      this.checkEmptinessOfForm();
    },
    parent_reg_date(val) {
      this.checkEmptinessOfForm();
    },
    parent_model(val) {
      this.checkEmptinessOfForm();
    },
    parent_km(val) {
      this.checkEmptinessOfForm();
    },
    parent_brand(val) {
      this.checkEmptinessOfForm();
    },
    parent_registration_no(val) {
      this.checkEmptinessOfForm();
    },
    parent_vehicle_type(val) {
      this.checkEmptinessOfForm();
    },
    parent_vin(val) {
      this.checkEmptinessOfForm();
    },

    due_date(val) {
      if (val && val.length > 10) {
        this.due_date = val.substring(0, 10);
      }
    },

    created_date(val) {
        if (val && val.length > 10) {
            this.created_date = val.substring(0, 10);
        }
    },
    parent_familyClient(val) {
      if (val) {
        this.reCalculateSelectedArticlesPricing();
      }
      this.checkEmptinessOfForm();
    },
    parent_company_client_first_name(val) {
      this.checkEmptinessOfForm();
    },
    parent_company_client_name(val) {
      this.setDueDate();
      console.log("billing_client_name", this.parent_company_client_name);
      if(this.parent_company_client_name && typeof this.parent_company_client_name == 'object' && this.parent_company_client_name.company && typeof this.parent_company_client_name.company == 'object'){
        this.taxationAllowed = this.findTaxationReasonByCode(this.parent_company_client_name.company.taxation_reason_id);
        console.log("taxation_allowed", this.findTaxationReasonByCode(this.parent_company_client_name.company.taxation_reason_id));
      }
      else{
        this.taxationAllowed = true;
      }
        this.$nextTick(() => {
            this.fixCalculationAfterTaxation();
        })
      this.customer_balance = this.fixDecimal(0);
      this.checkEmptinessOfForm();
      if (this.selectedBill && this.selectedBill.vehicle) {
        let billVehicle = val.vehicles.find((vehicle) => {
          return vehicle.id == this.selectedBill.vehicle.id;
        });
        if (billVehicle) {
          val.vehicles.unshift(billVehicle);
        }
        this.parent_company_client_name = val;
      } else if (this.appointmentdata && this.appointmentdata.vehicle) {
        console.log("finding vehicles", this.appointmentdata.vehicle, val);
        let billVehicle = val.vehicles.find((vehicle) => {
          return vehicle.id == this.appointmentdata.vehicle.id;
        });
        if (billVehicle) {
          val.vehicles.unshift(billVehicle);
        }
        this.parent_company_client_name = val;
      }
      if (val && val.id) {
        this.fetchClientBalance(val.id);
        console.log("notificationtype getig", val);
        if (val.notification_type_id) {
          this.notification_types.forEach((notification_type) => {
            if (notification_type.id == val.notification_type_id) {
              this.notification_type = notification_type;
            }
          });
        }
        // this.notification_type = this.translateKeys([val.notification_type], 'key', 'scheduler.notification_types')[0];
      }
    },
    billType(val) {
      this.checkEmptinessOfForm();
      // this.statuses = [];
      // this.order_status = null;
      this.filterOutStatuses();
      // if(val && val.key && val.key == 'purchase_order'){
      //     this.isVisibleOrderStatus = false;
      // }
      // else{
      this.isVisibleOrderStatus = true;
      // }
    },
    ispayment_method() {
      // this.payment_method = null;
    },
    order_status(val) {
      this.checkEmptinessOfForm();
      this.ispayment_method = false;
      if (val) {
        if(typeof val == 'object' && val?.key && val.key == "payed" || val.key == "partial_payed"){
            this.ispayment_method = true;
        }
      }
    },
    selectedArticles() {
      this.checkEmptinessOfForm();
      this.calculateTotals();
      this.selectedArticles.forEach((article, index) => {
        // update mus for every article
        if (!article.selectedmu) {
          article.selectedmu = article.article_original_name.family.mu;
        }
      });
      this.fineDataForSending();

      // Enable or disable depot buttons based upon depot out
      let depotOutAlreadyExists = false;
      this.selectedArticles.forEach((article) => {
        if (article.depot_out_id) {
          depotOutAlreadyExists = true;
        }
      });
      if (
          this.$store.state.showDepotButtons == 3 ||
          this.$store.state.showDepotButtons == 4
      ) {
        if (depotOutAlreadyExists) {
          this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
        } else {
          this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
        }
      }
      console.log("show depot buttons", this.$store.state.showDepotButtons);

      this.selectedArticles.forEach((article, index) => {
        console.log("dot article", article.supplier);
        // Fetch Dots only for tyre article
        if (
            article.article_original_name &&
            article.article_original_name.family &&
            (article.article_original_name.family.id == 1 ||
                article.article_original_name.family.parent_family_id == 1) &&
            article.depot_out_id == null &&
            article.depot_in_id == null
        ) {
          this.getArticleDots(
              index,
              article.article,
              article.supplier && article.supplier[0]
                  ? article.supplier[0].id != 0
                      ? article.supplier[0].id
                      : 0
                  : 0
          );
        } else {
          this.selectedArticles[index].dots = [];
        }

        // Attach Supplier Orders to the articles
        if (this.selectedBill && this.selectedBill.details && this.selectedBill.details.length > 0) {
          let currentArticleId = typeof article.article == "object" ? article.article.id : article.article;
          this.selectedBill.details.forEach((detailItem) => {
            console.log("matching supplier", detailItem.article_id, currentArticleId);
            if (detailItem && detailItem.supplier_order_details_id && detailItem.supplier_order_detail && currentArticleId == detailItem.article_id) {
              article.supplier = [detailItem.supplier_order_detail.supplier_order.supplier];
              article.saved_supplier_order_detail = detailItem.supplier_order_detail;
            }
          })
        }
        // if (
        //   this.selectedBill &&
        //   this.selectedBill.supplier_order &&
        //   this.selectedBill.supplier_order.length > 0
        // ) {
        //   console.log(
        //     "Supplier Order Changed",
        //     this.selectedBill.supplier_order
        //   );
        //   this.selectedBill.supplier_order.forEach((supplierOrder) => {
        //     console.log("Supplier Order Changed ------------", supplierOrder);
        //     if (
        //       article.article == supplierOrder.supplier_order_details.article_id
        //     ) {
        //       // Update Article Suppliers
        //       article.supplier = [];
        //       article.supplier[0] = supplierOrder.supplier;
        //       article.supplier_stock_detail = supplierOrder.supplier;

        //       // Update Article Suppplier Order Status
        //       if (
        //         article.supplier_stock_detail &&
        //         supplierOrder.supplier_id &&
        //         article.supplier_stock_detail.id == supplierOrder.supplier_id
        //       ) {
        //         article.supplier_order_status = supplierOrder;
        //         console.log(
        //           "article detail",
        //           article.supplier_stock_detail.id,
        //           supplierOrder.supplier_id
        //         );
        //       }
        //     }
        //   });
        // }
      });
    },
    article(val) {
      this.checkEmptinessOfForm();
      this.calculateColumns();
      if (!this.isEditModeForDataTableItem) { // If article is being edited then modify that particular item quantity and other tdetails
        if (val && val.family && val.family.mu_id) {
          console.log("entering family mu", val);
          this.mus.forEach((element) => {
            if (element.id == val.family.mu_id) {
              console.log("entering family mu matched", val.family, element.id);
              this.currentMu = element
            }
          })
        }
        if(this.showGroupText){
            this.parentGroupText = this.parentGroupText ? this.parentGroupText : (this.selectedFamilyArticle ? this.selectedFamilyArticle.name : null); 
        }
      }
      this.$store.state.showApplySelectedArticleButton =
          val == "" ? true : false;
    },
    quantity(val) {
      this.checkEmptinessOfForm();
      this.calculateColumns(true);
    },
    unit_price(val) {
      if (!val) {
        val = 0;
      }

      this.checkEmptinessOfForm();
      this.unit_price = val ?? 0;
      if (this.pauseWatchForUnitPrice) {
        this.pauseWatchForUnitPrice = false;
      } else {
        this.calculateColumns(true);
      }
      if (this.article) {
        if (this.unit_price < this.first_row_minimum_sales_price) {
          this.isInValidUnitPrice = true;
        } else {
          this.isInValidUnitPrice = false;
        }
      } else {
        this.isInValidUnitPrice = false
      }
    },
    first_row_minimum_sales_price(val) {
      if (!val) {
        val = 0;
      }

      this.first_row_minimum_sales_price = val ?? 0;
    },
    red_percentage(val) {
      this.checkEmptinessOfForm();
      this.calculateColumns(true);
    },
    search(val) {
      this.checkEmptinessOfForm();
      console.log(this.article);
      if (
          !val ||
          (this.article && this.article.title_description[0].description == val)
      ) {
        return;
      }
      this.isLoadingArticle = true;
      this.fetchArticleListDebounced();
    },
    search_bill(val) {
      this.checkEmptinessOfForm();
      if (val && typeof val == "object") {
        this.search_bill = val.bill_number
            ? val.bill_number
            : val.category == "vehicles"
                ? val.registration_number
                : val.name;
        this.allowContinueSearch = false;
        return;
      }

      if (val && this.allowContinueSearch) {
        if (
            !val ||
            (this.selectedBill && this.selectedBill.bill_number == val)
        ) {
          return;
        }
        this.isLoadingSearchRegiClient = true;
        this.fetchBillSuggesListDebounced();
      }
      this.allowContinueSearch = true;
    },
    selectedBill(val) {
      this.$store.commit("setCurrentPageData", this.selectedBill);
      this.$store.state.currentBillPeppolStatus = this.determineBillPeppolStatus(val);
      this.checkEmptinessOfForm();
      this.lastSupplierOrderCreated = null;
      if (this.selectedBill) {
        this.$store.commit("setIsBillSelected", true);
        this.$store.commit("setSelectedBillData", this.selectedBill);
        if (this.selectedBill.appointment && this.selectedBill.appointment.id) {
          if (!this.appointmentdata) {
            this.fetchAppointmentByID(this.selectedBill.appointment.id, false);
          }
          this.$store.commit("setBillCancelVisible", true);
          this.$store.commit("setEditingAllowed", true);
          this.$store.commit("setFormMode", SCHEDULER_EDIT_MODE);
        }
      } else {
        this.$store.commit("setIsBillSelected", false);
        this.$store.commit("setSelectedBillData", null);
      }
      if (!this.selectedBill) {
        // this.$route.meta.title = "create_appointment";
        this.onResetPageData();
      }
      if (this.selectedBill) {
        this.bills = [this.selectedBill];
        this.$store.commit("setBillType", this.selectedBill.type);
        this.billType = this.selectedBill.type;
        this.selectedArticles = [];
        this.articles = [];
        this.article = "";

        if (this.selectedBill.bill_number) {
          this.$store.commit("setBillCancelVisible", true);
          this.$store.commit("setEditingAllowed", true);
        } else {
          this.$store.commit("setBillCancelVisible", false);
          this.$store.commit("setEditingAllowed", false);
        }

        if (
            this.selectedBill.bill_number &&
            this.billType.key == "estimation" &&
            this.selectedBill.child_order_id == null
        ) {
          this.$store.commit("setMakeBillVisible", false);
          this.$store.commit("setMakePurchaseOrderVisible", true);
          this.$store.commit("setMakeCreditNoteVisible", false);
        } else if (
            this.selectedBill.bill_number &&
            this.billType.key == "purchase_order" &&
            (this.selectedBill.status_id == 10251000 || this.selectedBill.status_id == 10251002) &&
            this.selectedBill.child_order_id == null /* (10251000 = Open) */
        ) {
          this.$store.commit("setMakeBillVisible", true);
          this.$store.commit("setMakePurchaseOrderVisible", false);
          this.$store.commit("setMakeCreditNoteVisible", false);
        } else if (
            this.selectedBill.bill_number &&
            this.billType.key == "bill" &&
            this.selectedBill.child_order_id == null
        ) {
          this.$store.commit("setMakeBillVisible", false);
          this.$store.commit("setMakePurchaseOrderVisible", false);
          this.$store.commit("setMakeCreditNoteVisible", true);
        } else {
          this.$store.commit("setMakeBillVisible", false);
          this.$store.commit("setMakePurchaseOrderVisible", false);
          this.$store.commit("setMakeCreditNoteVisible", false);
        }
        let isCompany = !!this.selectedBill.client.company_id;
        this.parent_entityType = isCompany
            ? this.entityTypeSelectItems[1]
            : this.entityTypeSelectItems[0];
        this.parent_company_client_name = this.selectedBill.client;
        this.parent_company_client_names = [this.selectedBill.client];
        this.parent_familyClient = this.selectedBill.client.family_client_id;
        this.parent_project = this.selectedBill.project;
        if (this.selectedBill.project) {
          this.parent_project_name = this.selectedBill.project.name;
          this.parent_project_location = this.selectedBill.project.location;
          this.parent_project_locations = this.selectedBill.project.location;
          this.parent_project_note = this.selectedBill.project.note;
          this.parent_project_status = this.selectedBill.project.status_id;
        }

        this.parent_company_client_email = this.selectedBill.client.email;
        this.parent_company_client_language = this.selectedBill.client.language;
        this.parent_company_client_gsm = this.selectedBill.client.gsm;
        this.parent_company_client_phone = this.selectedBill.client.phone;
        this.parent_representative_company_id = this.selectedBill.client.representative_company_id;

        this.reference_client = this.selectedBill.reference;
        this.note = this.selectedBill.message;
        this.next_event_code = this.selectedBill.next_event_id;
        this.$nextTick(() => {
            // In Next Tick because on client update it will change due date automatically so we have to wait for it
            this.due_date = this.selectedBill?.due_date;
            this.created_date = this.selectedBill?.created_date;
        })
        if (
            this.selectedBill.billing_address_id &&
            this.selectedBill.billing_address.id
        ) {
          this.parent_address = this.selectedBill.billing_address;
        }
        if (
            this.selectedBill.shipping_address_id &&
            this.selectedBill.shipping_address?.id
        ) {
          this.parent_shipping_address = this.selectedBill.shipping_address;
        }

        if (this.selectedBill.client.company) {
          this.parent_denomination = this.selectedBill.client.company.denomination_id;
          this.parent_taxation_reason_id = this.selectedBill.client.company.taxation_reason;
          this.parent_tva = this.selectedBill.client.company.tva;
        }
        if (
            this.selectedBill &&
            this.selectedBill.details &&
            this.selectedBill.details.length > 0
        ) {
          this.bill_note = this.selectedBill.message;
          this.bill_note_temp = this.selectedBill.message;
          this.rowNumber = 0;
          this.setTopCurrencyBasedOnDefaultBank(this.selectedBill.bank_id);
          this.selectedBill.details.forEach((element) => {
            let isToMultiplyWithCredNoteMulValue = false;
            console.log("current element", element, element.mu_id);
            let selectedmu = '';
            this.mus.forEach((elementmu) => {
              if (elementmu.id == element.mu_id) {
                selectedmu = elementmu
              }
            })
            console.log("current element mu", selectedmu);
            if (this.billType && this.billType.key == "credit_note") {
              isToMultiplyWithCredNoteMulValue = true;
            }

            let thtva =
                element.price * element.quantity -
                (element.price * element.quantity * element.reduction) / 100;
            this.selectedArticles.push({
              srno: this.rowNumber++,
              article: element.article_id,
              article_original_name: element.article,
              quantity: element.quantity,
              unit_price: parseFloat(
                  isToMultiplyWithCredNoteMulValue
                      ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.price ?? 0)
                      : element.price ?? 0
              ),
              minimum_sales_price: parseFloat(element.minimum_sales_price),
              rounded_unit_price: parseFloat(
                  isToMultiplyWithCredNoteMulValue
                      ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.rounded_unit_price ?? 0)
                      : element.rounded_unit_price ?? 0
              ),
              red_percentage: parseFloat(element.reduction ?? 0),
              tarrif: parseFloat(element.article?.tariff_price),
              tva: parseFloat(element.tax),
              thtva: parseFloat(
                  isToMultiplyWithCredNoteMulValue
                      ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (thtva ?? 0)
                      : thtva ?? 0
              ),
              stock_property: element.stock_property ?? null,
              total: parseFloat(
                  isToMultiplyWithCredNoteMulValue
                      ? parseInt(this.creditNoteMultiplicationValue.value) *
                      (element.total ?? 0)
                      : element.total ?? 0
              ),
              text: element.text,
              row: element.row,
              text_position: element.text_position ?? 0,
              depot_out_id: element.depot_out_id,
              depot_in_id: element.depot_in_id,
              dots: null,
              group: element.group ?? null,
              selectedmu: selectedmu,
              currency_id: element.currency_id,
              order_counter_id: element.order_counter_id
            });
          });
        }
        console.log("selected Bill ", this.selectedArticles);
        this.order_status = this.selectedBill.status;
        this.payment_method = this.selectedBill.payed_by_id
            ? this.selectedBill.payedBy
            : null;

        this.showOrHidePrintReport();
        this.showOrHidePrintDepotOutReport();
        this.getOrderReminder();
        this.reduction_on_order = this.selectedBill.reduction;

        this.order_reminder_type = this.selectedBill.reminder_type
      } else {
        this.$store.commit("setMakeBillVisible", false);
        this.$store.commit("setMakePurchaseOrderVisible", false);
        this.$store.commit("setMakeCreditNoteVisible", false);
      }
    },
  },
};
</script>

<style scoped>
.right-aligned-input >>> input {
  text-align: right;
}

.customerbalance >>> .theme--light.v-label--is-disabled {
  color: rgb(255, 10, 10) !important;
}

.customerbalance >>> input {
  text-align: right;
  color: rgb(255, 0, 0) !important;
}

.customerbalance >>> .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgb(255, 0, 0) !important;
}

.articlenotset {
  display: flex !important;
}

.articleset {
  display: none !important;
}

.selectedArticleTextBox {
  background-color: rgb(220, 255, 220);
}

.selectedArticleTextBox .v-input__slot {
  background-color: rgb(220, 255, 220);
}

.selectedSupplierTextBox {
    background-color: rgb(255, 233, 220);
}

.selectedSupplierTextBox .v-input__slot {
    background-color: rgb(255, 233, 220);
}


.articleNotInStock {
  background-color: transparent;
  color: red;
}

.articleInStock {
  background-color: transparent;
  color: green !important;
}

.articleWaitingForSupplierOrder {
  background-color: transparent !important;
  color: red !important;
}

.articleWaitingForSupplierOrderOrange {
  background-color: transparent !important;
  color: orange !important;
}

.dontbreakline {
  white-space: pre !important;
}

.articles_row_item > .text-right {
  padding: 0px !important;
}
</style>