<template>
  <div v-if="currentVehicle" class="mb-5">
    <v-row>
      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="registrationNumber"
          outlined
          dense
          flat
          filled
          :label="$t('rentals.reg_no')"
          style="margin-top: 10px"
          class="to-upper"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="brandName"
          class="to-upper"
          :label="$t('rentals.brand_name')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="vehicleModel"
          class="to-upper"
          :label="$t('rentals.model_name')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          disabled
          :value="cylinderCapacity"
          class="to-upper"
          :label="$t('rentals.cylinder_capacity')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="fuelType"
          class="to-upper"
          :label="$t('rentals.fuel_type')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="gearBoxType"
          class="to-upper"
          :label="$t('rentals.gear_box_type')"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="1" class="remove-space-around">
        <v-text-field
          disabled
          :value="currentVehicle.vin"
          label="VIN"
          class="to-upper"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="remove-space-around">
        <v-text-field
          type="number"
          v-model.number="mileage"
          :label="$t('rentals.mileage')"
          @change="updateVehicle"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import { API_BASE_URL } from "@/config";
import axios from "@/plugins/axios";
export default {
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    brandName() {
      return this.currentVehicle?.brand?.name || "";
    },
    fuelType() {
      return this.currentVehicle?.fuel?.value || "";
    },
    gearBoxType() {
      return (this.currentVehicle?.gearbox?.value || "").replace("_", " ");
    },
    registrationNumber() {
      return this.currentVehicle?.registration_number || "";
    },
    vehicleModel() {
      return this.currentVehicle?.model || "";
    },
    cylinderCapacity() {
      return this.currentVehicle?.cylinder_capacity || "";
    },
  },
  data() {
    return {
      search: null,
      currentVehicle: null,
      mileage: null,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
    };
  },
  mounted() {
    this.currentVehicle = this.vehicle;
    this.mileage = this.currentVehicle.km;
  },
  watch: {
    vehicle: function(newVehicle) {
      this.currentVehicle = newVehicle;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateVehicle() {
      // mileage must be a number greater than 0
      let mileage = parseInt(this.mileage);
      if (mileage < 0) {
        this.$toast("rentals.mileage_error");

        return (this.mileage = this.currentVehicle.km);
      }

      this.currentVehicle.km = mileage;
      
      axios
        .put(
          API_BASE_URL + "/client_vehicles/" + this.currentVehicle.id,
          this.currentVehicle,
          {
            headers: this.header,
          }
        )
        .finally(() => {
          
        })
        .then((response) => {
          this.$toast.success(this.$t("rentals.mileage_update_success"));
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message || this.$t("unknown_error")
          );
        });
    },
  },
};
</script>
<style scoped>
.remove-space-around {
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
}
</style>
