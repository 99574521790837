var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{staticStyle:{"z-index":"20001"},attrs:{"max-width":"600px","scrollable":"","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-emoticon-happy-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Emoji Picker")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('emoji-picker',{on:{"emoji":_vm.selectEmoji},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
var clickEvent = ref.events.click;
return _c('div',{ref:"showEmojiButton",staticClass:"mr-2 mt-2",staticStyle:{"display":"none"},on:{"click":function($event){$event.stopPropagation();return clickEvent($event)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-emoticon-happy-outline")])],1)],1)}},{key:"emoji-picker",fn:function(ref){
var emojis = ref.emojis;
var insert = ref.insert;
return _c('div',{},[_c('div',[_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,style:({ cursor: 'pointer' }),attrs:{"title":emojiName},on:{"click":function($event){return insert(emoji)}}},[_vm._v(_vm._s(emoji))])}),0)])}),0)])])}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }