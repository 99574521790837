<template>
    <div>
        <v-combobox
            class="to-upper"
            :autocomplete="autocompleteValueLocal"
            v-model="member"
            :items="members"
            item-value="id"
            item-text="name"
            :label="$t(label)"
            append-icon="mdi-close"
            v-on:keydown="handleInput($event, 'alphanumeric', 50)"
            :search-input.sync="search_member"
            no-filter
            v-on:keydown.enter.prevent=""
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            :error-messages="errorMessages"
            >
            <template slot="append">
                <v-icon @click="editMember()">mdi-pencil</v-icon>
                <v-icon v-if="member" @click="removeMember()">mdi-close</v-icon>
                <!-- <v-icon v-if="!member && client" @click="addMember()">mdi-plus</v-icon> -->
            </template>
            <template slot="item" slot-scope="{ item }">
                <v-list-item-content @click="member = item">
                    <p class="member_name">{{ (item.name ? item.name : '').toUpperCase() }} {{ (item.first_name ? item.first_name : '').toUpperCase() }}</p>
                    <p class="member_detail">
                        <template v-if="item.type">
                            {{ $t("member_types." + item.type.key) }}
                        </template>
                        <template v-if="item.department">
                            - {{ (item.department ? item.department : '').toUpperCase() }}
                        </template>
                        <template v-if="item.email">
                            - {{ (item.email ? item.email : '').toUpperCase() }}
                        </template>
                        <template v-if="item.phone">
                            - {{ (item.phone ? item.phone : '').toUpperCase() }}
                        </template>
                        <template v-if="item.company_address && item.company_address.length > 0 && item.company_address[0].address">
                            - {{ computeAddressLabel(item.company_address[0].address) }}
                        </template>
                    </p>
                </v-list-item-content>
            </template>

            <template slot="selection" slot-scope="{ item }">
                <v-list-item-content>
                    <template v-if="item && typeof item == 'object'">
                        <p class="member_name">
                            <strong>{{ (item.name ? item.name : '').toUpperCase() }} {{ (item.first_name ? item.first_name : '').toUpperCase() }}</strong>
                        </p>
                        <p class="member_detail">
                            <template v-if="item.type_id">
                                <template v-if="item.type && typeof item.type == 'object'">
                                    {{ $t("member_types." + item.type.key) }}
                                </template>
                                <template v-else>
                                    {{ getMemberTypeByForNaming(item.type_id) }}
                                </template>
                            </template>
                            <template v-else-if="! item.type_id && item.type">
                                <template v-if="item.type && typeof item.type == 'object'">
                                    {{ $t("member_types." + item.type.key) }}
                                </template>
                            </template>
                            <template v-if="item.department">
                                - {{ (item.department ? item.department : '').toUpperCase() }}
                            </template>
                            <template v-if="item.email">
                                - {{ (item.email ? item.email : '').toUpperCase() }}
                            </template>
                            <template v-if="item.phone">
                                - {{ item.phone }}
                            </template>
                            <template v-if="item.company_address && item.company_address.length > 0 && item.company_address[0].address">
                                - {{ computeAddressLabel(item.company_address[0].address) }}
                            </template>
                        </p>
                    </template>
                    <template v-if="item && typeof item == 'string'">
                        {{ item.toUpperCase() }}
                    </template>
                </v-list-item-content>
            </template>
        </v-combobox>
        <edit-client-model
            v-if="openEditClientMemberModel"
            @buttonClicked="updatedMember"
            :defaultClient="value"
            :addMemberToCompany="client"
            :showDialog.sync="openEditClientMemberModel"
            :addMember="true"
            :saveToDB="false"
            :expeditor_tva="expeditor_tva"
        ></edit-client-model>
    </div>
</template>
<script>
    import { API_BASE_URL } from "@/config";
    import axios from "@/plugins/axios";
    import EditClientModel from "@/components/EditClientModel.vue";
    export default {
        model: { prop: "value", event: "input" },
        components: {
            "edit-client-model": EditClientModel,
        },
        props:{
            value: {
                type: [String, Number, Object],
                default: null,
            },
            items: {
                type: [Array],
                default: Array,
            },
            label: {
                type: String,
                default: 'clients.member_name',
            },
            client: {
                type: [String, Number, Object],
                default: null,
            },
            errorMessages: {
                type: [Array],
                default: Array
            },
            expeditor_tva: {
                type: [String],
                default: null,
            }
        },
        data(){
            return {
                header: { Authorization: "Bearer " + this.$store.state.AccessToken },
                autocompleteValueLocal: "off",
                is_boxes: this.getCompanyPropertyFromLocalStorage(
                    "boxes_on_fields",
                    1
                ),
                member: null,
                members: [],
                memberTypes: [],
                search_member: null,
                openEditClientMemberModel: false
            }
        },
        mounted(){
            
        },
        methods:{
            async getMemberTypes(){
                await this.getCodes('member_types', 'member_types')
                .then((data) => {
                    this.memberTypes = data;
                })
            },
            getMemberTypeByForNaming(id){
                this.getMemberTypes();
                console.log("member_type_id", id);
                let memberType = null;
                this.memberTypes.forEach((element) => {
                    if(element.id == id){
                        memberType = element;
                    }
                })
                if(memberType){
                    return this.$t("member_types." + memberType.key);
                }
                return "";
                
            },
            addMember(){
                this.value = null;
                this.openEditClientMemberModel = true; 
            },
            editMember(){
                console.log("edit_member_called");

                if( (! this.member || typeof this.member == 'string') && this.search_member){
                    this.member = this.search_member;
                }
                console.log("member triggered", this.member);

                // if(this.member && typeof this.client == 'object'){
                    this.openEditClientMemberModel = true; 
                // }
                // else{
                //     // First Save Client from the parent and then save Back Again
                //     // So Trigger First event to save client and then from their trigger this function again
                //     console.log("member emitted to save client first", this.member, this.client);
                //     this.$emit('saveClientFirstAndThenTriggerBack', true);
                // }
            },
            updatedMember(val){
                console.log("member_updated", val);
                if(val && typeof val == 'object'){
                    let ifAlreadyExists = false;
                    this.members.forEach((element, index) => {
                        if(val.id && element.id == val.id){
                            ifAlreadyExists = index;
                        }
                    })
                    if(ifAlreadyExists !== false){
                        this.members[ifAlreadyExists] = val;
                    }
                    else if(ifAlreadyExists >= 0){
                        this.members.push(val);
                    }
                     // Select member Automatically
                    this.member = val;
                }
                
            },
            removeMember(){
                console.log("remove_member_called");
                this.member = null;
            },
            fetchMember(client) {
                return new Promise((resolve, reject) => {
                    this.isLoading = true;
                    if(client){
                        axios.get(
                                API_BASE_URL +
                                    "/clients?searchRegNumber=true&membersOnly=true" +
                                    (client ? ("&representative_company_id=" + client.representative_company_id) : ''),
                                {
                                    headers: this.header,
                                }
                            )
                            .then(({ data }) => {
                                resolve(data.data);
                                this.isLoading = false;
                            })
                            .catch((err) => {
                                reject(err)
                                console.log(err);
                                this.isLoading = false;
                            });
                    }
                    else{
                        resolve([]);
                    }
                })
            },
        },
        watch:{
            value(val){
                console.log("updated expeditor_member_name", val);
                if(val){
                    this.member = val;
                }
            },
            items(val){
                if(val && Array.isArray(val) && val.length > 0){
                    console.log("oldval_newval_member_names", val);
                    this.members = val;
                }
                else{
                    this.members = [];
                }
            },
            member(){
                console.log("emit expeditor_member_name", this.member);
                this.$emit("input", this.member);
            },
            client(newval, oldval ){
                console.log("oldval_newval", oldval, newval);
                if(newval && typeof newval == 'object' && newval.id ){
                    if(oldval && newval && oldval.id == newval.id){
                        // this.members = [];
                        // this.member = null;
                    }
                    else{
                        this.fetchMember(newval).then((data) => {
                            console.log("oldval_newval_fetched_member", data);
                            this.$emit("update:items", data)
                            this.members = data;
                            // Check if already selected Member exists in the list, if yes then select it else make it null
                            if(this.member){
                                this.items.forEach((element) => {
                                    if(element.id == this.member.id){
                                        this.member = element;
                                    }
                                })
                            }
                        });
                    }
                }
                else if(newval && typeof newval == 'object' && ! newval.id ){
                    // 
                }
                else if(!newval){
                    console.log("oldval_newval_member_null", this.members);
                    this.member = null;
                    this.members = [];
                }
            }
        }
    }
</script>
<style>
.member_name{
    font-size: 14px;
}
.member_detail{
    font-size: 10px;
    margin: 0px !important;
    white-space: nowrap;
    overflow: hidden;
}
.member_more_detail{
    font-size: 10px;
}
.v-list-item__content{
    padding: 0px;
    margin: 0px;
}
.v-list-item__content ~ input{
    width: 10px !important;
    min-width: 10px;
    max-width: 10px !important;
}
</style>