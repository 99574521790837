<template>
    <div v-if="readyToView">
        <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>s
        <apexchart type="pie" width="480" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    props: {
        bardata: [String],
        barfields: [Array],
        card: [Object]
    },
    data() {
        return {
            readyToView: false,
            bardataProcessed: [],

            series: [],
            // series: [44, 55, 13, 43, 22],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },
    mounted() {

        // Process Bar Data
        if (this.bardata) {
            let dataRows = this.bardata.replace(/"/g, '').split("\n");
            let finalData = [];
            let count = -1;
            let rownumber = 1;
            dataRows.forEach((record) => {
                count++;
                if (count == 0) {
                    // return because first row will contain headers
                    return
                }
                let dataInColumns = record.split(";");
                let rowObject = {};
                for (let index = 0; index < this.barfields.length; index++) {
                    rowObject[index] = dataInColumns[index]
                }
                finalData.push(rowObject);
                rownumber++;
            })
            console.log("data rows", dataRows, finalData);
            this.bardataProcessed = finalData;
        }

        // Process All the Data related to Graph
        let labeldataToBeFilled = [];
        this.barfields.forEach((element, index) => {
            labeldataToBeFilled.push(element.translation);
            console.log("Pie label data",labeldataToBeFilled);
        });
        this.chartOptions.labels = labeldataToBeFilled;

        this.barfields.forEach((element, index) => {
            console.log("bar field", element.key, element.translation, this.chartOptions.labels);
                let valuedataToBeFilled = [];

                this.bardataProcessed.forEach((row) => {
                    if (!row[index]) {
                        return;
                    }
                    console.log("Pie Row data",element, row, index, row[index]);
                    valuedataToBeFilled.push(parseInt(row[index])); // index is same as data to be filled
                })
                this.series.push(valuedataToBeFilled[0]); // series: [44, 55, 57, 56, 61, 58, 63, 60, 66];
        })


        // Show Graph
        this.$nextTick(() => {
            this.readyToView = true;
        })
    }
}
</script>