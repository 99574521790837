import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";

export default {
    data() {
        return {
            parentOrder: null,
            childOrder: null,
            suppliersListDialog: false,
        }
    },
    methods: {
        fetchOrderById(billId) {
            return new Promise((resolve, reject) => {
                
                return axios
                    .get(API_BASE_URL + "/orders/" + billId, {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    })
                    .finally(() => {})
                    .then(({data}) => resolve(data.data))
                    .catch((error) => reject(error));
            });
        },
        async fetchBillRelatives(bill) {
            this.parentOrder = null;
            this.childOrder = null;

            let parentOrderId = bill?.parent_order_id;
            let childOrderId = bill?.child_order_id;

            if (parentOrderId) {
                this.parentOrder = await this.fetchOrderById(parentOrderId);
            }

            if (childOrderId) {
                this.childOrder = await this.fetchOrderById(childOrderId);
            }
        },
        async editBill(item) {
            console.log(item)
            this.$store.commit("seteditId", item.id);

            if (this.$route.name === "EditBilling") {
                return await this.fetchBillByID(item.id);
            }

            this.$router.push("/billing/edit").catch(() => {
            });
        },
        toggleElementById(index, state) {
            let node = document.getElementById(this.generateElementId(index));

            if (node) {
                node.style.visibility = state;
            }

            let editArticleNode = document.getElementById("editArticle" + index);

            if (editArticleNode) {
                editArticleNode.style.visibility = state;
            }
        },
        generateElementId(index) {
            return "schedule_" + index;
        },

       async onSupplierSelected(supplier) {
            supplier.article_id = this.article.id; //Article ID os from Parent Component
            supplier.supplier_id = supplier.id;
            this.article.supplier = [supplier];
           this.article.supplier_stock_detail = supplier

            if(supplier.id !== 0){

            this.article.supplier = [supplier];
            this.article.supplier_stock_detail = supplier

            let identifier = supplier.identifier === "CON" ? 'brand' : supplier.identifier.toLowerCase();

            this.parentSearchSelectedArticleSupplierDetail  = {
                align : 'start',
                field_client_price: identifier + '_client_price',
                field_delivery_date: identifier + '_delivery_date',
                field_price: identifier + '_price',
                field_stock: identifier + '_stock',
                header_text: supplier.name.toUpperCase(),
                index: 0,
                order: 0,
                sortable: false,
                supplier_detail: supplier,
                text: supplier.name.toUpperCase(),
                value: identifier + '_stock',
                visible: true,
                width: 300,
            }
            }else{
                this.parentSearchSelectedArticleSupplierDetail = null
            }

            let parentArticleClone = {...this.parentSearchSelectedArticleId};
            this.parentSearchSelectedArticleId = null
            await setTimeout(() => {this.parentSearchSelectedArticleId = parentArticleClone},50)

        },
        openSupplierListDialog(){
            return false;
            // if(this.suppliersListDialog === true){
            //     this.suppliersListDialog = false;
            //     return setTimeout(() => { this.suppliersListDialog = true }, 50)
            // }
            //
            // this.suppliersListDialog = true;
        }

    }
}