<template>
  <span @click="clickHandler"> <v-icon size="21">mdi-open-in-new</v-icon></span>
</template>
<script>
export default{
  name: "showEditVehicleModal",
  methods:{
    clickHandler(){
      this.$store.commit('SHOW_EDIT_VEHICLE_MODAL')
    }
  }
}
</script>